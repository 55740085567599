export const ORDERS_REQUEST = "ORDERS_REQUEST";
export const ORDERS_SUCCESS = "ORDERS_SUCCESS";
export const ORDERS_FAILD = "ORDERS_FAILD";
export const ORDERS_LONG = "ORDERS_LONG";
export const ORDERS_NO_DATA = "ORDERS_NO_DATA";

export const PERSONAL_DATA_REQUEST = "PERSONAL_DATA_REQUEST";
export const PERSONAL_DATA_SUCCESS = "PERSONAL_DATA_SUCCESS";
export const PERSONAL_DATA_FAILD = "PERSONAL_DATA_FAILD";
export const PERSONAL_DATA_LONG = "PERSONAL_DATA_LONG";

export const ADDRESS_REQUEST = "ADDRESS_REQUEST";
export const ADDRESS_SUCCESS = "ADDRESS_SUCCESS";
export const ADDRESS_FAILD = "ADDRESS_FAILD";
export const ADDRESS_LONG = "ADDRESS_LONG";

export const FORM_ADDRESS_REQUEST = "FORM_ADDRESS_REQUEST";
export const FORM_ADDRESS_SUCCESS = "FORM_ADDRESS_SUCCESS";
export const FORM_ADDRESS_FAILD = "FORM_ADDRESS_FAILD";
export const FORM_ADDRESS_LONG = "FORM_ADDRESS_LONG";
export const FORM_ADDRESS_CLEAR = "FORM_ADDRESS_CLEAR";

export const SET_PERSONAL_DATA_REQUEST = "SET_PERSONAL_DATA_REQUEST";
export const SET_PERSONAL_DATA_SUCCESS = "SET_PERSONAL_DATA_SUCCESS";
export const SET_PERSONAL_DATA_FAILD = "SET_PERSONAL_DATA_FAILD";
export const SET_PERSONAL_DATA_LONG = "SET_PERSONAL_DATA_LONG";
export const SET_PERSONAL_DATA_CLEAR = "SET_PERSONAL_DATA_CLEAR";

export const CHANGE_PASSWORD_DATA_REQUEST = "CHANGE_PASSWORD_DATA_REQUEST";
export const CHANGE_PASSWORD_DATA_SUCCESS = "CHANGE_PASSWORD_DATA_SUCCESS";
export const CHANGE_PASSWORD_DATA_FAILD = "CHANGE_PASSWORD_DATA_FAILD";
export const CHANGE_PASSWORD_DATA_LONG = "CHANGE_PASSWORD_DATA_LONG";
export const CHANGE_PASSWORD_DATA_CLEAR = "CHANGE_PASSWORD_DATA_CLEAR";

export const COMMUNICATION_REQUEST = "COMMUNICATION_REQUEST";
export const COMMUNICATION_SUCCESS = "COMMUNICATION_SUCCESS";
export const COMMUNICATION_FAILD = "COMMUNICATION_FAILD";
export const COMMUNICATION_LONG = "COMMUNICATION_LONG";

export const SET_COMMUNICATION_REQUEST = "SET_COMMUNICATION_REQUEST";
export const SET_COMMUNICATION_SUCCESS = "SET_COMMUNICATION_SUCCESS";
export const SET_COMMUNICATION_FAILD = "SET_COMMUNICATION_FAILD";
export const SET_COMMUNICATION_LONG = "SET_COMMUNICATION_LONG";

export const GET_SUBSCRIPTION_REQUEST = "GET_SUBSCRIPTION_REQUEST";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_FAILD = "GET_SUBSCRIPTION_FAILD";
export const GET_SUBSCRIPTION_LONG = "GET_SUBSCRIPTION_LONG";
export const GET_SUBSCRIPTION_CLEAR = "GET_SUBSCRIPTION_CLEAR";
export const GET_SUBSCRIPTION_NO_DATA = "GET_SUBSCRIPTION_NO_DATA";

export const YOUR_SCREEN_REQUEST = "YOUR_SCREEN_REQUEST";
export const YOUR_SCREEN_SUCCESS = "YOUR_SCREEN_SUCCESS";
export const YOUR_SCREEN_FAILD = "YOUR_SCREEN_FAILD";
export const YOUR_SCREEN_LONG = "YOUR_SCREEN_LONG";
export const YOUR_SCREEN_CLEAR = "YOUR_SCREEN_CLEAR";
export const YOUR_SCREEN_NO_DATA = "YOUR_SCREEN_NO_DATA";

export const INVOICE_REQUEST = "INVOICE_REQUEST";
export const INVOICE_SUCCESS = "INVOICE_SUCCESS";
export const INVOICE_FAILED  = "INVOICE_FAILED";
export const INVOICE_LONG =   "INVOICE_LONG";
export const INVOICE_NO_DATA = "INVOICE_NO_DATA"; 
