import { useEffect, useState } from "react";
import BagSummary from "./BagSummary/BagSummary";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { ORDERSUCCESS } from "../../../Constant/Route";
import { useNavigate } from 'react-router-dom';
import { isLoading } from "../../../Utility/General"
import { API_STATUS } from "../../../Constant/Api";
import {PLACE_ORDER_SUCCESS,PLACE_ORDER_FAILD,PLACE_ORDER_LONG,PLACE_ORDER_REQUEST} from "../../../Redux/Checkout/CheckoutType"
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { useSelector } from "react-redux";

const YourBagMobile = (props: any) => {

  const [isActive, setActive] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const navigate = useNavigate();
  const placeOrderData = props.placeOrderData;
  const onNextClick = (e:any,count: number) => {
    if (!isDisabled) {
      let straightForward = [2, 3, 4];
      if (straightForward.includes(count)) {
        props.onNextClick(count);
        //if (count == 4 && props.enableShipping) {
        //  props.onNextClick(count+1);
        //}else{
        //  props.onNextClick(count);
        //}
      } else if (count == 5) {
        props.onPlaceOrder(e);
      }
      // if (count == 2) {
      //   // navigate(ORDERSUCCESS)
      //   props.onNextClick(count);
      // }else if (count == 3) {
      //   // navigate(ORDERSUCCESS)
      //   props.onNextClick(count);
      // }
    }
  };

  useSelector((state: any) => {
    if (state?.placeOrderReducer?.type === PLACE_ORDER_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.placeOrderReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.placeOrderReducer?.type === PLACE_ORDER_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.placeOrderReducer?.type === PLACE_ORDER_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.placeOrderReducer?.type === PLACE_ORDER_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  useEffect(() => {
    validateAndButtonEnableDisable(props);
  }, [props]);

  const validateAndButtonEnableDisable = (props: any)=>{
    if (props.placeOrderData && props.activeTabCount) {
      if (placeOrderData.email.value != "" && props.activeTabCount == 2) {
        if (props.placeOrderDataError["email"]) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      } else if (
        placeOrderData.email.value == "" &&
        props.activeTabCount == 2
      ) {
        setIsDisabled(true);
      } else if (
        placeOrderData.address.value != "" &&
        props.activeTabCount == 3
      ) {
        if (props.placeOrderDataError["address"]) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      } else if (
        placeOrderData.address.value == "" &&
        props.activeTabCount == 3
      ) {
        setIsDisabled(true);
      } 
      // Disable Shipping method validation
      /* else if (placeOrderData.shipping.value != "" && props.activeTabCount == 4) {
        if (props.placeOrderDataError["shipping"]) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      } else if (placeOrderData.shipping.value == "" && props.activeTabCount == 4) {
        setIsDisabled(true);
      } */
      else if (placeOrderData.paymentMethod.value != "" && (props.activeTabCount == 5 || props.activeTabCount == 4) ) {
        if (props.placeOrderDataError["paymentMethod"]) {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      } else if (placeOrderData.paymentMethod.value == "" && (props.activeTabCount == 5 || props.activeTabCount == 4)) {
        setIsDisabled(true);
      }
    }
  }

  return (
    <>
      <div
        className={isActive ? "overlay-summary show" : "overlay-summary hide"}
      >
        <div className="summary-top">
          <p className="f2036">Summary</p>
          <button className="closebtn" onClick={toggleClass}></button>
        </div>
        <BagSummary
          productDetail={props?.productDetail}
          priceDetail={props?.priceDetail}
          showSummary={isActive}
        />
        <div className="total-section">
          <span className="label-text">Total</span>
          <p className="price-text">
            {props?.priceDetail?.priceDetailsData?.payableAmount}
          </p>
          <button
            className={isActive ? "btnoverlay show" : "btnoverlay hide"}
            onClick={toggleClass}
          ></button>
          <div className="action-bar">
            <CustomButton
              disabled={isDisabled}
              isLoading={isLoading(apiStatus)}
              onClick={(e: any) => onNextClick(e,props?.activeTabCount + 1)}
              bg={"fill"}
            >
              {(props.activeTabCount == 5 || props.activeTabCount == 4) ? "Place Order" : "Next"}
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  );
}

export default YourBagMobile;