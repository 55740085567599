import { useEffect, useState } from "react";
import InputGroups from "../../Common/InputGroups/InputGroups";
import PDataNamePopup from "./PDataNamePopup/PDataNamePopup";
import PDataPwdPopup from "./PDataPwdPopup/PDataPwdPopup";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import { Container } from "react-bootstrap";
import PasswordGroups from "../../Common/PasswordGroups/PasswordGroups";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import {
  PERSONAL_DATA_FAILD,
  PERSONAL_DATA_LONG,
  PERSONAL_DATA_REQUEST,
  PERSONAL_DATA_SUCCESS,
} from "../../../Redux/Account/AccountType";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { personalDataAction } from "../../../Redux/Account/AccountAction";
import Loader from "../../Loader/Loader";

const PersonalData = () => {
  const [personalDataNameModalShow, setPersonalDataNameModalShow] =
    useState(false);
  const [personalDataPwdModalShow, setPersonalDataPwdModalShow] =
    useState(false);

  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const personalData = useSelector((state: any) => state?.personalData?.data);
  const loginData = useSelector((state: any) => state?.login?.data);
  useEffect(() => {
    dispatch(personalDataAction(loginData));
  }, []);
  const submitPersonalData = () =>{
    setPersonalDataNameModalShow(false);
    // dispatch(personalDataAction());
  }
  useSelector((state: any) => {
    if (state?.personalData?.type === PERSONAL_DATA_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.personalData?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.personalData?.type === PERSONAL_DATA_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.personalData?.type === PERSONAL_DATA_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.personalData?.type === PERSONAL_DATA_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <>
          <div className="personaldata-wrap">
            <div className="page-title">
              <h5>Personal Data</h5>
            </div>
            <div className="page-content">
              <div className="upper-wrap">
                <InputGroups
                  label={"Name"}
                  readOnly={true}
									value={personalData?.firstName + " " + personalData?.lastName}
                />
                <a
                  className="pdata-modal"
                  onClick={() => setPersonalDataNameModalShow(true)}
                ></a>
              </div>
              <div className="upper-wrap">
                <PasswordGroups
                  label={"Password"}
                  type="text"
                  readOnly={true}
                  value="********"
                />
                <a
                  className="pdata-modal"
                  onClick={() => setPersonalDataPwdModalShow(true)}
                ></a>
              </div>
            </div>
          </div>
          <PDataNamePopup
            show={personalDataNameModalShow}
            onHide={() => setPersonalDataNameModalShow(false)}
						firstName={personalData?.firstName}
						lastName={personalData?.lastName}
          ></PDataNamePopup>
          <PDataPwdPopup
            show={personalDataPwdModalShow}
            onHide={() => setPersonalDataPwdModalShow(false)}
          ></PDataPwdPopup>
        </>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    // return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
};

export default PersonalData;
