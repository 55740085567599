import React from "react";
import { Link } from "react-router-dom";
import CloseIcon from "../../../Assets/img/close.svg";
import Logo from "../../../Assets/img/logo.svg";
import {
  ABOUT,
  CLUB,
  CONTACT,
  HOME,
  MOBILEACCOUNT,
  LOGIN,
  PRODUCT_LISTING,
} from "../../../Constant/Route";
import AddCartFill from "../../../Assets/img/add-cart.svg";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MiniCart from "../MiniCart/MiniCart";
import { Dropdown } from "react-bootstrap";
import AddCart from "../../../Assets/img/add-cart-blank.svg";

const MobileMegaMenu = (props: any) => {
  const cartCount = useSelector(
    (state: any) => state?.cartCountGlobalData?.data
  );

  const toggleLevel = (e: any) => {
    e.target.classList.toggle("show");
  };

  const navigate = useNavigate();

  const redirectFunction = (e: any) => {
    navigate(e);
    props.toggleMenu();
  };

  return (
    <>
      <div className={`side-menu ${props.toggleSide ? "active" : ""}`}>
        <div className="side-menu-header">
          <img
            src={CloseIcon}
            className="close-icon"
            onClick={props.toggleMenu}
          />
          <a className="mobile-logo">
            <img src={Logo} alt="" />
          </a>
          {cartCount > 0 ? (
            <Dropdown
              className="add-to-cart mobile-view"
              onToggle={props.toggleCartMenu}
              show={props.toggleCart}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={AddCartFill} alt="" />
                <p>{cartCount}</p>
              </Dropdown.Toggle>
              <MiniCart
                toggleCart={props.toggleCart}
                toggleCartMenu={props.toggleCartMenu}
              />
            </Dropdown>
          ) : (
            <Dropdown className="add-to-cart mobile-view">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={AddCart} alt="" />
              </Dropdown.Toggle>
            </Dropdown>
          )}
        </div>
        <div className="scroll-side-menu">
          <ul className="side-menu-list">
            <li className={window.location.pathname == "/" ? "active" : ""}>
              <a
                onClick={(e: any) => redirectFunction(HOME)}
                className="nav-links"
              >
                Home
              </a>
            </li>
            <li
              className={
                window.location.pathname == "/productslisting" ? "active" : ""
              }
            >
              <a
                onClick={(e: any) => redirectFunction(PRODUCT_LISTING)}
                className="nav-links"
              >
                Diapers
              </a>
            </li>
            <li
              className={window.location.pathname == "/about" ? "active" : ""}
            >
              <a
                onClick={(e: any) => redirectFunction(ABOUT)}
                className="nav-links"
              >
                About
              </a>
            </li>
            <li className={window.location.pathname == "/club" ? "active" : ""}>
              <a
                onClick={(e: any) => redirectFunction(CLUB)}
                className="nav-links"
              >
                Pural Club
              </a>
            </li>
            <li
              className={window.location.pathname == "/contact" ? "active" : ""}
            >
              <a
                onClick={(e: any) => redirectFunction(CONTACT)}
                className="nav-links"
              >
                Contact
              </a>
            </li>
            {props.auth === true ? (
              <li
                className={
                  window.location.pathname == "/account" ? "active" : ""
                }
              >
                <a
                  onClick={(e: any) => redirectFunction(MOBILEACCOUNT)}
                  className="nav-links"
                >
                  My Account
                </a>
              </li>
            ) : (
              <li
                className={window.location.pathname == "/login" ? "active" : ""}
              >
                <a
                  onClick={(e: any) => redirectFunction(LOGIN)}
                  className="nav-links"
                >
                  Log In
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="side-menu-footer">
          <CustomButton
            bg="fill"
            onClick={(e: any) => redirectFunction(PRODUCT_LISTING)}
          >
            SHOP
          </CustomButton>
          <CustomButton onClick={(e: any) => redirectFunction(CLUB)}>
            JOIN PURAL CLUB
          </CustomButton>
        </div>
      </div>
      <div
        className={`side-back ${props.toggleSide ? "active" : ""}`}
        onClick={props.toggleMenu}
      ></div>
    </>
  );
};

export default MobileMegaMenu;
