import React, { useEffect, useState } from "react";
import minus from "../../../Assets/img/minus.svg";
import plus from "../../../Assets/img/plus.svg";

const IncDecBox = (props: any) => {

	const [incDecVal,setIncDecVal] = useState(props.value);
	const [disableMinus,setDisableMinus] = useState(false);
	const [disablePlus,setDisablePlus] = useState(false);
	const incDecValChange = (type: any) => {
		// console.log(type);
		var val = incDecVal;
		if(type === 'inc'){
			val = val + 1;
			setIncDecVal(incDecVal+1);
		}else{
			if(incDecVal > 0){
				val = val - 1;
				setIncDecVal(incDecVal-1);
			}
		}
		props.onChange(val);
	}
	useEffect(() => {
		if(incDecVal === 1){
			setDisableMinus(true);
		}else{
			setDisableMinus(false);
		}
		if (props.maxVal) {
			if(incDecVal === parseInt(props.maxVal)){
				setDisablePlus(true);
			}else{
				setDisablePlus(false);
			}
		}
		// if (props.onChange){
		// 	 props.onChange(incDecVal);
		// };
	},[incDecVal]);
	
	useEffect(() => {
		setIncDecVal(props.value);
	},[props.value]);
	return (
    <>
      <div className="inc-dec-box">
        <button onClick={() => incDecValChange("dec")} disabled={disableMinus || props.disableButton}>
          <img src={minus} alt="" />
        </button>
        <input value={incDecVal} readOnly />
        <button onClick={() => incDecValChange("inc")} disabled={disablePlus || props.disableButton}>
          <img src={plus} alt="" />
        </button>
      </div>
    </>
  );
}

export default IncDecBox;