const SelectGropus = (props: any) => {
  return (
    <>
      <div className="select-group">
        <select value={props.defaultSelect} onChange={props.onChange}>
          {props.value
            ? 
            props.value.map((items: any, index: string) => (
                <option key={index + "select-group"} value={items.id}>
                  {items.name}
                </option>
              ))
            : ""}
        </select>
        {
          props.error && <span className="error">{props.error}</span>
        }
      </div>
    </>
  );
};

export default SelectGropus;
