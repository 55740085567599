import { Container } from "react-bootstrap";
import CustomButton from "../../Common/CustomButton/CustomButton";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import Breadcrumbs from "../../Common/Breadcrumbs/Breadcrumbs";
import { ACCOUNT, CLUB, HOME, SUBSCRIPTION } from "../../../Constant/Route";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionAction } from "../../../Redux/Account/AccountAction";
import { useEffect, useState } from "react";
import { API_STATUS } from "../../../Constant/Api";
import { GET_SUBSCRIPTION_FAILD, GET_SUBSCRIPTION_LONG, GET_SUBSCRIPTION_NO_DATA, GET_SUBSCRIPTION_REQUEST, GET_SUBSCRIPTION_SUCCESS } from "../../../Redux/Account/AccountType";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import Loader from "../../Loader/Loader";
import { useNavigate } from "react-router-dom";

const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const subscriptionData = useSelector(
    (state: any) =>
      state?.getSubscriptionReducer?.data?.component?.subscriptionData
  );
  
  useEffect(() => {
    dispatch(getSubscriptionAction());
  }, []);
  const calculatePercent = (value: number, total: number) => {
    return 100 - ((value) / total) * 100;
  }
  useSelector((state: any) => {
    if (state?.getSubscriptionReducer?.type === GET_SUBSCRIPTION_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.getSubscriptionReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.getSubscriptionReducer?.type === GET_SUBSCRIPTION_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.getSubscriptionReducer?.type === GET_SUBSCRIPTION_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.getSubscriptionReducer?.type === GET_SUBSCRIPTION_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    } else if (state?.getSubscriptionReducer?.type === GET_SUBSCRIPTION_NO_DATA) {
      if (apiStatus !== API_STATUS.NO_DATA) {
        setApiStatus(API_STATUS.NO_DATA);
      }
    }
  });
  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <div className="subscription-wrap">
          <div className="page-title">
            <h5>Subscription</h5>
          </div>
          <div className="page-content">
            <div className="sub-info">
              <h5 className="sub-name">Active Subscription</h5>
              {subscriptionData?.active?.map((data: any, index: any) => (
                <div key={index}>
                  <div className="sub-timeperiod">
                    <label className="sub-dates">
                      {data?.start_date} - {data?.expiry_date}
                    </label>
                    <label className="sub-due">
                      {data?.expiry_days}
                      {data?.expiry_days > 1 ? " days" : " day"} left
                    </label>
                  </div>
                  <div className="sub-progress">
                    <div className="lower-bar">
                      <div
                        className="upper-bar"
                        style={{
                          width:
                            calculatePercent(
                              parseInt(data?.expiry_days),
                              parseInt(data?.diff_days)
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="sub-additional">
                    <p>{data?.message}</p>
                  </div>
                  <div className="action-bar">
                    <CustomButton onClick={() => navigate(CLUB)} bg={"fill"}>
                      Upgrade subscription
                    </CustomButton>
                  </div>
                </div>
              ))}
            </div>
            <div className="sub-info">
              <h5 className="sub-name">Future Subscription</h5>
              {subscriptionData?.future?.map((data: any, index: any) => (
                <div key={index}>
                  <div className="sub-timeperiod">
                    <label className="sub-dates">
                      {data?.start_date} - {data?.expiry_date}
                    </label>
                  </div>
                  <div className="sub-additional">
                    <p>{data?.message}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="sub-info">
              <h5 className="sub-name">Expired Subscription</h5>
              {subscriptionData?.expired?.map((data: any, index: any) => (
                <div key={index}>
                  <div className="sub-timeperiod">
                    <label className="sub-dates">
                      {data?.start_date} - {data?.expiry_date}
                    </label>
                  </div>
                  <div className="sub-additional">
                    <p>{data?.message}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    return (
      <div className="subscription-wrap">
        <div className="page-title">
          <h5>Subscription</h5>
        </div>
        <div className="page-content">
          <div className="sub-additional">
            <p>You don’t have any active membership.</p>
          </div>
          <div className="action-bar">
            <CustomButton onClick={() => navigate(CLUB)} bg={"fill"}>
              Purchase Membership
            </CustomButton>
          </div>
        </div>
      </div>
    );
    default:
      return <Loader></Loader>;
  }
}

export default Subscription;