import { END_POINTS, MAX_CALLS } from "../../Constant/Api";
import {
  SUCCESS_RESPONSE_CODE
} from "../../Constant/Status";
import api from "../../Service/Axios";
import { PURAL_CLUB_FAILD, PURAL_CLUB_LONG, PURAL_CLUB_REQUEST, PURAL_CLUB_SUCCESS } from "./ClubType";

export function getClubPageAction() {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: PURAL_CLUB_REQUEST,
      payload: { type: PURAL_CLUB_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.puralClub}`, {})
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            // showToast("success", result.data.message);
            dispatch({
              type: PURAL_CLUB_SUCCESS,
              payload: { data: result.data, type: PURAL_CLUB_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PURAL_CLUB_LONG,
              payload: { data: {}, type: PURAL_CLUB_LONG },
            });
          } else {
            dispatch({
              type: PURAL_CLUB_FAILD,
              payload: { data: {}, type: PURAL_CLUB_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PURAL_CLUB_LONG,
              payload: { data: {}, type: PURAL_CLUB_LONG },
            });
          } else {
            dispatch({
              type: PURAL_CLUB_FAILD,
              payload: { data: {}, type: PURAL_CLUB_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}