import { CONTACTUS_CLEAR, CONTACTUS_FAILD, CONTACTUS_LONG, CONTACTUS_REQUEST, CONTACTUS_SUCCESS, GET_CONTACTUS_CLEAR, GET_CONTACTUS_FAILD, GET_CONTACTUS_LONG, GET_CONTACTUS_REQUEST, GET_CONTACTUS_SUCCESS } from "./ContactUsType";

export function contactUs(state = {}, action: any) {
  switch (action.type) {
    case CONTACTUS_REQUEST:
      return { ...action.payload };
    case CONTACTUS_SUCCESS:
      return { ...action.payload };
    case CONTACTUS_FAILD:
      return { ...action.payload };
    case CONTACTUS_LONG:
      return { ...action.payload };
    case CONTACTUS_CLEAR:
      return {};
    default:
      return state;
  }
}

export function getContactUs(state = {}, action: any) {
  switch (action.type) {
    case GET_CONTACTUS_REQUEST:
      return { ...action.payload };
    case GET_CONTACTUS_SUCCESS:
      return { ...action.payload };
    case GET_CONTACTUS_FAILD:
      return { ...action.payload };
    case GET_CONTACTUS_LONG:
      return { ...action.payload };
    case GET_CONTACTUS_CLEAR:
      return {};
    default:
      return state;
  }
}
