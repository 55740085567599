import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { getCommunicationAction, setCommunicationAction } from "../../../Redux/Account/AccountAction";
import {
  COMMUNICATION_FAILD,
  COMMUNICATION_LONG,
  COMMUNICATION_REQUEST,
  COMMUNICATION_SUCCESS,
} from "../../../Redux/Account/AccountType";
import Loader from "../../Loader/Loader";

const CommunicationSettings = () => {
  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);

  useEffect(() => {
    dispatch(getCommunicationAction());
  }, []);

  useSelector((state: any) => {
    if (state?.getCommunication?.type === COMMUNICATION_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.getCommunication?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.getCommunication?.type === COMMUNICATION_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.getCommunication?.type === COMMUNICATION_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.getCommunication?.type === COMMUNICATION_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  const communicationData = useSelector(
    (state: any) => state?.getCommunication?.data
  );
  // console.table(communicationData);
  const settingData = [
    {
      message:
        "I want to receive emails and messages from Pural with news and exclusive discounts",
      name: "newsletter",
      isActive: communicationData?.newsletter,
    },
    {
      message:
        "I want to receive messages through Whatsapp from Pural with news and exclusive discounts",
      name: "whatsapp",
      isActive: communicationData?.whatsapp,
    },
    // "I want to receive messages through Whatsapp from Pural with news and exclusive discounts",
  ];

  const setChangeHandler = (checked:boolean, itemsName: string) => {
    let values = checked?"1":"0";
    dispatch(setCommunicationAction({key: itemsName, value:values}));
  }

  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <div className="communication-settings">
          <div className="page-title">
            <h5>Communication settings</h5>
          </div>
          <div className="page-content">
            {settingData.map((items: any, index: number) => (
              <div className="CS-box" key={index + "Communication-setting"}>
                <p className="f1424">{items.message}</p>
                <div className="button r" id="button-1">
                  <input
                    type="checkbox"
                    className="checkbox"
                    defaultChecked={items.isActive}
                    onChange={(e: any) => setChangeHandler(e.target.checked, items.name)}
                  />
                  <div className="knobs"></div>
                  <div className="layer"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    // return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
};

export default CommunicationSettings;
