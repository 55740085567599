import CartImg1 from "../../../Assets/img/cart-img-1.png";
import CartImgpl from "../../../Assets/img/cart-img-placeholder.png";
import IncDecBox from "../../Common/IncDecBox/IncDecBox";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import CartDelete from "../../../Assets/img/cart-trash.svg";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ORDERSUCCESS } from "../../../Constant/Route";
import { useDispatch, useSelector } from "react-redux";
import { addToCartAction } from "../../../Redux/Cart/CartAction";
import { ADD_REMOVE_CART_FAILD, ADD_REMOVE_CART_REQUEST, ADD_REMOVE_CART_SUCCESS } from "../../../Redux/Cart/CartType";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { API_STATUS } from "../../../Constant/Api";
import { QUOTE_ID } from "../../../Constant/LocalStorage";
import { getCheckoutAction } from "../../../Redux/Checkout/CheckoutAction";
import { isLoading } from "../../../Utility/General"
import {PLACE_ORDER_SUCCESS,PLACE_ORDER_FAILD,PLACE_ORDER_LONG,PLACE_ORDER_REQUEST} from "../../../Redux/Checkout/CheckoutType"

const YourBag = (props:any) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [disableButton, setDisableButton] = useState(false);
  const quoteId = localStorage.getItem(QUOTE_ID)
    ? localStorage.getItem(QUOTE_ID)
    : "";
  const [addRemoveCartApiStatus, setAddRemoveCartApiStatus] = useState<any>({});
  const [disablePlaceOrder, setDisablePlaceOrder] = useState(false);

  const [isActive, setActive] = useState(false);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);

  // const toggleClass = () => {
  //   setActive(!isActive);
  // };

  const changeQty = (value: any, data: any) => {
    props.onQtyChange(true);
    let cartFlag = parseInt(data.selectedQuantity) < value ? "1" : "0";
    let productQuery = {
      cartFlag: cartFlag,
      // itemId: "",
      itemId: data.itemId,
      productId: data.id,
      urlKey: data.urlKey,
      qty: "1",
      varientQuery: data.variant,
    };

    let productQueryData = {
      quoteId: quoteId,
      productQuery: [productQuery],
    };
    dispatch(addToCartAction(productQueryData));
    // dispatch(addRemoveCartAction(productQueryData));
  };

  const deleteItem = (data: any) => {
    props.onQtyChange(true);
    let productQuery = {
      cartFlag: "0",
      itemId: data.itemId,
      productId: data.id,
      urlKey: data.urlKey,
      qty: data.selectedQuantity,
      varientQuery: data.variant,
    };

    let productQueryData = {
      quoteId: quoteId,
      productQuery: [productQuery],
    };
    dispatch(addToCartAction(productQueryData));
    // dispatch(addRemoveCartAction(productQueryData));
  };

   useSelector((state: any) => {
     //add to cart selector
     // console.log(state?.addToCartReducer?.type);
     if (state?.addToCartReducer?.type === ADD_REMOVE_CART_REQUEST) {
       if (addRemoveCartApiStatus !== API_STATUS.LOADING) {
         setAddRemoveCartApiStatus(API_STATUS.LOADING);
       }
     } else if (
       state?.addToCartReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
       state?.addToCartReducer?.type === ADD_REMOVE_CART_SUCCESS
     ) {
       if (addRemoveCartApiStatus !== API_STATUS.SUCCESS) {
        // console.log('asd');
         setAddRemoveCartApiStatus(API_STATUS.SUCCESS);
         props.onQtyChange(false);
        // dispatch(getCheckoutAction({ quoteId: quoteId }));
       }
     } else if (state?.addToCartReducer?.type === ADD_REMOVE_CART_FAILD) {
       if (addRemoveCartApiStatus !== API_STATUS.FAILED) {
         setAddRemoveCartApiStatus(API_STATUS.FAILED);
       }
     }
   });

  useEffect(() => {
    if (JSON.stringify(props.placeOrderDataError) !== JSON.stringify({})) {
      setDisablePlaceOrder(true);
    }else{
      setDisablePlaceOrder(false);
    }
  }, [props.placeOrderData, props.placeOrderDataError]);

  useSelector((state: any) => {
    if (state?.placeOrderReducer?.type === PLACE_ORDER_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.placeOrderReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.placeOrderReducer?.type === PLACE_ORDER_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.placeOrderReducer?.type === PLACE_ORDER_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.placeOrderReducer?.type === PLACE_ORDER_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  return (
    <div className="cart-items">
      <p className="f2036">Your bag</p>
      <div
        className={isActive ? "bag-content your_className" : "bag-content null"}
      >
        <div className="cart-box">
          {props?.productDetail?.purchasedProductData?.list?.map(
            (data: any, index: number) => (
              <div className="item" key={index + "cartItems1"}>
                <img src={data.image} alt="" className="cart-data-img" />
                <div className="cart-data">
                  <p className="f1626">{data.title}</p>
                  <div className="atrributes">
                    {/* {data.subscriptionActive == "1" ? (
                      <div className="without-incdec">
                        <SelectGropus value={subDropdown}></SelectGropus>
                      </div>
                    ) : (
                      <div className="with-incdec">
                        <SelectGropus value={catDropdown}></SelectGropus>
                        <IncDecBox value={1}></IncDecBox>
                      </div>
                    )} */}
                    <div className="with-incdec">
                      <p className="with-category">
                        {data?.variant?.length ? data?.variant[0]?.value:""}
                      </p>
                      { data?.productType != "membership" ? (
                          <IncDecBox
                            disableButton={
                              data?.productType == "virtual"
                                ? true
                                : props.disableButton
                            }
                            onChange={(e: any) => {
                              changeQty(e, data);
                            }}
                            maxVal={parseInt(data.qty)}
                            value={parseInt(data.selectedQuantity)}
                          ></IncDecBox>
                        ) : '' }
                    </div>
                  </div>
                  <div className="price-action">
                    <p className="price-text">{data.price}</p>
                    <a
                      href="#"
                      className="delete"
                      onClick={() => deleteItem(data)}
                    >
                      <img src={CartDelete} alt="Delete Icon"></img>
                    </a>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
        {props?.priceDetail?.priceDetailsData?.listData?.map(
          (data: any, index: number) => (
            <div className="shipping-section" key={index + "priceDetail"}>
              <span className="label-text">{data.leftText}</span>
              <span className="value-text">
                {data.rightText == "Free" ? "-" : data.rightText}
              </span>
            </div>
          )
        )}
        {/* <div className="shipping-section">
          <span className="label-text">Shipping</span>
          <span className="value-text">-</span>
        </div> */}
        <div className="total-section">
          <span className="label-text">Total</span>
          <p className="price-text">
            {props?.priceDetail?.priceDetailsData?.payableAmount}
          </p>
          {/* <button onClick={toggleClass}></button> */}
        </div>
        <div className="action-bar">
          {/* <CustomButton bg={"fill"} onClick={() => navigate(ORDERSUCCESS)}> */}
          <CustomButton
            bg={"fill"}
            isLoading={isLoading(apiStatus)}
            disabled={disablePlaceOrder}
            onClick={(e: any) => props.onSubmit(e)}
          >
            Place Order
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default YourBag;