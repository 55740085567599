import { END_POINTS, MAX_CALLS } from "../../Constant/Api";
import { MASTER_API } from "../../Constant/Config";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import api from "../../Service/Axios";
import { showToast } from "../../Utility/General";
// import { showToast } from "../../Utility/General";
import {
  CART_COUNT_GLOBAL,
  DEFAULT_PARAMETER,
  MASTER_FAILD,
  MASTER_LONG,
  MASTER_REQUEST,
  MASTER_SUCCESS,
  NEWSLETTER_SUBSCRIPTION_FAILD,
  NEWSLETTER_SUBSCRIPTION_LONG,
  NEWSLETTER_SUBSCRIPTION_REQUEST,
  NEWSLETTER_SUBSCRIPTION_SUCCESS,
} from "./GeneralType";

export function getMaster(params: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: MASTER_REQUEST,
      payload: { type: MASTER_REQUEST },
    });
    function recursiveCall() {      
      api
        .post(MASTER_API, params)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            dispatch({
              type: MASTER_SUCCESS,
              payload: { data: result.data, type: MASTER_SUCCESS },
            });

            dispatch({
              type: CART_COUNT_GLOBAL,
              payload: { data: result.data?.cartCount },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: MASTER_LONG,
              payload: { data: {}, type: MASTER_LONG },
            });
          } else {
            dispatch({
              type: MASTER_FAILD,
              payload: { data: {}, type: MASTER_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: MASTER_LONG,
              payload: { data: {}, type: MASTER_LONG },
            });
          } else {
            dispatch({
              type: MASTER_FAILD,
              payload: { data: {}, type: MASTER_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

// export const showMinicart = {
//     type: MINI_CART_TRUE,
//     payload: true
// };

// export const hideMinicart = {
//     type: MINI_CART_FALSE,
//     payload: false
// };

export function defaultParameterAction(param: any) {
  return (dispatch: any) => {
    dispatch({
      type: DEFAULT_PARAMETER,
      payload: { data: param, type: DEFAULT_PARAMETER },
    });
  };
}

export function newsletterSubscriptionAction(param: any) {
    let recallCount = 0;
    return (dispatch: any) => {
        dispatch({
            type: NEWSLETTER_SUBSCRIPTION_REQUEST,
            payload: { type: NEWSLETTER_SUBSCRIPTION_REQUEST },
        });
        function recursiveCall() {
            api.post(END_POINTS.newsletterSubscription, param).then((result) => {
                if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
                    showToast('success', result.data.message)
                    dispatch({
                        type: NEWSLETTER_SUBSCRIPTION_SUCCESS,
                        payload: { data: result.data, type: NEWSLETTER_SUBSCRIPTION_SUCCESS },
                    });
                }
                else if (recallCount < MAX_CALLS) {
                    recursiveCall()
                    recallCount++;
                    dispatch({
                        type: NEWSLETTER_SUBSCRIPTION_LONG,
                        payload: { data: {}, type: NEWSLETTER_SUBSCRIPTION_LONG },
                    });
                }
                else {
                    showToast('error', result.data.message)
                    dispatch({
                        type: NEWSLETTER_SUBSCRIPTION_FAILD,
                        payload: { data: {}, type: NEWSLETTER_SUBSCRIPTION_FAILD },
                    });
                }
            }).catch((error) => {
                showToast('error', error)
                if (recallCount < MAX_CALLS) {
                    recursiveCall()
                    recallCount++;
                    dispatch({
                        type: NEWSLETTER_SUBSCRIPTION_LONG,
                        payload: { data: {}, type: NEWSLETTER_SUBSCRIPTION_LONG },
                    });
                }
                else {
                    dispatch({
                        type: NEWSLETTER_SUBSCRIPTION_FAILD,
                        payload: { data: {}, type: NEWSLETTER_SUBSCRIPTION_FAILD },
                    });
                }
            });
        }
        recursiveCall()
    };
}

// export function addRemoveWishListAction(param: any) {
//     let recallCount = 0;
//     return (dispatch: any) => {
//         dispatch({
//             type: ADD_REMOVE_WISH_LIST_REQUEST,
//             payload: { type: ADD_REMOVE_WISH_LIST_REQUEST },
//         });
//         function recursiveCall() {
//             api.post(END_POINTS.addRemoveWishlist, param).then((result) => {
//                 if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
//                     showToast('success', result.data.message)
//                     dispatch({
//                         type: ADD_REMOVE_WISH_LIST_SUCCESS,
//                         payload: { data: result.data, type: ADD_REMOVE_WISH_LIST_SUCCESS },
//                     });
//                 }
//                 else if (recallCount < MAX_CALLS) {
//                     recursiveCall()
//                     recallCount++;
//                     dispatch({
//                         type: ADD_REMOVE_WISH_LIST_LONG,
//                         payload: { data: {}, type: ADD_REMOVE_WISH_LIST_LONG },
//                     });
//                 }
//                 else {
//                     showToast('error', result.data.message)
//                     dispatch({
//                         type: ADD_REMOVE_WISH_LIST_FAILD,
//                         payload: { data: {}, type: ADD_REMOVE_WISH_LIST_FAILD },
//                     });
//                 }
//             }).catch((error) => {
//                 showToast('error', error)
//                 if (recallCount < MAX_CALLS) {
//                     recursiveCall()
//                     recallCount++;
//                     dispatch({
//                         type: ADD_REMOVE_WISH_LIST_LONG,
//                         payload: { data: {}, type: ADD_REMOVE_WISH_LIST_LONG },
//                     });
//                 }
//                 else {
//                     dispatch({
//                         type: ADD_REMOVE_WISH_LIST_FAILD,
//                         payload: { data: {}, type: ADD_REMOVE_WISH_LIST_FAILD },
//                     });
//                 }
//             });
//         }
//         recursiveCall()
//     };
// }
