import React from 'react';
import LoaderImg from "../../Assets/img/loader.svg";

const Loader = () => {
  return (
    <div className="loader">
      <img src={LoaderImg} alt="" />
      <p>Processing</p>
      <span>Please wait</span>
    </div>
  );
}

export default Loader;
