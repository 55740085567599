import CustomButton from "../Common/CustomButton/CustomButton";
import InputGroups from "../Common/InputGroups/InputGroups";
import SelectGropus from "../Common/SelectGroups/SelectGroups";
import TextareaGroups from "../Common/TextareaGroups/TextareaGroups";
import contbannerDesk from "../../Assets/img/banner-contact.jpg";
import contbannershapeDesk from "../../Assets/img/shape-contact.svg";
import contbannerMob from "../../Assets/img/mbanner-contact.jpg";
import contbannershapeMob from "../../Assets/img/mshape-contact.svg";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { API_STATUS } from "../../Constant/Api";
import validate from "../../Utility/Validation";
import {
  contactUsAction,
  getContactUsAction,
} from "../../Redux/ContactUs/ContactUsAction";
import {
  CONTACTUS_CLEAR,
  CONTACTUS_FAILD,
  CONTACTUS_LONG,
  CONTACTUS_REQUEST,
  CONTACTUS_SUCCESS,
  GET_CONTACTUS_CLEAR,
  GET_CONTACTUS_FAILD,
  GET_CONTACTUS_LONG,
  GET_CONTACTUS_REQUEST,
  GET_CONTACTUS_SUCCESS,
} from "../../Redux/ContactUs/ContactUsType";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { isLoading } from "../../Utility/General";

const ContactUs = () => {
  const loginData = useSelector((state: any) => state?.login?.data);

  const dispatch = useDispatch();
  const INITIAL_CONTACT_DATA: any = {
    email: {
      value: loginData?.email,
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
    firstName: {
      value: '',
      validation: ["required"],
      errors: ["firstName is required."],
    },
    lastName: {
      value: '',
      validation: ["required"],
      errors: ["lastName is required."],
    },
    message: {
      value: "",
      validation: ["required"],
      errors: ["Message is required."],
    },
    customerType: {
      value: "General Buyer",
      validation: ["required"],
      errors: ["Customer Type is required."],
    },
  };

  const [contactData, setContactData] = useState(INITIAL_CONTACT_DATA);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [getApiStatus, setGetApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [contactDataError, setContactDataError] = useState(demo);
  const [typeData, setTypeData] = useState<any>([]);
  const getContactUsData = useSelector(
    (state: any) => state?.getContactUs?.data
  );
  // console.log("getContactUs", getContactUs);
  const setData = (field: string, value: string) => {
    let contactDetails = Object.assign({}, contactData);
    contactDetails[field].value = value;
    setContactData({ ...contactDetails });
  };

  useEffect(() => {
    setContactDataError({ ...validate(contactData)?.errors });
    setIsFormValidated(false);
  }, [contactData]);
  useEffect(() => {
    dispatch(getContactUsAction());
  }, []);

  useEffect(() => {
    let contactUsType: any = [];
    getContactUsData?.list?.forEach((item: any) => {
      contactUsType.push({ id: item, name: item });
    });
    setTypeData(contactUsType);
  }, [getContactUsData]);

  const contactHandler = () => {
    if (validate(contactData).isValidated === true) {
      dispatch(
        contactUsAction({
          firstName: contactData.firstName.value,
          lastName: contactData.lastName.value,
          email: contactData.email.value,
          message: contactData.message.value,
          customerType: contactData.customerType.value,
        })
      );
    } else {
      setContactDataError({ ...validate(contactData)?.errors });
      setIsFormValidated(true);
    }
  };

  useSelector((state: any) => {
    if (state?.contactUs?.type === CONTACTUS_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.contactUs?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.contactUs?.type === CONTACTUS_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
      dispatch({
        type: CONTACTUS_CLEAR,
        payload: { type: CONTACTUS_CLEAR },
      });
      setContactData({ ...INITIAL_CONTACT_DATA });
    } else if (state?.contactUs?.type === CONTACTUS_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.contactUs?.type === CONTACTUS_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }

    if (state?.getContactUs?.type === GET_CONTACTUS_REQUEST) {
      if (getApiStatus !== API_STATUS.LOADING) {
        setGetApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.getContactUs?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.getContactUs?.type === GET_CONTACTUS_SUCCESS
    ) {
      if (getApiStatus !== API_STATUS.SUCCESS) {
        setGetApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.getContactUs?.type === GET_CONTACTUS_LONG) {
      if (getApiStatus !== API_STATUS.LONG) {
        setGetApiStatus(API_STATUS.LONG);
      }
    } else if (state?.getContactUs?.type === GET_CONTACTUS_FAILD) {
      if (getApiStatus !== API_STATUS.FAILED) {
        setGetApiStatus(API_STATUS.FAILED);
      }
    }
  });

  const accBreadcrumb = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "/contact",
      label: "Contact Us",
    },
  ];

  const regex = /(<([^>]+)>)/ig;
  const title = getContactUsData?.contactText.replace(regex,'')

  return (
    <div className="contact-wrap">
      <div className="banner-wrap">
        <div className="cmsbanner-wrap desktop show-768">
          <div className="cmsbanner-img">
            <img src={contbannerDesk} alt="Banner Image"></img>
          </div>
          <div className="cmsbanner-inwrap">
            <Container>
              <div className="cmsbanner-content">
                <img src={contbannershapeDesk} alt="Vector"></img>
                <div className="cmsshape">
                  <Breadcrumbs value={accBreadcrumb}></Breadcrumbs>
                  <h1>Contact Us</h1>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div className="cmsbanner-wrap mobile hide-767">
          <div className="cmsbanner-img">
            <img src={contbannerMob} alt="Banner Image"></img>
            <Breadcrumbs value={accBreadcrumb}></Breadcrumbs>
          </div>
          <div className="cmsbanner-inwrap">
            <div className="cmsbanner-content">
              <img src={contbannershapeMob} alt="Vector"></img>
              <div className="abtshape">
                <h1>Contact Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inner-contact">
        <Container>
          <div className="cform-wrap">
            <div className="mobile-contect-banner-data">
              <h1>Get in touch!</h1>
              <p>Looking to stock Pural?Have a question about our nappies?</p>
              <span>Fill in the contact form below with as much information as possible, and we’ll get back to you within 24 hours.</span>
            </div>
            <p>{title}</p>
            <form className="contact-form">
              <SelectGropus
                onChange={(e: any) => setData("customerType", e.target.value)}
                // error={isFormValidated && contactDataError['email'] ? contactDataError['email'] : null}
                value={typeData}
                defaultSelect={contactData.customerType.value}
              ></SelectGropus>
              <InputGroups
                onChange={(e: any) => setData("firstName", e.target.value)}
                error={
                  isFormValidated && contactDataError["firstName"]
                    ? contactDataError["firstName"]
                    : null
                }
                value={contactData.firstName.value}
                type={"text"}
                label={"Name"}
              ></InputGroups>
              <InputGroups
                onChange={(e: any) => setData("lastName", e.target.value)}
                error={
                  isFormValidated && contactDataError["lastName"]
                    ? contactDataError["lastName"]
                    : null
                }
                value={contactData.lastName.value}
                type={"text"}
                label={"Surname"}
              ></InputGroups>
              {/* <InputGroups
                onChange={(e: any) => setData("phone", e.target.value)}
                // error={isFormValidated && contactDataError['email'] ? contactDataError['email'] : null}
                type={"text"}
                label={"Phone"}
              ></InputGroups> */}
              <InputGroups
                onChange={(e: any) => setData("email", e.target.value)}
                error={
                  isFormValidated && contactDataError["email"]
                    ? contactDataError["email"]
                    : null
                }
                value={contactData.email.value}
                type={"email"}
                label={"Email"}
              ></InputGroups>
              <TextareaGroups
                onChange={(e: any) => setData("message", e.target.value)}
                error={
                  isFormValidated && contactDataError["message"]
                    ? contactDataError["message"]
                    : null
                }
                rowno={5}
                value={contactData.message.value}
              >
                Message
              </TextareaGroups>
            </form>
            <div className="action-bar">
              <CustomButton
                isLoading={isLoading(apiStatus)}
                disabled={isLoading(apiStatus)}
                onClick={() => contactHandler()}
                title={"Send"}
                bg={"fill"}
              >
                Send
              </CustomButton>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ContactUs;
