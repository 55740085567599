import React from 'react';
import { Container } from 'react-bootstrap';
import iconImg from "../../Assets/img/something-wrong.png";
import CustomButton from '../Common/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { HOME } from "../../Constant/Route";

const SomethingWrong = () => {

  const navigate = useNavigate();

  return (
    <div className="something-wrong">
        <Container>
        <div className='row something-wrong-layout'>
           <div className='wrong-layout-text col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 order-2 order-xs-1 order-md-1 order-lg-1 order-xl-1 '>
           <h1>Something went wrong!</h1>
          <p>We are sorry, the page you requested could not be found. Please go back to the homepage.</p>
          <CustomButton onClick={() => navigate(HOME)} bg={"fill"}>go home</CustomButton>
           </div>
           <div className='col-xl-6 col-lg-6 col-md-6  col-sm-12 col-xs-12  order-1 order-xs-2 order-md-2 order-lg-2 order-xl-2'>
           <img src={iconImg} alt="" />
           </div>
          </div>  
      </Container>
    </div>
  );
}

export default SomethingWrong;
