import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Accordion, Dropdown } from "react-bootstrap";
import Logo from "../../Assets/img/logo.svg";
import CustomButton from "../Common/CustomButton/CustomButton";
import UserIcon from "../../Assets/img/user.svg";
import AddCart from "../../Assets/img/add-cart-blank.svg";
import {
  ABOUT,
  ACCOUNT,
  CLUB,
  CONTACT,
  HOME,
  ORDERS,
  DETAILPAGE,
  CHECKOUT,
  LOGIN,
  SUBSCRIPTION,
  PERSONALDATA,
  COMMUNICATIONSETTINGS,
  ADRESSES,
  PRODUCT_LISTING,
} from "../../Constant/Route";
import AddCartFill from "../../Assets/img/add-cart.svg";
import MenuIcon from "../../Assets/img/menu-icon.svg";
import CloseIcon from "../../Assets/img/modal-close.svg";
import MobileMegaMenu from "./MobileMegaMenu/MobileMegaMenu";
import CartImg1 from "../../Assets/img/cart-img-1.png";
import CartImgpl from "../../Assets/img/cart-img-placeholder.png";
import CartDelete from "../../Assets/img/cart-trash.svg";
import SelectGropus from "../Common/SelectGroups/SelectGroups";
import IncDecBox from "../Common/IncDecBox/IncDecBox";
import { useNavigate } from "react-router-dom";
import LoginPopup from "../LoginPopup/LoginPopup";
import SignupPopup from "../SignupPopup/SignupPopup";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { USER, QUOTE_ID } from "../../Constant/LocalStorage";
import MiniCart from "./MiniCart/MiniCart";
import { LOGIN_MODAL_SHOW, LOGOUT_SUCCESS  } from "../../Redux/Auth/AuthType";
import { showToast } from "../../Utility/General";
import { defaultParameterAction } from "../../Redux/General/GeneralAction";
import { myCart } from "../../Redux/Cart/CartAction";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const quoteId = localStorage.getItem(QUOTE_ID)? localStorage.getItem(QUOTE_ID): "";
  const [toggleSide, setToggleSide] = useState(false);
  const [toggleCart, setToggleCart] = useState(false);
  const [backStatus1, setBackStatus1] = useState(false);
  const [backStatus2, setBackStatus2] = useState(false);

  const toggleMenu = () => {
    setToggleSide(!toggleSide);
  };

  const toggleCartMenu = () => {
    setToggleCart(!toggleCart);
  };
  onscroll = () => {
    const scrollY = window.scrollY;
    var navbar: any = document.getElementById("navbar");
    if (window.pageYOffset >= 1) {
      navbar?.classList.add("nav-fixed");
    } else {
      navbar?.classList.remove("nav-fixed");
    }
  };

  let timeoutCart: any = null;
  const [tmCheck, setTmCheck] = useState(0);

  useEffect(() => {
    if(backStatus1){
      timeoutCart = setTimeout(() => {
        setBackStatus1(false);
      }, 5000);
      setTmCheck(timeoutCart);
    }
    else{
      clearTimeout(tmCheck); 
    }
  }, [backStatus1]);



  let timeoutCart2: any = null;
  const [tmCheck2, setTmCheck2] = useState(0);

  //useEffect(() => {
  //  if(backStatus2){
  //    timeoutCart2 = setTimeout(() => {
  //      setBackStatus2(false);
  //    }, 5000);
  //    setTmCheck2(timeoutCart2);
  //  }
  //  else{
  //    clearTimeout(tmCheck2); 
  //  }
  //}, [backStatus2]);



  const auth = useSelector((state: any) => state?.login?.isLoggedIn);
  const cartCount = useSelector(
    (state: any) => state?.cartCountGlobalData?.data
  );
  const cartDataStatus = useSelector((state: any) => state?.myCartGlobalData?.data?.statusCode);
  const loginShowHide = useSelector((state: any) => state?.getLoginShowReducer);
  // const auth = localStorage.getItem(USER);
 

  


  const toggleBack1 = () => {
    setBackStatus1(!backStatus1);
  };

  const toggleBack2 = () => {
    setBackStatus2(!backStatus2);
  };

  const [loginModalShow, setLoginModalShow] = useState(false);
  const [signupModalShow, setSignupModalShow] = useState(false);

  const [menuToggle, setMenuToggle] = useState(false);

  useEffect(() => {
    // console.log(window.location.pathname);
    var urlArr: any = window.location.pathname?.includes("account");
    if (urlArr) {
      setMenuToggle(true);
    } else {
      setMenuToggle(false);
    }
  });

  const loginCall = () => {
    setLoginModalShow(true);
  };

  useEffect(() => {
    // console.log("loginShowHide", loginShowHide);
    if (loginShowHide?.show) {
      setLoginModalShow(true);
    }
  }, [loginShowHide]);
  
  useEffect(() => {
    if(cartCount == 0){
      setBackStatus2(false)
    }
  },[cartCount])

  const setLoginModalReducer = () => {
    //dispatch({
    //  type: LOGIN_MODAL_SHOW,
    //  payload: { show: val },
    //});
    setLoginModalShow(false);
    setSignupModalShow(false);
  };
  // const redirectFunction = (e: any) => {
  //   navigate(e);
  //   setBackStatus2(!backStatus2);
  // };
  const handleLogout = () => {
    localStorage.removeItem(USER);
    localStorage.removeItem(QUOTE_ID);
    dispatch({
      type: LOGOUT_SUCCESS,
      payload: { type: LOGOUT_SUCCESS },
    });
    dispatch(myCart({ quoteId: quoteId }));
    const defaultParameters = {
      customerId: "",
      customerToken: "",
      websiteId: "",
      groupId: "",
      storeId: "",
    }
    let defaultParameter = Object.assign({}, defaultParameters);
    dispatch(defaultParameterAction({...defaultParameter}));
    navigate(HOME);
    showToast("success", "Logout Successfully")
  };

  return (
    <>
      <header id="navbar">
        <div className="header-container">
          <img
            src={MenuIcon}
            alt=""
            className="menu-icon"
            onClick={toggleMenu}
          />
          <MobileMegaMenu
            toggleSide={toggleSide}
            toggleMenu={toggleMenu}
            auth={auth}
            toggleCart={toggleCart}
            toggleCartMenu={toggleCartMenu}
          />
          <Link to={HOME} className="logo">
            <img src={Logo} alt="" />
          </Link>
          <ul className="center-menu">
            <li className={window.location.pathname == "/" ? "active" : ""}>
              <Link to={HOME} className="nav-links">
                Home
              </Link>
            </li>
            <li
              className={
                window.location.pathname == "/productslisting" ? "active" : ""
              }
            >
              <Link to={PRODUCT_LISTING} className="nav-links">
                Diapers
              </Link>
            </li>
            <li
              className={window.location.pathname == "/about" ? "active" : ""}
            >
              <Link to={ABOUT} className="nav-links">
                About
              </Link>
            </li>
            <li className={window.location.pathname == "/club" ? "active" : ""}>
              <Link to={CLUB} className="nav-links">
                Pural Club
              </Link>
            </li>
            <li
              className={window.location.pathname == "/contact" ? "active" : ""}
            >
              <Link to={CONTACT} className="nav-links">
                Contact
              </Link>
            </li>
          </ul>
          <div className="left-menu">
            <CustomButton bg="fill" onClick={() => navigate(PRODUCT_LISTING)}>
              Shop
            </CustomButton>

            <Dropdown
              className="user-icon show-992"
              onToggle={() => toggleBack1()}
              show={backStatus1}
            >
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <img src={UserIcon} alt="" />
              </Dropdown.Toggle>
              {!auth ? (
                <Dropdown.Menu className="login-menu">
                  <Dropdown.Item
                    className="login-btn"
                    onClick={() => loginCall()}
                  >
                    LOG IN
                  </Dropdown.Item>
                  <p className="have-account-content">
                    Don’t you have an account?{" "}
                    <Dropdown.Item onClick={() => setSignupModalShow(true)}>
                      Sign up
                    </Dropdown.Item>
                  </p>
                </Dropdown.Menu>
              ) : (
                <Dropdown.Menu className="account-menu">
                  <h6>My Account</h6>
                  <div className="items-wrapper">
                    <Dropdown.Item onClick={() => navigate(ORDERS)}>
                      Orders
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate(SUBSCRIPTION)}>
                      Subscription
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate(PERSONALDATA)}>
                      Personal data
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => navigate(ADRESSES)}>
                      Adresses
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => navigate(COMMUNICATIONSETTINGS)}
                    >
                      Communication settings
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => handleLogout()}
                    >
                      Logout
                    </Dropdown.Item>
                  </div>
                </Dropdown.Menu>
              )}
            </Dropdown>

            {cartCount > 0 && cartDataStatus === '200' ? (
              <Dropdown
                className="add-to-cart"
                onToggle={() => toggleBack2()}
                show={backStatus2}
              >
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={AddCartFill} alt="" />
                  <p>{cartCount}</p>
                </Dropdown.Toggle>
                <MiniCart
                  toggleCart={backStatus2}
                  toggleCartMenu={toggleBack2}
                />
              </Dropdown>
            ) : (
              <Dropdown className="add-to-cart">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={AddCart} alt="" />
                </Dropdown.Toggle>
              </Dropdown>
            )}
          </div>
        </div>
      </header>

      <LoginPopup
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        onSuccess={() => setLoginModalReducer()}
        redirectValue={() => setSignupModalShow(true)}
      />
      <SignupPopup
        show={signupModalShow}
        onHide={() => setSignupModalShow(false)}
        redirectValue={() => setLoginModalShow(true)}
      />
      <div
        className={`dropdown-back ${backStatus1 === true ? "show" : ""}`}
      ></div>
      <div
        className={`dropdown-back ${backStatus2 === true ? "show" : ""}`}
      ></div>

      <ToastContainer />
    </>
  );
};

export default Header;
