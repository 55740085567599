import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../../Constant/Api";
import { addressUpdateAction } from "../../../../Redux/Account/AccountAction";
import validate from "../../../../Utility/Validation";
import CheckboxLabel from "../../../Common/CheckboxLabel/CheckboxLabel";
import CustomButton from "../../../Common/CustomButton/CustomButton";
import InputGroups from "../../../Common/InputGroups/InputGroups";
import SelectGropus from "../../../Common/SelectGroups/SelectGroups";
import { FORM_ADDRESS_FAILD, FORM_ADDRESS_LONG, FORM_ADDRESS_REQUEST, FORM_ADDRESS_SUCCESS } from "../../../../Redux/Account/AccountType"
import { isLoading } from "../../../../Utility/General";
import { SUCCESS_RESPONSE_CODE } from "../../../../Constant/Status"


const AddressPopup = (props: any) => {
  const phoneNo = ["+965", "+569", "+695"];
  const dispatch = useDispatch();
  const auth = useSelector((state: any) => state?.login?.isLoggedIn); 
  const INITIAL_ADDRESS_DATA: any = {
    isUpdate: {
      value: "0",
    },
    addressId: {
      value: "0",
    },
    alias: {
      value: "",
      //validation: ["required"],
      //errors: ["Alias is required."],
    },
    firstName: {
      value: auth ? JSON.parse(localStorage.getItem("user") || '{}').firstname : "",
      validation: ["required"],
      errors: ["Firstname is required."],
    },
    lastName: {
      value: auth ? JSON.parse(localStorage.getItem("user") || '{}').lastname : "",
      validation: ["required"],
      errors: ["Lastname is required."],
    },
    countryCode: {
      value: "+965",
      validation: ["required"],
      errors: ["Country Code is required."],
    },
    phoneNumber: {
      value: "",
      validation: ["required"],
      errors: ["Phone Number is required."],
    },
    addressType: {
      value: "Apartment",
      validation: ["required"],
      errors: ["Address Type is required."],
    },
    block: {
      value: "",
      validation: ["required"],
      errors: ["Block is required."],
    },
    avenue: {
      value: "",
      //validation: ["required"],
      //errors: ["Avenue is required."],
    },
    street: {
      value: "",
      validation: ["required"],
      errors: ["Street is required."],
    },
    building: {
      value: "",
      validation: ["required"],
      errors: ["Building is required."],
    },
    customer_area: {
      value: "",
      validation: ["required"],
      errors: ["Area is required."],
    },
    floor: {
      value: "",
      validation: ["requiredIf:addressType:Appartment"],
      errors: ["Floor is required."],
    },
    apartment_number: {
      value: "",
      validation: ["requiredIf:addressType:Appartment"],
      errors: ["Appartment Number is required."],
    },
    isDefaultShipping: {
      value: "0",
    },
    isDefaultBilling: {
      value: "0",
    },
  };
  const types = [
    { id: "Apartment", name: "Apartment" },
    { id: "Home", name: "Home" },
  ];
  const [addressData, setAddressData] = useState(INITIAL_ADDRESS_DATA);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [addressDataError, setAddressDataError] = useState(demo);
  const [addressDefaultShipping,setAddressDefaultShipping] = useState(addressData?.isDefaultShipping.value)
  const [addressDefaultBilling,setAddressDefaultBilling] = useState(addressData?.isDefaultBilling.value)
  const initialDefaultShipping = props?.address?.isDefaultShipping;
  const initialDefaultBilling = props?.address?.isDefaultBilling;  
  
  useSelector((state: any) => {
    if (state?.addressUpdateData?.type === FORM_ADDRESS_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.addressUpdateData?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.addressUpdateData?.type === FORM_ADDRESS_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
     }
    } else if (state?.addressUpdateData?.type === FORM_ADDRESS_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.addressUpdateData?.type === FORM_ADDRESS_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  const setData = (field: string, value: string) => {
    let addressDetails = Object.assign({}, addressData);
    //console.log("setData",field,value);
    if(field === "isDefaultShipping" && value === "0"){
      value = value ? "0" : "1";
      setAddressDefaultShipping(value);
    }else if(field === "isDefaultBilling" && value === "0") {
      value = value ? "0" : "1";
      setAddressDefaultBilling(value);
    }else if(field === "isDefaultShipping") {
      value = value ? "1" : "0";
      setAddressDefaultShipping(value);
    }else if(field === "isDefaultBilling") {
      value = value ? "1" : "0";
      setAddressDefaultBilling(value);
    }
    addressDetails[field].value = value;
    setAddressData({ ...addressDetails });
  };

  useEffect(() => {
    if (Object.keys(props?.address).length) {
      let assignAble = [
        "alias",
        "firstName",
        "lastName",
        "countryCode",
        "phoneNumber",
        "addressType",
        "block",
        "avenue",
        "street",
        "building",
        "customer_area",
        "floor",
        "apartment_number",
        "addressId",
        "isDefaultShipping",
        "isDefaultBilling"
      ];
      let addressDetails = Object.assign({}, props?.address);
      Object.keys(addressDetails).forEach((component: any) => {
        if (assignAble.includes(component)) {
          setData(component, addressDetails[component]);
        }
      });
      setData("isUpdate", "1");
      setAddressDefaultShipping(props?.address?.isDefaultShipping);
      setAddressDefaultBilling(props?.address?.isDefaultBilling);
    } else {
      setData("isUpdate", "0");
      setAddressDefaultShipping("0");
      setAddressDefaultBilling("0");
      setAddressData(INITIAL_ADDRESS_DATA);
    }
    // setAddressDataError({ ...validate(addressData)?.errors });
  }, [props.address]);

  useEffect(() => {
    setAddressDataError({ ...validate(addressData)?.errors });
    setIsFormValidated(false);
  }, [addressData]);

  const submitHandler = (e: any, state: any) => {
    e.preventDefault(); 
   
    if (validate(addressData).isValidated === true) { 
      dispatch(
        addressUpdateAction({
          addressData: {
            alias: addressData.alias.value,
            firstname: addressData.firstName.value,
            lastname: addressData.lastName.value,
            phonenumber: addressData.phoneNumber.value,
            // apartment: addressData.apartment.value,
            addressType: addressData.addressType.value,
            countryCode: addressData.countryCode.value,
            block: addressData.block.value,
            avenue: addressData.avenue.value,
            street: addressData.street.value,
            building: addressData.building.value,
            customer_area: addressData.customer_area.value,
            floor: addressData.floor.value,
            apartment_number: addressData.apartment_number.value,
            isDefaultShipping: addressData.isDefaultShipping.value,
            isDefaultBilling: addressData.isDefaultBilling.value,
            same_as_billing:  "0",
            billingAddress: "",  
          },
          isUpdate: addressData.isUpdate.value,
          addressId: addressData.addressId.value,
          billingAddressID: addressData.addressId.value,
        })
      );
      props.onHide();
    } else {
      // console.log("error", validate(addressData)?.errors);
      setAddressDataError({ ...validate(addressData)?.errors });
      setIsFormValidated(true);
    }
  };

  // console.log("addressData", props.addressData);

  return (
    <Modal {...props} centered className="address-popup">
      <Modal.Header closeButton>
        <h5 className="m-head">
          {props.type == "add" ? "Add Address" : "Edit Address"}
        </h5>
      </Modal.Header>
      {/* <form onSubmit={(e: any) => submitHandler(e)}> */}
      <Modal.Body>
        <form className="address-form">
          <InputGroups
            onChange={(e: any) => setData("alias", e.target.value)}
            error={
              isFormValidated && addressDataError["alias"]
                ? addressDataError["alias"]
                : null
            }
            value={addressData?.alias?.value}
            type={"text"}
            label={"Alias"}
          ></InputGroups>
          <InputGroups
            value={addressData?.firstName?.value}
            onChange={(e: any) => setData("firstName", e.target.value)}
            error={
              isFormValidated && addressDataError["firstName"]
                ? addressDataError["firstName"]
                : null
            }
            type={"text"}
            label={"Name"}
          ></InputGroups>
          <InputGroups
            value={addressData?.lastName?.value}
            onChange={(e: any) => setData("lastName", e.target.value)}
            error={
              isFormValidated && addressDataError["lastName"]
                ? addressDataError["lastName"]
                : null
            }
            type={"text"}
            label={"Surname"}
          ></InputGroups>
          <div className="phone input-groups">
            <select
              value={addressData?.countryCode?.value}
              onChange={(e: any) => setData("countryCode", e.target.value)}
            >
              {phoneNo.map((items: any, index: any) => (
                <option key={index + "phoneNumber"} value={items}>
                  {items}
                </option>
              ))}
            </select>
            <InputGroups
              value={addressData?.phoneNumber?.value}
              onChange={(e: any) => setData("phoneNumber", e.target.value)}
              error={
                isFormValidated && addressDataError["phoneNumber"]
                  ? addressDataError["phoneNumber"]
                  : null
              }
              type={"text"}
              label={"Phone"}
            ></InputGroups>
          </div>
          <SelectGropus
            defaultSelect={addressData?.addressType?.value}
            onChange={(e: any) => setData("addressType", e.target.value)}
            error={
              isFormValidated && addressDataError["addressType"]
                ? addressDataError["addressType"]
                : null
            }
            value={types}
          ></SelectGropus>
          {/* <InputGroups type={"text"} label={"Apartment"}></InputGroups> */}
          <InputGroups
            value={addressData?.avenue?.value}
            onChange={(e: any) => setData("avenue", e.target.value)}
            error={
              isFormValidated && addressDataError["avenue"]
                ? addressDataError["avenue"]
                : null
            }
            type={"text"}
            label={"Avenue"}
          ></InputGroups>
          <InputGroups
            value={addressData?.block?.value}
            onChange={(e: any) => setData("block", e.target.value)}
            error={
              isFormValidated && addressDataError["block"]
                ? addressDataError["block"]
                : null
            }
            type={"text"}
            label={"Block"}
          ></InputGroups>
          <InputGroups
            value={addressData?.street?.value}
            onChange={(e: any) => setData("street", e.target.value)}
            error={
              isFormValidated && addressDataError["street"]
                ? addressDataError["street"]
                : null
            }
            type={"text"}
            label={"Street"}
          ></InputGroups>
          <InputGroups
            value={addressData?.building?.value}
            onChange={(e: any) => setData("building", e.target.value)}
            error={
              isFormValidated && addressDataError["building"]
                ? addressDataError["building"]
                : null
            }
            type={"text"}
            label={"Building"}
          ></InputGroups>
          <InputGroups
            value={addressData?.customer_area?.value}
            onChange={(e: any) => setData("customer_area", e.target.value)}
            error={
              isFormValidated && addressDataError["customer_area"]
                ? addressDataError["customer_area"]
                : null
            }
            type={"text"}
            label={"Area"}
          ></InputGroups>
          {addressData?.addressType?.value == "Apartment" ? (
            <div className="half">
              <InputGroups
                value={addressData?.floor?.value}
                onChange={(e: any) => setData("floor", e.target.value)}
                error={
                  isFormValidated && addressDataError["floor"]
                    ? addressDataError["floor"]
                    : null
                }
                type={"text"}
                label={"Floor"}
              ></InputGroups>
              <InputGroups
                value={addressData?.apartment_number?.value}
                onChange={(e: any) =>
                  setData("apartment_number", e.target.value)
                }
                error={
                  isFormValidated && addressDataError["apartment_number"]
                    ? addressDataError["apartment_number"]
                    : null
                }
                type={"text"}
                label={"Apartment number"}
              ></InputGroups>
            </div>
          ) : (
            ""
          )}
          {initialDefaultShipping === "1" ? (
            <label className="ck default-checked">
              This is default shipping address
            </label>
          ) : (
            <CheckboxLabel
              value={addressDefaultShipping}
              name="addressDefaultShipping"
              id="addressDefaultShipping"
              checked={!!parseInt(addressDefaultShipping)}
              onChange={(e: any) =>
                setData("isDefaultShipping", e.target.checked)
              }
            >
              Use as my default Shipping address
            </CheckboxLabel>
          )}
          {initialDefaultBilling === "1" ? (
            <label className="ck default-checked">
              This is default billing address
            </label>
          ) : (
            <CheckboxLabel
              value={addressDefaultBilling}
              name="addressDefaultBilling"
              id="addressDefaultBilling"
              checked={!!parseInt(addressDefaultBilling)}
              onChange={(e: any) =>
                setData("isDefaultBilling", e.target.checked)
              }
            >
              Use as my default Billing address
            </CheckboxLabel>
          )}
        </form>
      </Modal.Body>
      <div className="action-bar">
        <CustomButton
          isLoading={isLoading(apiStatus)}
          disabled={isLoading(apiStatus)}
          onClick={(e: any, state: any) => submitHandler(e, state)}
          bg="fill"
        >
          {props.type == "add" ? "Add Address" : "Update"}
        </CustomButton>
      </div>
      {/* </form> */}
    </Modal>
  );
};

export default AddressPopup;
