import { ADD_REMOVE_CART_CLEAR, ADD_REMOVE_CART_FAILD, ADD_REMOVE_CART_LONG, ADD_REMOVE_CART_REQUEST, ADD_REMOVE_CART_SUCCESS, MYCART_CLEAR, MYCART_FAILD, MYCART_GLOBAL, MYCART_REQUEST, MYCART_SUCCESS } from "./CartType";

export function addToCartReducer(state = {}, action: any) {
  switch (action.type) {
    case ADD_REMOVE_CART_REQUEST:
      return { ...action.payload };
    case ADD_REMOVE_CART_SUCCESS:
      return { ...action.payload };
    case ADD_REMOVE_CART_FAILD:
      return { ...action.payload };
    case ADD_REMOVE_CART_LONG:
      return { ...action.payload };
    case ADD_REMOVE_CART_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function myCart(state = {}, action: any) {
  switch (action.type) {
    case MYCART_REQUEST:
      return { ...action.payload };
    case MYCART_SUCCESS:
      return { ...action.payload };
    case MYCART_FAILD:
      return { ...action.payload };
    case MYCART_CLEAR:
      return {};
    default:
      return state;
  }
}

export function myCartGlobalReducer(state = {}, action: any) {
  switch (action.type) {
    case MYCART_GLOBAL:
      return { ...action.payload };
    default:
      return state;
  }
}
