import React from "react";
import { useSelector } from "react-redux";

const DiscountBanner = () => {
  const masterData = useSelector((state: any) => state?.master?.data);

  console.log(masterData);

  return (
    <>
      {masterData?.menuData?.offerText && (
        <div className="discount-banner">
          {/* <span>15% of discount in your purchase for Pural members</span> */}
          <span>{masterData?.menuData?.offerText}</span>
        </div>
      )}
    </>
  );
};

export default DiscountBanner;
