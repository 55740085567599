import { FAQ_FAILD, FAQ_LONG, FAQ_REQUEST, FAQ_SUCCESS } from "./FAQsType";

export function faq(state = {}, action: any) {
  switch (action.type) {
    case FAQ_REQUEST:
      return { ...action.payload };
    case FAQ_SUCCESS:
      return { ...action.payload };
    case FAQ_FAILD:
      return { ...action.payload };
    case FAQ_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}
