import React, { useEffect, useState } from "react";
import CartImg1 from "../../../Assets/img/cart-img-1.png";
import CartImgpl from "../../../Assets/img/cart-img-placeholder.png";
import CloseIcon from "../../../Assets/img/modal-close.svg";
import CartDelete from "../../../Assets/img/cart-trash.svg";
import { Accordion, Dropdown } from "react-bootstrap";
import SelectGropus from "../../Common/SelectGroups/SelectGroups";
import IncDecBox from "../../Common/IncDecBox/IncDecBox";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { CHECKOUT } from "../../../Constant/Route";
import { useNavigate } from "react-router-dom";
import { QUOTE_ID } from "../../../Constant/LocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { addToCartAction, myCart } from "../../../Redux/Cart/CartAction";
import { ADD_REMOVE_CART_FAILD, ADD_REMOVE_CART_REQUEST, ADD_REMOVE_CART_SUCCESS, MYCART_SUCCESS } from "../../../Redux/Cart/CartType";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { LOGIN_MODAL_SHOW } from "../../../Redux/Auth/AuthType";

export default function MiniCart(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [backStatus2, setBackStatus2] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [addRemoveCartApiStatus, setAddRemoveCartApiStatus] = useState<any>({});
  const [cart, setCart] = useState<any>({});
  const quoteId = localStorage.getItem(QUOTE_ID)? localStorage.getItem(QUOTE_ID): "";
  const cartData = useSelector((state: any) => state?.myCartGlobalData?.data);
  const auth = useSelector((state: any) => state?.login?.isLoggedIn);

  const toggleBack2 = () => {
    setBackStatus2(!backStatus2);
  };
  const redirectFunction = (e: any) => {
    navigate(e);
    props.toggleCartMenu();
  };
  useEffect(() => {
    dispatch(myCart({ quoteId: quoteId }));
  }, []);
  useEffect(() => {

    let componentData: any = {};
    cartData?.component?.forEach((component: any) => {
      componentData[component?.componentId] = component;
    });
    setCart({ ...componentData });
  }, [cartData]);

  const changeQty = (value: any, data: any) => {
    setDisableButton(true);
    let cartFlag = parseInt(data.selectedQuantity) < value ? "1" : "0";
    let productQuery = {
      cartFlag: cartFlag,
      itemId: data.itemId,
      productId: data.id,
      urlKey: data.urlKey,
      qty: "1",
      varientQuery: data.variant,
    };

    let productQueryData = {
      quoteId: quoteId,
      productQuery: [productQuery],
    };
    dispatch(addToCartAction(productQueryData));
    // dispatch(addRemoveCartAction(productQueryData));
  };

  const deleteItem = (data: any) => {
    setDisableButton(true);
    let productQuery = {
      cartFlag: "0",
      itemId: data.itemId,
      productId: data.id,
      urlKey: data.urlKey,
      qty: data.selectedQuantity,
      varientQuery: data.variant,
    };

    let productQueryData = {
      quoteId: quoteId,
      productQuery: [productQuery],
    };
    dispatch(addToCartAction(productQueryData));
    // dispatch(addRemoveCartAction(productQueryData));
  };


  useSelector((state: any) => {
    //add to cart selector
    if (
      state?.addToCartReducer?.type ===
      ADD_REMOVE_CART_REQUEST
    ) {
      if (addRemoveCartApiStatus !== API_STATUS.LOADING) {
        setAddRemoveCartApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.addToCartReducer?.data?.statusCode ===
        SUCCESS_RESPONSE_CODE &&
      state?.addToCartReducer?.type ===
        ADD_REMOVE_CART_SUCCESS
    ) {
      if (addRemoveCartApiStatus !== API_STATUS.SUCCESS) {
        setAddRemoveCartApiStatus(API_STATUS.SUCCESS);
        setDisableButton(false);
        if (!props.toggleCart) {
          if (state?.myCartGlobalData?.type === MYCART_SUCCESS) {
            props.toggleCartMenu();
          }
        }
      }
    } else if (
      state?.addToCartReducer?.type ===
      ADD_REMOVE_CART_FAILD
    ) {
      if (addRemoveCartApiStatus !== API_STATUS.FAILED) {
        setAddRemoveCartApiStatus(API_STATUS.FAILED);
      }
    }
  });

  const addToCart = (data: any, e:any) => {
    e.preventDefault();
    if (auth){
      let productQueryData = {
        quoteId: quoteId,
        productQuery: [
          {
            cartFlag: 1,
            itemId: "",
            productId: data.id,
            urlKey: data.urlKey,
            qty: 1,
            varientQuery: [],
          },
        ],
      };
    dispatch(addToCartAction(productQueryData));
    }else{
      dispatch({
        type: LOGIN_MODAL_SHOW,
        payload: { show: 1 },
      });
    }
  }
  
  return (
    <Dropdown.Menu className={`cart-menu-wrapper ${props.toggleCart ? "active"  : ""}`}>
      <div className="cart-menu">
        <div className="minicart-top">
          <button
            className="mobile-close hide-576"
            onClick={props.toggleCartMenu}
          >
            <img src={CloseIcon}></img>
          </button>
          <p className="f2036">Your bag</p>
        </div>
        <div className="your-bag">
          <div className="cart-box">
            {/* {orderData.map((data: any, index) => (
              <div className="item" key={index + "orderData"}>
                <img src={data.productImg} alt="" className="cart-data-img" />
                <div className="cart-data">
                  <p className="f1626">{data.productName}</p>
                  <div className="atrributes">
                    {data.subscriptionActive == "1" ? (
                      <div className="without-incdec">
                        <SelectGropus value={subDropdown}></SelectGropus>
                      </div>
                    ) : (
                      <div className="with-incdec">
                        <SelectGropus value={catDropdown}></SelectGropus>
                        <IncDecBox value={1}></IncDecBox>
                      </div>
                    )}
                  </div>
                  <div className="price-action">
                    <p className="price-text">{data.productPrice}</p>
                    <a href="#" className="delete">
                      <img src={CartDelete} alt="Delete Icon"></img>
                    </a>
                  </div>
                </div>
              </div>
            ))} */}
            {cart?.productList?.productListData?.map(
              (data: any, index: number) => (
                <div className="item" key={index + "orderData"}>
                  <img src={data.image} alt="" className="cart-data-img" />
                  <div className="cart-data">
                    <p className="f1626">{data.title}</p>
                    <div className="atrributes">
                      {/* {data.subscriptionActive == "1" ? (
                        <div className="without-incdec">
                          <SelectGropus value={subDropdown}></SelectGropus>
                        </div>
                      ) : (
                        <div className="with-incdec">
                          <SelectGropus value={catDropdown}></SelectGropus>
                          <IncDecBox
                            maxVal={data.qty}
                            value={data.selectedQuantity}
                          ></IncDecBox>
                        </div>
                      )} */}
                      <div className="with-incdec">
                        {/* <SelectGropus value={catDropdown}></SelectGropus> */}
                        <p className="with-category">
                          {data?.variant[0]?.value}
                        </p>
                        { data?.productType != "membership" ? (
                          <IncDecBox
                            disableButton={
                              data?.productType == "virtual"
                                ? true
                                : disableButton
                            }
                            onChange={(e: any) => {
                              changeQty(e, data);
                            }}
                            maxVal={parseInt(data.qty)}
                            value={parseInt(data.selectedQuantity)}
                          ></IncDecBox>
                        ) : '' }
                      </div>
                    </div>
                    <div className="price-action">
                      <p className="price-text">{data.price}</p>
                      <a
                        href="#"
                        className="delete"
                        onClick={() => deleteItem(data)}
                      >
                        <img src={CartDelete} alt="Delete Icon"></img>
                      </a>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <div className="action-bar">
          <CustomButton bg={"fill"} onClick={() => redirectFunction(CHECKOUT)}>
            PROCEED TO CHECKOUT
          </CustomButton>
        </div>
           {!cart?.productList?.productListData?.filter((item:any)  => item.productType === "membership").length && cart?.subscription?.subscriptionData?.productList.length > 0 ? (
        <div className="discount-box">
          <p>{cart?.subscription?.subscriptionData?.title}</p>
          <span>{cart?.subscription?.subscriptionData?.subTitle}</span>
          <Accordion>
              <Accordion.Item eventKey="0">
              <Accordion.Header>Add To Cart</Accordion.Header>
              <Accordion.Body>
                {cart?.subscription?.subscriptionData?.productList?.map(
                  (data: any, index: number) => (
                    <a
                      href="#"
                      onClick={(e:any) => addToCart(data,e)}
                      className="subs-time onem"
                      key={index + "subscriptionItemCart"}
                    >
                      {data.title + " (" + data.price + ")"}
                    </a>
                  )
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
            ) : ("")
           }
          {/* <CustomButton>ADD TO CART</CustomButton> */}
      </div>
    </Dropdown.Menu>
  );
}
