// Created by @MIS1131 for Home page

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import OwlCarousel from "react-owl-carousel";
import commaIcon from "../../Assets/img/comma.svg";
import RangeBanner from "../../Assets/img/range-banner.png";
import TinySlider from "tiny-slider-react";
import bannerMob from "../../Assets/img/herobanner-mobile.jpg";
import VectorLeft from "../../Assets/img/Vector-left.svg";
import VectorRight from "../../Assets/img/Vector-right.svg";
import Tested1 from "../../Assets/img/tested-1.png";
import Tested2 from "../../Assets/img/tested-2.png";
import Tested3 from "../../Assets/img/tested-3.png";
import { API_STATUS } from "../../Constant/Api";
import { QUOTE_ID } from "../../Constant/LocalStorage";
import { ABOUT, CLUB, PRODUCT_LISTING } from "../../Constant/Route";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { myCart } from "../../Redux/Cart/CartAction";
import { getHome } from "../../Redux/Home/HomeAction";
import {
  HOME_FAILD,
  HOME_LONG,
  HOME_REQUEST,
  HOME_SUCCESS,
} from "../../Redux/Home/HomeType";
import CustomButton from "../Common/CustomButton/CustomButton";
import Loader from "../Loader/Loader";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [homeData, setHomeData] = useState(null);
  const home = useSelector((state: any) => state?.home?.data);
  console.log(home);
  const quoteId = localStorage.getItem(QUOTE_ID)
    ? localStorage.getItem(QUOTE_ID)
    : "";

  useEffect(() => {
    dispatch(getHome());
  }, []);

  useEffect(() => {
    dispatch(myCart({ quoteId: quoteId }));
  }, []);
  // useEffect(() => {
  //   // setHomeData()
  //   let homeData:any = {};
  //   home?.component?.map((comp:any, index: number) => {
  //     homeData[comp?.componentId] = comp;
  //   })
  //   setHomeData({...homeData});
  // }, [home]);

  useSelector((state: any) => {
    if (state?.home?.type === HOME_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.home?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.home?.type === HOME_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.home?.type === HOME_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.home?.type === HOME_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  const HBoptions = {
    responsiveClass: true,
    autoplay: false,
    loop: false,
    dots: false,
    responsive: {
      0: {
        items: 1,
        nav: false,
        dots: true,
      },
      768: {
        items: 1,
        nav: true,
      },
      992: {
        items: 2,
        nav: true,
      },
    },
  };

  const settings = {
    mouseDrag: true,
    loop: false,
    controlsText: ["", ""],
    responsive: {
      0: {
        items: 1,
        nav: true,
      },
      768: {
        items: 1,
        nav: false,
      },
      992: {
        items: 2,
        nav: false,
      },
    },
  };

  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <div className="homepage">
          {home?.component?.map((comp: any, index: number) => {
            if (comp.componentId == "banner") {
              return (
                <section className="banner-wrap" key={index + "banner"}>
                  <div className="desktop-inwrap show-768">
                    <div className="banner-img">
                      <img
                        src={comp?.bannerData?.list[0]?.image}
                        alt="Banner Image"
                      />
                    </div>
                    <div className="banner-content">
                      <Container className="banner-overlay">
                        <div className="banner-inner">
                          <h5>{comp?.bannerData?.list[0]?.title}</h5>
                          <h6>{comp?.bannerData?.list[0]?.subTitle}</h6>
                          <CustomButton
                            bg={"fill"}
                            onClick={() => navigate(PRODUCT_LISTING)}
                          >
                            {comp?.buttonText}
                          </CustomButton>
                        </div>
                      </Container>
                    </div>
                  </div>

                  <div className="mobile-inwrap hide-767">
                    <div className="banner-img">
                      <img src={comp?.bannerData?.list[0]?.mobileimage} alt="Banner Image"></img>
                    </div>
                    <div className="banner-content">
                      <h5>{comp?.bannerData?.list[0]?.title}</h5>
                      <h6>{comp?.bannerData?.list[0]?.subTitle}</h6>
                    </div>
                    <div className="actions">
                      <CustomButton
                        bg={"fill"}
                        onClick={() => navigate(PRODUCT_LISTING)}
                      >
                        GET YOURS NOW
                      </CustomButton>
                    </div>
                  </div>
                </section>
              );
            } else if (comp.componentId == "qualityComponent") {
              return (
                <section
                  className="qualitypt-wrap"
                  key={index + "qualityComponent"}
                >
                  <Container>
                    <div className="quality-title">
                      <h1>{comp?.qualityComponentData?.title}</h1>
                    </div>
                    <Row>
                      {comp?.qualityComponentData?.list?.map(
                        (items: any, index: number) => (
                          <Col
                            xl={3}
                            lg={3}
                            md={6}
                            sm={6}
                            key={index + "qualityComponent"}
                          >
                            <div className="point-wrap">
                              <div className="for-img">
                                <img
                                  src={items.image}
                                  alt="Quality Point"
                                ></img>
                              </div>
                              <div className="for-desc">
                                <h4>{items.title}</h4>
                                <p className="f1422">{items.subTitle}</p>
                              </div>
                            </div>
                          </Col>
                        )
                      )}
                    </Row>
                    {comp?.isButtonActive ? (
                      <div className="action-bar">
                        <CustomButton onClick={() => navigate(ABOUT)}>
                          {comp?.buttonText}
                        </CustomButton>
                      </div>
                    ) : (
                      ""
                    )}
                  </Container>
                </section>
              );
            } else if (comp.componentId == "searchingForNappies") {
              return (
                <div className="searchfor-section">
                  <Container>
                    <h4>
                      {comp?.SearchForNappiesComponentData?.list[0]?.title}
                    </h4>
                    <p>
                      {comp?.SearchForNappiesComponentData?.list[0]?.subTitle}
                    </p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          comp?.SearchForNappiesComponentData?.list[0]
                            ?.htmlContent,
                      }}
                    ></div>
                  </Container>
                </div>
              );
            } else if (comp.componentId == "shopOurRange") {
              return (
                <div className="range-section">
                  <img
                    src={comp?.ShopOurRangeComponentData?.list[0]?.image}
                    alt=""
                  />
                  <Container>
                    <div
                      className="star-box"
                      dangerouslySetInnerHTML={{
                        __html:
                          comp?.ShopOurRangeComponentData?.list[0]?.htmlContent,
                      }}
                    >
                      {/* <div className="img-text">
                        <div className="img-wrapper">
                          <img src={VectorLeft} alt="" />
                        </div>
                        <p>Unbeatable softness</p>
                      </div>
                      <div className="img-text">
                        <div className="img-wrapper">
                          <img src={VectorRight} alt="" />
                          <img src={VectorLeft} alt="" />
                        </div>
                        <p>Absorbency you can rely on</p>
                      </div>
                      <div className="img-text">
                        <div className="img-wrapper">
                          <img src={VectorRight} alt="" />
                          <img src={VectorLeft} alt="" />
                          <img src={VectorRight} alt="" />
                        </div>
                        <p>
                          Free from chlorine bleaching, perfumes, lotions,
                          parabens, and phthalates
                        </p>
                      </div> */}
                    </div>
                    <p>{comp?.ShopOurRangeComponentData?.list[0]?.subTitle}</p>
                    <CustomButton
                      onClick={() => navigate(PRODUCT_LISTING)}
                      bg="fill"
                    >
                      {comp?.buttonText}
                    </CustomButton>
                  </Container>
                </div>
              );
            } else if (comp.componentId == "fourImageComponent") {
              return (
                <>
                  <section
                    className="certificate-wrap"
                    key={index + "fourImageComponent"}
                  >
                    <div className="cert-inwrap">
                      <Container>
                        <div className="cert-wrapper">
                          <div className="cert-desc">
                            <h1>{comp?.fourImageComponentData?.title}</h1>
                            <p>{comp?.fourImageComponentData?.subTitle}</p>
                          </div>
                          <div className="cert-slider">
                            <TinySlider settings={settings}>
                              {comp?.fourImageComponentData?.list?.map(
                                (items: any, index: number) => (
                                  <div key={index + "certificates"}>
                                    <div className="cert-card">
                                      <div className="cert-img">
                                        <img
                                          src={items.image}
                                          alt="Certificate Slider"
                                        />
                                      </div>
                                      <div className="cert-title">
                                        <h4>{items.title}</h4>
                                      </div>
                                      <div className="cert-msg">
                                        <p>{items.subTitle}</p>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </TinySlider>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </section>

                  <div className="tested-section">
                    <Container>
                      <h2>{comp?.fourImageComponentData?.title}</h2>
                      <div>
                        {comp?.fourImageComponentData?.list?.map(
                          (items: any, index: number) => (
                            <div className="this-tested-box">
                              <img src={items.image} alt="" />
                              <p>{items.title}</p>
                              <span>{items.subTitle}</span>
                            </div>
                          )
                        )}
                        {/* <div className="this-tested-box">
                          <img src={Tested1} alt="" />
                          <p>OEKO-TEX CERTIFICATE</p>
                          <span>
                            Tested for harmnful substances by Oeko-Tex©
                          </span>
                        </div>
                        <div className="this-tested-box">
                          <img src={Tested2} alt="" />
                          <p>DERMATEST CERTIFICATE</p>
                          <span>
                            Tested and confirmed quality by Dermatology
                            Institute Dermatest©{" "}
                          </span>
                        </div>
                        <div className="this-tested-box">
                          <img src={Tested3} alt="" />
                          <p>Allergy friendly product</p>
                          <span>
                            We’re committed to helping the millions of people
                            who live with allergic disease
                          </span>
                        </div> */}
                      </div>
                    </Container>
                  </div>
                </>
              );
            } else if (comp.componentId == "twoImageComponent") {
              return (
                <section
                  className="clubpart-wrap"
                  key={index + "twoImageComponent"}
                >
                  <div className="club-inwrap">
                    <div className="club-left">
                      <img
                        src={comp?.twoImageComponentData?.leftSide[0]?.image}
                        alt="Club Part Banner"
                      />
                    </div>
                    <div className="club-right">
                      <div className="cr-in">
                        <div className="club-head">
                          <h1 className="show-992">
                            {comp?.twoImageComponentData?.rightSide?.title}
                          </h1>
                          <h1 className="hide-991">Become part of the Club</h1>
                          <p className="hide-991">
                            {comp?.twoImageComponentData?.rightSide?.title}
                          </p>
                        </div>
                        <div className="cpoint-details">
                          {comp?.twoImageComponentData?.rightSide?.items?.map(
                            (items: any, index: number) => (
                              <div
                                className="point"
                                key={index + "cpoint-details"}
                              >
                                <div className="cp-img">
                                  <img src={items.image} alt="Club Point"></img>
                                </div>
                                <div className="cp-text">
                                  <h4>{items.title}</h4>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div className="action-bar">
                          <CustomButton onClick={() => navigate(CLUB)}>
                            Explore Now
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              );
            } else if (comp.componentId == "shopNappies") {
              return (
                <div className="pural-mappies-section">
                  <Container>
                    {/* <img src={commaIcon} alt="" /> */}
                    <img
                      src={comp?.ShopNappiesComponentData?.list[0].image}
                      alt=""
                    />
                    <h4>{comp?.ShopNappiesComponentData?.list[0]?.title}</h4>
                    <CustomButton
                      onClick={() => navigate(PRODUCT_LISTING)}
                      bg="fill"
                    >
                      Shop NAPPIES
                    </CustomButton>
                  </Container>
                </div>
              );
            } else if (comp.componentId == "joinOurClub") {
              return (
                <div className="join-our-club">
                  <Container>
                    <h4>{comp?.JoinOurClubComponentData?.list[0]?.title}</h4>
                    <p>{comp?.JoinOurClubComponentData?.list[0]?.subTitle}</p>
                    <CustomButton onClick={() => navigate(CLUB)}>
                      {comp?.buttonText}
                    </CustomButton>
                  </Container>
                </div>
              );
            } else if (comp.componentId == "dermatestProcess") {
              return (
                <div className="testing-section">
                  <Container>
                    <p>
                      {comp?.DermatestProcessComponentData?.list[0].subTitle}
                    </p>
                    <br />
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          comp?.DermatestProcessComponentData?.list[0]
                            ?.htmlContent,
                      }}
                    ></div>
                    {/* <p>
                      Find out about the Dermatest process{" "}
                      <a className="here-link">here.</a>
                    </p> */}
                  </Container>
                </div>
              );
            }

            // else if(comp.componentId == ""){

            // }
            else if (comp.componentId == "singleBanner") {
              return (
                <section className="premium-wrap" key={index + "singleBanner"}>
                  <div className="pm-inwrap">
                    <div className="pm-content">
                      <p className="f4048">
                        {comp?.singleBannerData?.list[0]?.title}
                      </p>
                      <div className="action-bar">
                        <div className="action-bar">
                          {comp?.isButtonActive ? (
                            <CustomButton
                              bg="fill"
                              onClick={() => navigate(PRODUCT_LISTING)}
                            >
                              {comp?.buttonText}
                            </CustomButton>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              );
            }
          })}
        </div>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    // return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
};

export default Home;
