import axios from "axios";
import { store } from "../Redux/Store";
import { getUrl } from "../Utility/General";
// For GET requests

export function getTokan() {
  return store.getState()?.master?.data?.authToken;
}

export function getDefaultParameters() {
  return store.getState()?.defaultParameter;
}

axios.interceptors.request.use(
  (req: any) => {
    // Add configurations here

    req.headers = { Authorization: getTokan() };

    if (req.method !== "get") {
      req.data["defaultParameters"] = getDefaultParameters();
    } else if (req.method === "get") {
      if (!req.url.includes(".json")) {
        req.url = getUrl(req.url, getDefaultParameters());
      }
    }

    return req;
  },
  (err) => {
    // Add configurations here
    return Promise.reject(err);
  }
);

// For POST requests
axios.interceptors.response.use(
  (res) => {
    // Add configurations here
    return res;
  },
  (err) => {
    // Add configurations here
    return Promise.reject(err);
  }
);
