// import React, { Component } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route } from "react-router-dom";

// const ProtectedRoutes = ({ component, ...rest }: any) => {
//     const isLoggedIn = useSelector((state:any) =>
//       state.login.isLoggedIn ? state.login.isLoggedIn : false
//     );
//   const routeComponent = (props: any) =>
//     isLoggedIn ? (
//     //   React.createElement(component, props)
//         <Component {...props} />
//     ) : (
//       <Navigate to={{ pathname: "/login" }} />
//     );
//   return <Route {...rest} render={routeComponent} />;
// };
const ProtectedRoutes = ({ children }: any) => {
  const isLoggedIn = useSelector((state: any) =>
    state.login.isLoggedIn ? state.login.isLoggedIn : false
  );
  if (!isLoggedIn) {
    return <Navigate to="/" replace />;
  }
  return children;
};
export default ProtectedRoutes;