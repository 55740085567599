import React, { useEffect, useState } from "react";

const InputGroups = (props: any) => {
  const [isActive, setIsActive] = useState(false);

  function handleTextChange(text: any) {
    text !== "" ? setIsActive(true) : setIsActive(false);
  }

	const topLabel = (e:any) => {
		handleTextChange(e.target.value);
		if(props.onChange){
      props.onChange(e);
    }
	}

  useEffect(() => {
    if (props.value) {
      handleTextChange(props.value);
    } else if (props.defaultValue) {
      handleTextChange(props.defaultValue);
    }
  }, [props.value]);

  return (
    <div className="input-groups">
      <label className={isActive ? "active" : ""} htmlFor="email">
        {props.label}
      </label>
      <input
				type={props.type}
        inputMode={props.inputmode}
        defaultValue={props.defaultValue}
        value={props.value}
        placeholder={props.placeholder}
        readOnly = {props.readOnly}
        autoComplete={props.autoComplete}
        name={props.name}
        id={props.id}
        className={props.error && 'error'}
        onChange={(e) => topLabel(e)}
      />
      {props.error && <span className="error">{props.error}</span>}
    </div>
  );
};

export default InputGroups;
