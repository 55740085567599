import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { LOGIN_MODAL_SHOW } from "../../../Redux/Auth/AuthType";
import { addToCartAction } from "../../../Redux/Cart/CartAction";
import { useDispatch, useSelector } from "react-redux"
import { QUOTE_ID } from "../../../Constant/LocalStorage";
import { getClubPageAction } from "../../../Redux/Club/ClubAction";

const SubscriptionPopup = (props: any) => {
  const dispatch = useDispatch()
  const quoteId = localStorage.getItem(QUOTE_ID) ? localStorage.getItem(QUOTE_ID) : ""
  const auth = useSelector((state: any) => state?.login?.isLoggedIn);
  const clubPage = useSelector((state: any) => state?.getClubPageReducer?.data);
  useEffect(() => {
    dispatch(getClubPageAction());
  }, []);
  useEffect(() => {
    if(clubPage?.component?.length > 0) {
      clubPage?.component?.filter((item: any) => {
        if(item?.componentId === "puralSubscriptionComponent" && item?.isActive === "1" && item?.puralSubscriptionComponentData?.items.length >0 ){
            selectPlan(item?.puralSubscriptionComponentData?.items[0]?.productId);
            setMembershipCartData(item?.puralSubscriptionComponentData?.items[0]);
        }
      })
    }
  },[clubPage])

  //const clubSubscription = [
  //  {
  //    price: "$8",
  //    timePeriod: "1 month",
  //  },
  //  {
  //    price: "$20",
  //    timePeriod: "3 months",
  //  },
  //];

  const [isSelected, setIsSelected] = useState(false);

  const selectPlan = (index: any) => {
    setIsSelected(index);
  };

  const INITIAL_CART_DATA = {
    quoteId: quoteId,
    productQuery: [
      {
        cartFlag: 1,
        itemId: "",
        productId: "",
        urlKey: "",
        qty: 1,
        varientQuery: [],
      },
    ],
  };
  const [addCartData, setAddCartData] = useState(INITIAL_CART_DATA);
  const [isValidate, setIsValidate] = useState(false);
  const [selectProductError, setSelectProductError] = useState<any>('');
  const setMembershipCartData = (data: any) => {
    setIsValidate(true);
    setAddCartData({
      ...addCartData,
      productQuery: [
        {
          ...addCartData.productQuery[0],
          productId: data.productId,
          urlKey: data.urlKey,
        },
      ],
    });
  }

  const addToCart = (e: any) => {
    e.preventDefault();
    if (auth){
      if (isValidate) {
        dispatch(addToCartAction(addCartData));
      } else {
        setSelectProductError("Please select the Subscription Plan");
      }
    }else{
      dispatch({
        type: LOGIN_MODAL_SHOW,
        payload: { show: 1 },
      });
    }
    props.onHide();
  };

  return (
    <>
      <Modal {...props} centered className="subscription-popup">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            {clubPage?.component?.map(
              (data:any, index: any) => {
                if(data?.componentId == "puralSubscriptionComponent") {
                  return(
                    <>
                      <h5 className="m-head">
                        Enjoy 10% discount on every purchase and 50% on each delivery!
                      </h5>
                      <p className="m-desc">
                        Choose your subscription and add it to your bag. The price will be
                        automatically updated in your shopping cart.
                      </p>
                      <div className="cpoint-details">
                        {data?.puralSubscriptionComponentData?.items?.map(
                          (data: any, index: any) => (
                            <div
                              className={isSelected == data?.productId ? "point active" : "point"}
                              key={index + "cpoint-details2"}
                              onClick={() => (selectPlan(data?.productId),setMembershipCartData(data))}
                            >
                              <div className="time">
                                <span>{data?.title}</span>
                              </div>
                              <div className="amount">
                                <p className="price-text">{data?.price}</p>
                              </div>
                            </div>
                            )
                          )
                        }
                      </div>
                      {!isValidate ? <p className="subscriptionError">{selectProductError}</p> : ""}
                    </>
                  )
                }
               }
              )
            }
          <div className="action-bar">
            <CustomButton bg="fill" onClick={(e:any) => addToCart(e)}>
              Add To Cart
            </CustomButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubscriptionPopup;
