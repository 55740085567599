import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { getOrdersAction,invoiceAction } from "../../../Redux/Account/AccountAction";
import noorderimg from "../../../Assets/img/no-order.svg";

import CustomButton from "../../Common/CustomButton/CustomButton";
import {
  ORDERS_FAILD,
  ORDERS_LONG,
  ORDERS_NO_DATA,
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
} from "../../../Redux/Account/AccountType";
import Loader from "../../Loader/Loader";
import { addToCartAction } from "../../../Redux/Cart/CartAction";
import { QUOTE_ID } from "../../../Constant/LocalStorage";

const Orders = () => {
  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const orders = useSelector((state: any) => state?.getOrders?.data);
  const buyAgaingData = useSelector((state: any) => state?.orderBuyAgainReducer?.data);
  const Invoice = useSelector((state: any) => state?.invoiceReducer?.data);
  const [invoiceId, setInvoiceId] = useState(false)
  let quoteId = localStorage.getItem(QUOTE_ID)
  ? localStorage.getItem(QUOTE_ID)
  : "";

    

  useEffect(() => {
    dispatch(getOrdersAction());
  }, []);

  const buyAgainHandler = (data: any) => {
     let productQuery =  [];
      for(let i= 0; i<data.length; i++) {
        productQuery.push({
            cartFlag: "1",
            itemId: data[i].itemId,
            productId: data[i].productId,
            urlKey: data[i].urlKey,
            qty: data[i].qty,
            varientQuery: (typeof data[i].variant !== 'undefined' && data[i].variant.length > 0) ? (
           [{
                attributeId:  data[i]?.variant[0]?.optionId.toString(),
                optionId: data[i]?.variant[0]?.attributeId,
              }]
            ) : ([""]),
        })
      };
     const INITIAL_CART_DATA = {
      quoteId: quoteId,
      productQuery
     } 
    dispatch(addToCartAction(INITIAL_CART_DATA));
  }
  
  
  const invoiceHandler = ( id:any ) => {
    dispatch(
      invoiceAction({
        invoiceId: id
      })
    );
  }
  useSelector((state: any) => {
    if (state?.getOrders?.type === ORDERS_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.getOrders?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.getOrders?.type === ORDERS_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.getOrders?.type === ORDERS_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.getOrders?.type === ORDERS_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    } else if (state?.getOrders?.type === ORDERS_NO_DATA) {
      if (apiStatus !== API_STATUS.NO_DATA) {
        setApiStatus(API_STATUS.NO_DATA);
      }
    }
  });
  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <div className="orders-wrap">
          <div className="page-title">
            <h5>Orders</h5>
          </div>
          <div className="page-content">
            {orders?.component && orders?.component[0] !== "undefined" ? 
            <Accordion>
              {orders?.component[0]?.myOrdersData.map(
                (data: any, index: any) => (
                  <Accordion.Item
                    eventKey={index}
                    key={index + "order-details"}
                  >
                    <Accordion.Header>
                      <div className="order-info">
                        <div className="order-id">#{data.textOrderId}</div>
                        <div className="placed-date f1214">
                          {data.textOrderDate}
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <table>
                        <thead>
                          <tr>
                            <th className="col one">Article</th>
                            <th className="col two">Reference</th>
                            <th className="col three">Quantity</th>
                            <th className="col four">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.items.map((data1: any, index1: any) => (
                            <tr key={index1 + "order-detail-table"}>
                              <td className="col one">
                                <div className="product-nameinfo">
                                  <p className="p-name">{data1.itemName}</p>
                                  {data1.variant?.length ? (
                                    <span className="p-cat">
                                      {data1.variant[0]?.value}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                              <td className="col two">{data1.sku}</td>
                              <td className="col three">{data1.qty}</td>
                              <td className="col four">{data1.price}</td>
                            </tr>
                          ))}
                          <tr className="tfooter">
                            <td className="col one st">
                              {data.priceDetail[0]?.leftText}
                            </td>
                            <td className="col two st"></td>
                            <td className="col three st"></td>
                            <td className="col four st">
                              {data.priceDetail[0]?.rightText}
                            </td>
                          </tr>
                          <tr className="tfooter">
                            <td className="col one">
                              {data.priceDetail[1]?.leftText}
                            </td>
                            <td className="col two"></td>
                            <td className="col three"></td>
                            <td className="col four">
                              {data.priceDetail[1]?.rightText}
                            </td>
                          </tr>
                          <tr className="tfooter">
                            <td className="col one dc">
                              {data.priceDetail[2]?.leftText}
                            </td>
                            <td className="col two dc"></td>
                            <td className="col three dc"></td>
                            <td className="col four dc">
                              {data.priceDetail[2]?.rightText}
                            </td>
                          </tr>
                          <tr className="tfooter">
                            <td className="col one gt">
                              {data.grandTotal[0]?.leftText}
                              <span>{data.grandTotal[0]?.subTitle}</span>
                            </td>
                            <td className="col two gt"></td>
                            <td className="col three gt"></td>
                            <td className="col four gt">
                              {data.grandTotal[0]?.rightText}
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <div className="order-actions hide-991 mobile">
                        <CustomButton title={"Buy Again"} onClick={() => buyAgainHandler(data.items)}>
                          Buy Again
                        </CustomButton>
                        <CustomButton title={"Invoice"} disabled={data.invoiceId === 0}  onClick={() => invoiceHandler(data.invoiceId)}>Invoice</CustomButton>
                      </div>
                    </Accordion.Body>
                    <div className="order-actions show-992 desk">
                      <CustomButton title={"Buy Again"}  onClick={() => buyAgainHandler(data.items)}>Buy Again</CustomButton>
                      <CustomButton disabled={data.invoiceId === 0} title={"Invoice"} onClick={() => invoiceHandler(data.invoiceId)}>Invoice</CustomButton>
                    </div>
                  </Accordion.Item>
                )
              )}
            </Accordion>
            : '' }
          </div>
        </div>
      );
    case API_STATUS.NO_DATA:
      return (
        <div className="orders-wrap no-order">
          <div className="no-orderwrap">
            <img src={noorderimg} alt="No Order Placed" />
            <label>No orders yet!</label>
          </div>
        </div>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    // return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
};

export default Orders;
