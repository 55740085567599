import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../../Constant/Status";
import { personalDataAction, setPersonalDataAction } from "../../../../Redux/Account/AccountAction";
import { SET_PERSONAL_DATA_CLEAR, SET_PERSONAL_DATA_FAILD, SET_PERSONAL_DATA_LONG, SET_PERSONAL_DATA_REQUEST, SET_PERSONAL_DATA_SUCCESS } from "../../../../Redux/Account/AccountType";
import { isLoading } from "../../../../Utility/General";
import validate from "../../../../Utility/Validation";
import CustomButton from "../../../Common/CustomButton/CustomButton";
import InputGroups from "../../../Common/InputGroups/InputGroups";

const PDataNamePopup = (props: any) => {
  const dispatch = useDispatch();
  const INITIAL_PERSONAL_DATA: any = {
    firstname: {
      value: props.firstName,
      validation: ["required"],
      errors: ["Name is required."],
    },
    lastname: {
      value: props.lastName,
      validation: ["required"],
      errors: ["Surname is required."],
    },
  };

  const [personalData, setPersonalData] = useState(INITIAL_PERSONAL_DATA);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [personalDataError, setPersonalDataError] = useState(demo);
  const loginData = useSelector((state: any) => state?.login?.data);
  const updatePersonalData = useSelector((state: any) => state?.setPersonalData);
  useEffect(() => {
    //console.log('updatePersonalData', updatePersonalData)
    if(updatePersonalData?.type === SET_PERSONAL_DATA_SUCCESS){
      dispatch({type: SET_PERSONAL_DATA_CLEAR});
      dispatch(personalDataAction(loginData))
    }
  },[updatePersonalData])

  const setData = (field: string, value: string) => {
    let signupDetails = Object.assign({}, personalData);
    signupDetails[field].value = value;
    setPersonalData({ ...signupDetails });
  };

  useEffect(() => {
    setPersonalDataError({ ...validate(personalData)?.errors });
  }, [personalData]);


  const submitHandler = (e: any) => {
    e.preventDefault();
    if (validate(personalData).isValidated === true) {
      dispatch(
        setPersonalDataAction({
          firstName: personalData.firstname.value,
          lastName: personalData.lastname.value
        })
      );
    } else {
      setPersonalDataError({ ...validate(personalData)?.errors });
      setIsFormValidated(true);
    }
  }


  useSelector((state: any) => {
    if (state?.setPersonalData?.type === SET_PERSONAL_DATA_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.setPersonalData?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.setPersonalData?.type === SET_PERSONAL_DATA_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        props.onHide();
      }
    } else if (state?.setPersonalData?.type === SET_PERSONAL_DATA_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.setPersonalData?.type === SET_PERSONAL_DATA_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });


  return (
    <Modal {...props} centered className="pdataname-popup">
      <Modal.Header closeButton>
        <h5 className="m-head">Name</h5>
      </Modal.Header>
      <form onSubmit={(e: any) => submitHandler(e)}>
        <Modal.Body>
          <div className="personaldata-form">
            <InputGroups
              type={"text"}
              label={"Name"}
              value={personalData.firstname.value}
              onChange={(e: any) => setData("firstname", e.target.value)}
              error={
                isFormValidated && personalDataError["firstname"]
                  ? personalDataError["firstname"]
                  : null
              }
            />
            <InputGroups
              type={"text"}
              label={"Surname"}
              value={personalData.lastname.value}
              onChange={(e: any) => setData("lastname", e.target.value)}
              error={
                isFormValidated && personalDataError["lastname"]
                  ? personalDataError["lastname"]
                  : null
              }
            />
          </div>
        </Modal.Body>
        <div className="action-bar">
          <CustomButton
            bg="fill"
            isLoading={isLoading(apiStatus)}
            disabled={isLoading(apiStatus)}
            onClick={(e: any) => submitHandler(e)}
          >
            Update
          </CustomButton>
        </div>
      </form>
    </Modal>
  );
};

export default PDataNamePopup;
