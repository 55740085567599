import { useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import clubbannershapeMob from "../../Assets/img/mshape-club.svg";
import clubbannershapeDesk from "../../Assets/img/shape-club.svg";
import AboutImg1 from "../../Assets/img/about-img-1.png";
import { API_STATUS } from "../../Constant/Api";
import { QUOTE_ID } from "../../Constant/LocalStorage";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { LOGIN_MODAL_SHOW } from "../../Redux/Auth/AuthType";
import { addToCartAction } from "../../Redux/Cart/CartAction";
import { getClubPageAction } from "../../Redux/Club/ClubAction";
import TinySlider from "tiny-slider-react";
import {
  PURAL_CLUB_FAILD,
  PURAL_CLUB_LONG,
  PURAL_CLUB_REQUEST,
  PURAL_CLUB_SUCCESS,
} from "../../Redux/Club/ClubType";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import CustomButton from "../Common/CustomButton/CustomButton";
import Loader from "../Loader/Loader";
import SomethingWrong from "../SomethingWrong/SomethingWrong";
import { isLoading } from "../../Utility/General";
import {
  ADD_REMOVE_CART_FAILD,
  ADD_REMOVE_CART_LONG,
  ADD_REMOVE_CART_REQUEST,
  ADD_REMOVE_CART_SUCCESS,
} from "../../Redux/Cart/CartType";

const Club = () => {
  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [quoteId, setQuoteId] = useState(
    localStorage.getItem(QUOTE_ID) ? localStorage.getItem(QUOTE_ID) : ""
  );
  const [addToCartStatus, setAddToCartStatus] = useState(API_STATUS.SUCCESS);
  const auth = useSelector((state: any) => state?.login?.isLoggedIn);
  const clubPage = useSelector((state: any) => state?.getClubPageReducer?.data);
  // console.log(clubPage);
  const INITIAL_CART_DATA = {
    quoteId: quoteId,
    productQuery: [
      {
        cartFlag: 1,
        itemId: "",
        productId: "",
        urlKey: "",
        qty: 1,
        varientQuery: [],
      },
    ],
  };
  const [addCartData, setAddCartData] = useState(INITIAL_CART_DATA);
  const [isValidate, setIsValidate] = useState(false);
  const [selectProductError, setSelectProductError] = useState<any>("");

  useEffect(() => {
    dispatch(getClubPageAction());
  }, []);

  useEffect(() => {
    if (clubPage?.component?.length > 0) {
      clubPage?.component?.filter((item: any) => {
        if (
          item?.componentId === "puralSubscriptionComponent" &&
          item?.isActive === "1" &&
          item?.puralSubscriptionComponentData?.items.length > 0
        ) {
          selectPlan(item?.puralSubscriptionComponentData?.items[0]?.productId);
          setMembershipCartData(item?.puralSubscriptionComponentData?.items[0]);
        }
      });
    }
  }, [clubPage]);

  useSelector((state: any) => {
    if (state?.getClubPageReducer?.type === PURAL_CLUB_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.getClubPageReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.getClubPageReducer?.type === PURAL_CLUB_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.getClubPageReducer?.type === PURAL_CLUB_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.getClubPageReducer?.type === PURAL_CLUB_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }

    if (state?.addToCartReducer?.type === ADD_REMOVE_CART_REQUEST) {
      if (addToCartStatus !== API_STATUS.LOADING) {
        setAddToCartStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.addToCartReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.addToCartReducer?.type === ADD_REMOVE_CART_SUCCESS
    ) {
      if (addToCartStatus !== API_STATUS.SUCCESS) {
        setAddToCartStatus(API_STATUS.SUCCESS);
      }
      /*if (!quoteId) {
        setQuoteId(state?.addToCartReducer?.data?.quoteId);
        setAddCartData({
          ...addCartData,
          quoteId: state?.addToCartReducer?.data?.quoteId,
        });
      }*/
    } else if (state?.addToCartReducer?.type === ADD_REMOVE_CART_LONG) {
      if (addToCartStatus !== API_STATUS.LONG) {
        setAddToCartStatus(API_STATUS.LONG);
      }
    } else if (state?.addToCartReducer?.type === ADD_REMOVE_CART_FAILD) {
      if (addToCartStatus !== API_STATUS.FAILED) {
        setAddToCartStatus(API_STATUS.FAILED);
      }
    }
  });

  const accBreadcrumb = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "/club",
      label: "The Club",
    },
  ];

  const [isSelected, setIsSelected] = useState(false);

  const selectPlan = (index: any) => {
    setIsSelected(index);
  };
  const setMembershipCartData = (data: any) => {
    setIsValidate(true);
    setAddCartData({
      ...addCartData,
      productQuery: [
        {
          ...addCartData.productQuery[0],
          productId: data.productId,
          urlKey: data.urlKey,
        },
      ],
    });
  };

  const addToCart = (e: any) => {
    e.preventDefault();
    if (auth) {
      if (isValidate) {
        dispatch(addToCartAction(addCartData));
      } else {
        setSelectProductError("Please select the Subscription Plan");
      }
    } else {
      dispatch({
        type: LOGIN_MODAL_SHOW,
        payload: { show: 1 },
      });
    }
  };

  const addToCartBtnHandler = (data: any) => {
    setIsValidate(true);
    let addCartDataState = addCartData;
    addCartDataState = {
      ...addCartData,
      productQuery: [
        {
          ...addCartData.productQuery[0],
          productId: data.productId,
          urlKey: data.urlKey,
        },
      ],
    };
    if (auth) {
      if (isValidate) {
        dispatch(addToCartAction(addCartDataState));
      } else {
        setSelectProductError("Please select the Subscription Plan");
      }
    } else {
      dispatch({
        type: LOGIN_MODAL_SHOW,
        payload: { show: 1 },
      });
    }
  };

  const regex = /(<([^>]+)>)/gi;

  const settings = {
    mouseDrag: true,
    loop: false,
    controlsText: ["", ""],
    arrowKeys: true,
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      768: {
        items: 1,
        nav: false,
      },
      992: {
        items: 1,
        nav: false,
      },
    },
  };

  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <>
          {clubPage?.isActive === "1" ? (
            <div className="club-wrap">
              {clubPage?.component?.map((comp: any, index: number) => {
                if (comp?.componentId == "pageTitleComponent") {
                  return (
                    <div className="banner-wrap" key={index + "clubPage"}>
                      <div className="cmsbanner-wrap desktop show-768">
                        <div className="cmsbanner-img">
                          <img
                            src={comp?.pageTitleComponentData?.banner_image}
                            alt="Banner Image"
                          ></img>
                        </div>
                        <div className="cmsbanner-inwrap">
                          <Container>
                            <div className="cmsbanner-content">
                              <img src={clubbannershapeDesk} alt="Vector"></img>
                              <div className="cmsshape">
                                <Breadcrumbs
                                  value={accBreadcrumb}
                                ></Breadcrumbs>
                                <h1>
                                  {comp?.pageTitleComponentData?.page_name}
                                </h1>
                              </div>
                            </div>
                          </Container>
                        </div>
                      </div>
                      <div className="cmsbanner-wrap mobile hide-767">
                        <div className="cmsbanner-img">
                          <img
                            src={comp?.pageTitleComponentData?.mobile_image}
                            alt="Banner Image"
                          ></img>
                          <Breadcrumbs value={accBreadcrumb}></Breadcrumbs>
                        </div>
                        <div className="cmsbanner-inwrap">
                          <div className="cmsbanner-content">
                            <img src={clubbannershapeMob} alt="Vector"></img>
                            <div className="abtshape">
                              <h1>{comp?.pageTitleComponentData?.page_name}</h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else if (comp?.componentId == "pageMainSectionComponent") {
                  return (
                    <div className="club-cms-section">
                      <Container>
                        <div
                          className="info-wrap"
                          dangerouslySetInnerHTML={{
                            __html: comp?.pageMainSectionComponentData?.editor,
                          }}
                        ></div>
                        {/* <div className="info-wrap">
                          <h2>Pural Club Membership</h2>
                          <h3>
                            Join the Pural Club and make sure you’re never
                            caught without the essentials.
                          </h3>
                          <p>
                            You glance down into your lap to see a face of pure
                            concentration, slightly red cheeks, and a furrowed
                            brow.
                          </p>
                          <br />
                          <p>Someone’s filled their nappy again.</p>
                          <img src={AboutImg1} alt="" />
                          <p>
                            You lay them down on the changing mat, undoing the
                            onesie poppers whilst trying to wrangle wriggly
                            limbs.
                          </p>
                          <br />
                          <p>
                            Using one hand to stop the baby rolling off onto the
                            floor, you reach with the other for a fresh nappy...
                            But all you find is an empty bag... Oh...
                          </p>
                          <br />
                          <p>
                            Life is busy, and the last thing you want is to find
                            out you’ve run out of nappies when you’ve got a code
                            brown.
                          </p>
                          <br />
                          <p>
                            Our Pural Club members never have to worry about
                            running out.
                          </p>
                          <br />
                          <p>
                            With our flexible subscriptions, your nappies are
                            delivered straight to your door, without you needing
                            to remember to put them on the grocery list.
                          </p>
                          <img src={AboutImg1} alt="" />
                          <h3>
                            We make your life easier...and keep your nappy shelf
                            stocked.
                          </h3>
                        </div> */}
                      </Container>
                    </div>
                  );
                } else if (comp?.componentId == "puralOfferComponent") {
                  return (
                    <div className="grey-bg-mobile">
                      <Container key={index + "clubPage"}>
                        <div className="joinclub-wrap text-center">
                          <h2>{comp?.puralOfferComponentData?.title}</h2>
                          <Row>
                            {comp?.puralOfferComponentData?.items.map(
                              (data: any, index: number) => (
                                <Col
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  className="point-in"
                                  key={index + "joinclubWrap"}
                                >
                                  <div className="for-img">
                                    <img
                                      src={data?.image}
                                      alt="Clubpoints"
                                    ></img>
                                  </div>
                                  <div className="for-desc">
                                    <h4>{data?.title}</h4>
                                    <p>{data?.subtitle}</p>
                                  </div>
                                </Col>
                              )
                            )}
                          </Row>
                        </div>
                      </Container>
                    </div>
                  );
                } else if (
                  comp?.componentId == "puralSubscriptionComponent" &&
                  comp?.isActive === "1" &&
                  comp?.puralSubscriptionComponentData?.items.length > 0
                ) {
                  return (
                    <section className="clubpart-wrap" key={index + "clubPage"}>
                      <div className="club-inwrap">
                        <div className="club-left">
                          <img
                            src={comp?.puralSubscriptionComponentData?.image}
                            alt="Club Part Banner"
                          ></img>
                        </div>
                        <div className="club-right">
                          <div className="cr-in">
                            <div className="club-head">
                              <p className="show-992">
                                {
                                  comp?.puralSubscriptionComponentData
                                    ?.description
                                }
                              </p>
                              <p className="show-992">
                                {
                                  comp?.puralSubscriptionComponentData
                                    ?.subDescription
                                }
                              </p>
                              <p className="hide-991">
                                {
                                  comp?.puralSubscriptionComponentData
                                    ?.description
                                }
                              </p>
                            </div>
                            <div className="cpoint-details">
                              {comp?.puralSubscriptionComponentData?.items?.map(
                                (data: any, index: any) => (
                                  <div
                                    className={
                                      isSelected == data?.productId
                                        ? "point active"
                                        : "point"
                                    }
                                    key={index + "cpoint-details"}
                                    onClick={() => (
                                      selectPlan(data?.productId),
                                      setMembershipCartData(data)
                                    )}
                                    // onClick={() => selectPlan(data.urlKey)}
                                  >
                                    <div className="time">
                                      <span>{data?.title}</span>
                                    </div>
                                    <div className="amount">
                                      <p className="price-text">
                                        {data?.price}
                                      </p>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                            {!isValidate ? (
                              <p className="subscriptionError">
                                {selectProductError}
                              </p>
                            ) : (
                              ""
                            )}

                            <div className="action-bar">
                              <CustomButton
                                onClick={(e: any) => addToCart(e)}
                                isLoading={isLoading(addToCartStatus)}
                                disabled={isLoading(addToCartStatus)}
                                bg="fill"
                              >
                                Add To Cart
                              </CustomButton>
                            </div>
                          </div>
                        </div>
                        <div className="mobile-subscribe-section">
                          <Container>
                            <h2>Choose you subscription</h2>
                            <div className="cert-slider">
                              <TinySlider settings={settings}>
                                {comp?.puralSubscriptionComponentData?.items.map(
                                  (values: any, index3: number) => (
                                    <div
                                      className="subscription-card"
                                      key={index3 + "sub-box"}
                                    >
                                      <div className="subscription-title">
                                        {values.title}
                                      </div>
                                      <div>
                                        <p>{values.price}</p>
                                        <p>{values.membership_discount}</p>
                                        <p>
                                          {values.membership_delivery_discount}
                                        </p>
                                      </div>
                                      <span>
                                        {values.membership_product_note}
                                      </span>
                                      <CustomButton
                                        bg="fill"
                                        onClick={() =>
                                          addToCartBtnHandler(values)
                                        }
                                      >
                                        ADD TO CART
                                      </CustomButton>
                                    </div>
                                  )
                                )}
                                {/* <div className="subscription-card">
                                  <div className="subscription-title">
                                    3 months subscription
                                  </div>
                                  <div>
                                    <p>KWD 2.50</p>
                                    <p>Discount 15%</p>
                                    <p>Delivery discount 70%</p>
                                  </div>
                                  <span>
                                    *Minimum Purchase Quantity: 2 Pack
                                  </span>
                                  <CustomButton bg="fill">
                                    ADD TO CART
                                  </CustomButton>
                                </div> */}
                              </TinySlider>
                            </div>
                          </Container>
                        </div>
                      </div>
                    </section>
                  );
                } else if (comp?.componentId == "pageStaticSectionComponent") {
                  return (
                    <div className="premium-section">
                      <Container>
                        <h4>{comp?.pageStaticSectionComponentData?.title}</h4>
                      </Container>
                    </div>
                  );
                } else if (
                  comp?.componentId == "puralFaqComponent" &&
                  comp?.isActive === "1"
                ) {
                  return (
                    <div className="faq-club-wrapper">
                      <Container key={index + "clubPage"}>
                        <div className="faq-wrap">
                          <h2>{comp?.puralFaqComponentData?.title}</h2>
                          <div className="accordion-wrap">
                            <Accordion defaultActiveKey="0">
                              {comp?.puralFaqComponentData?.faqlist?.map(
                                (items: any, index: any) => (
                                  <Accordion.Item
                                    eventKey={index}
                                    key={index + "faqs2"}
                                  >
                                    <Accordion.Header>
                                      {items.faqQuestion}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <p>
                                        {items.faqAnswer.replace(regex, "")}
                                      </p>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                )
                              )}
                            </Accordion>
                          </div>
                        </div>
                      </Container>
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <SomethingWrong></SomethingWrong>
          )}
        </>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    // return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
};

export default Club;
