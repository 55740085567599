const Breadcrumbs = (props: any) => {
  function isLast(index: any) {
    return index === props.value.length - 1;
  }
  return (
    <div className="cus-breadcrumb">
      {props.value.map((data: any, index: any) => {
        const disabled = isLast(index) ? (
          <span className="disabled" key={index + "breadcrumbs"}>
            {data.label}
          </span>
        ) : (
          <a href={data.path} className="item" key={index + "breadcrumbs"}>
            {data.label}
          </a>
        );
        return disabled;
      })}
    </div>
  );
};

export default Breadcrumbs;
