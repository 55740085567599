import React, { useCallback, useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
// import FB from "../../Assets/img/FB.svg";
import GoogleImg from "../../Assets/img/google.svg";
import InputGroups from "../Common/InputGroups/InputGroups";
import PasswordGroups from "../Common/PasswordGroups/PasswordGroups";
import CustomButton from "../Common/CustomButton/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { FORGOTPASSWORD, HOME, ORDERS, SIGNUP } from "../../Constant/Route";
import { LOGIN_FAILD, LOGIN_LONG, LOGIN_REQUEST, LOGIN_SUCCESS } from "../../Redux/Auth/AuthType";
import { API_STATUS } from "../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { isLoading } from "../../Utility/General";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../Redux/Auth/AuthAction";
import validate from "../../Utility/Validation";
import { QUOTE_ID } from "../../Constant/LocalStorage";
import GoogleLogin from "../SocialLogin/GoogleLogin/GoogleLogin";


const Login = (props:any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const INITIAL_LOGIN_DATA: any = {
    email: {
      value: '',
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
    password: {
      value: '',
      validation: ["required"],
      errors: ["Password is required."],
    },
  };
  const [loginData, setLoginData] = useState(INITIAL_LOGIN_DATA);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [loginDataError, setLoginDataError] = useState(demo);
  const quoteId = localStorage.getItem(QUOTE_ID)
    ? localStorage.getItem(QUOTE_ID)
    : "";
  const authRawData = useSelector((state: any) => state?.login?.data);
  
  const setData = (field: string, value: string) => {
    let loginDetails = Object.assign({}, loginData);
    loginDetails[field].value = value;
    setLoginData({ ...loginDetails });
  };

  useEffect(() => {
    setLoginDataError({ ...validate(loginData)?.errors })
  }, [loginData])

  const loginHandler = (e: any) => {
    e.preventDefault();
    if (validate(loginData).isValidated === true) {
      dispatch(
        loginAction({
          email: loginData.email.value,
          password: loginData.password.value,
          loginType: "0",
          quoteId: quoteId,
        })
      );
    } else {
      setLoginDataError({ ...validate(loginData)?.errors });
      setIsFormValidated(true);
    }
  }

  useSelector((state: any) => {
    if (state?.login?.type === LOGIN_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.login?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.login?.type === LOGIN_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        navigate(HOME);
      }
    } else if (state?.login?.type === LOGIN_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.login?.type === LOGIN_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  return (
    <div className="login form-section">
      <Container>
        <div className="max-464">
          <h1>Log In</h1>
          <div className="input-field-wrapper">
            <InputGroups
              onChange={(e: any) => setData("email", e.target.value)}
              type="text"
              label="Email"
              defaultValue=""
              error={
                isFormValidated && loginDataError["email"]
                  ? loginDataError["email"]
                  : null
              }
            />
            <PasswordGroups
              onChange={(e: any) => setData("password", e.target.value)}
              label="Password"
              defaultValue=""
              error={
                isFormValidated && loginDataError["password"]
                  ? loginDataError["password"]
                  : null
              }
            />
          </div>
          <div className="forgot-links">
            <Link to={FORGOTPASSWORD} className="pages-link">
              Forgot password?
            </Link>
          </div>
          <CustomButton bg="fill" 
          onClick={(e: any) => loginHandler(e)}
          isLoading={isLoading(apiStatus)}
          disabled={isLoading(apiStatus)}>
            LOG IN
          </CustomButton >
          <p className="or">or</p>
          <div className="social-login">
            <GoogleLogin btnMsg="signin_with"/>
            {/* <a className="social-btns">
              <img src={FB} alt="" />
            </a> */}
            {/*<a className="social-btns">
              <img src={GoogleImg} alt="" />
              
            </a>*/}
          </div>
          <p className="have-account">
            Don’t you have an account? <Link to={SIGNUP}> Sign up</Link>{" "}
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Login;
