import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import InputGroups from '../Common/InputGroups/InputGroups';
import CustomButton from '../Common/CustomButton/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import { FORGOTSUCCESS, HOME, LOGIN, RESETPASSWORD } from '../../Constant/Route';
import { useDispatch, useSelector } from 'react-redux';
import { API_STATUS } from '../../Constant/Api';
import forgotPasswordAction from '../../Redux/Auth/AuthAction';
import validate from '../../Utility/Validation';
import { FORGOT_PASSWORD_CLEAR, FORGOT_PASSWORD_FAILD, FORGOT_PASSWORD_LONG, FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_SUCCESS } from '../../Redux/Auth/AuthType';
import { SUCCESS_RESPONSE_CODE } from '../../Constant/Status';
import { isLoading } from '../../Utility/General';


const ForgotPassword = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const INITIAL_FORGOT_PASS_DATA: any = {
    email: {
      value: '',
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
  };
  const [forgotPassData, setForgotPassData] = useState(INITIAL_FORGOT_PASS_DATA);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [forgotPassError, setForgotPassError] = useState(demo);
  const setData = (field: string, value: string) => {
    let forgotDetails = Object.assign({}, forgotPassData);
    forgotDetails[field].value = value;
    setForgotPassData({ ...forgotDetails });
  };

  const submitHandler = (e:any) => {
    e.preventDefault();
    // navigate(ORDERS);
    // props.onHide();
    if (validate(forgotPassData).isValidated === true) {
      // console.log('loginData', loginData);
      dispatch(
        forgotPasswordAction({
          email: forgotPassData.email.value,
        })
      );
      // navigate(FORGOTSUCCESS);
    } else {
      setForgotPassError({ ...validate(forgotPassData)?.errors });
      setIsFormValidated(true);
    }
  };

  useSelector((state: any) => {
    if (state?.forgotPassword?.type === FORGOT_PASSWORD_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.forgotPassword?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.forgotPassword?.type === FORGOT_PASSWORD_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
      dispatch({
        type: FORGOT_PASSWORD_CLEAR,
        payload: { type: FORGOT_PASSWORD_CLEAR },
      });
      setForgotPassData({ ...INITIAL_FORGOT_PASS_DATA });
      navigate(FORGOTSUCCESS);
    } else if (state?.forgotPassword?.type === FORGOT_PASSWORD_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.forgotPassword?.type === FORGOT_PASSWORD_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });



  useEffect(() => {
    setForgotPassError({ ...validate(forgotPassData)?.errors });
    setIsFormValidated(false);
  }, [forgotPassData]);

  return (
    <Container>
      <div className="forgot-password-page form-section">
        <Container>
          <form className="max-464" onSubmit={(e: any) => submitHandler(e)}>
            <h1>Forgot your password?</h1>
            <p>
              No worries, we will send you reset instructions to your email.
            </p>
            <div>
              <InputGroups
                onChange={(e: any) => setData("email", e.target.value)}
                error={
                  isFormValidated && forgotPassError["email"]
                    ? forgotPassError["email"]
                    : null
                }
                type="email"
                value={forgotPassData.email.value}
                label="Enter e-mail"
              />
            </div>
            <CustomButton
              bg="fill"
              isLoading={isLoading(apiStatus)}
              disabled={isLoading(apiStatus)}
              onClick={(e: any) => submitHandler(e)}
            >
              RESET PASSWORD
            </CustomButton>
            <div className="go-back-wrapper">
              <Link to={LOGIN} className="go-back-btn">
                <span>Go Back</span>
              </Link>
            </div>
          </form>
        </Container>
      </div>
    </Container>
  );
}

export default ForgotPassword;
