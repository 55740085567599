export const GET_CHECKOUT_REQUEST = "GET_CHECKOUT_REQUEST";
export const GET_CHECKOUT_SUCCESS = "GET_CHECKOUT_SUCCESS";
export const GET_CHECKOUT_FAILD = "GET_CHECKOUT_FAILD";
export const GET_CHECKOUT_LONG = "GET_CHECKOUT_LONG";
export const GET_CHECKOUT_CLEAR = "GET_CHECKOUT_CLEAR";


export const UPDATE_CHECKOUT_REQUEST = "UPDATE_CHECKOUT_REQUEST";
export const UPDATE_CHECKOUT_SUCCESS = "UPDATE_CHECKOUT_SUCCESS";
export const UPDATE_CHECKOUT_FAILD = "UPDATE_CHECKOUT_FAILD";
export const UPDATE_CHECKOUT_CLEAR = "UPDATE_CHECKOUT_CLEAR";
export const UPDATE_CHECKOUT_LONG = "UPDATE_CHECKOUT_LONG";


export const PLACE_ORDER_REQUEST = "PLACE_ORDER_REQUEST";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILD = "PLACE_ORDER_FAILD";
export const PLACE_ORDER_CLEAR = "PLACE_ORDER_CLEAR";
export const PLACE_ORDER_LONG = "PLACE_ORDER_LONG";

export const PAYMENT_CONFIRM_REQUEST = "PAYMENT_CONFIRM_REQUEST";
export const PAYMENT_CONFIRM_SUCCESS = "PAYMENT_CONFIRM_SUCCESS";
export const PAYMENT_CONFIRM_FAILD = "PAYMENT_CONFIRM_FAILD";
export const PAYMENT_CONFIRM_CLEAR = "PAYMENT_CONFIRM_CLEAR";
export const PAYMENT_CONFIRM_LONG = "PAYMENT_CONFIRM_LONG";

export const QUOTE_UPDATE_ADDRESS_REQUEST = "UOTE_UPDATE_ADDRESS_REQUEST";
export const QUOTE_UPDATE_ADDRESS_SUCCESS = "UOTE_UPDATE_ADDRESS_SUCCESS";
export const QUOTE_UPDATE_ADDRESS_FAILD = "UOTE_UPDATE_ADDRESS_FAILD";
export const QUOTE_UPDATE_ADDRESS_CLEAR = "UOTE_UPDATE_ADDRESS_CLEAR";
export const QUOTE_UPDATE_ADDRESS_LONG = "UOTE_UPDATE_ADDRESS_LONG";