import { END_POINTS, MAX_CALLS } from "../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import api from "../../Service/Axios";
import { CMS_FAILD, CMS_LONG, CMS_REQUEST, CMS_SUCCESS } from "./CmsPagesType";

export function getCmsPage(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: CMS_REQUEST,
      payload: { type: CMS_REQUEST },
    });
    function recursiveCall() {
      api
        .get(`${END_POINTS.cms}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            dispatch({
              type: CMS_SUCCESS,
              payload: { data: result.data, type: CMS_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: CMS_LONG,
              payload: { data: {}, type: CMS_LONG },
            });
          } else {
            dispatch({
              type: CMS_FAILD,
              payload: { data: {}, type: CMS_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: CMS_LONG,
              payload: { data: {}, type: CMS_LONG },
            });
          } else {
            dispatch({
              type: CMS_FAILD,
              payload: { data: {}, type: CMS_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}
