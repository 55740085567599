import { Link } from "react-router-dom";
const AccountBreadcrumb = (props: any) => {
  function isLast(index: any) {
    return index === props.value.length - 1;
  }

  return (
    <div className="cus-breadcrumb">
      {props.value.map((items: any, index: number) => {
         const disabled = isLast(index) ? (
          <span className="disabled" key={index + "breadcrumb2"}>
          {items.label}
        </span>
        ) : (
          <Link to={items?.path} className="item" key={index + "breadcrumb2"}>
            {items.label}
          </Link>
        )
        return disabled
    })}
    </div>
  );
};

export default AccountBreadcrumb;
