import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import AccountBreadcrumb from "../Common/AccountBreadcrumb/AccountBreadcrumb";
import { Outlet, useLocation, useMatch, useParams, useOutlet} from "react-router-dom";
import MobAccountPages from "./MobAccountPages";
import AccountSideMenu from "./AccountSideMenu/AccountSideMenu";
import { HOME, PERSONALDATA } from "../../Constant/Route";


const AccountPages = (props: any) => {
  const [location, setLocation] =  useState("")
  const navigate: any = useLocation()
  const newpath = location.substring(9)

  useEffect(() => {
    setLocation(navigate.pathname);
  },[navigate])

  const accBreadcrumb = [
    {
      path: HOME,
      label: "Home",
    },
    {
      path: PERSONALDATA,
      label: "My Account",
    },
    {
      path: location,
      label: newpath
    }
  ];

  return (
    <div className="web-account-wrapper">
      <Container>
        <AccountBreadcrumb value={accBreadcrumb}></AccountBreadcrumb>
        <div className="web-account-inner">
          <AccountSideMenu />
          <div className="right-desc">
            <Outlet />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AccountPages;
