import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import InputGroups from "../Common/InputGroups/InputGroups";
import CustomButton from "../Common/CustomButton/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { HOME, RESETSUCCESS } from "../../Constant/Route";
import PasswordGroups from "../Common/PasswordGroups/PasswordGroups";
import { API_STATUS } from "../../Constant/Api";
import validate from "../../Utility/Validation";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordAction } from "../../Redux/Auth/AuthAction";
import {
  RESET_PASSWORD_FAILD,
  RESET_PASSWORD_LONG,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
} from "../../Redux/Auth/AuthType";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { isLoading } from "../../Utility/General";

const ResetPassword = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const auth = useSelector((state: any) => state?.login?.data);

  useEffect(() => {
    if(auth) {
      navigate(HOME)
    }
  },[auth])

  const INITIAL_PASSWORD_DATA: any = {
    token: {
      value: params.token,
    },
    password: {
      value: "",
      validation: ["required"],
      errors: ["Password is required."],
    },
    confirmPassword: {
      value: "",
      validation: ["sameAs:password"],
      errors: ["Confirm Password should be same as Password."],
    },
  };

  const [isFormValidated, setIsFormValidated] = useState(false);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [resetData, setResetData] = useState(INITIAL_PASSWORD_DATA);
  let demo: any = {};
  const [passwordDataError, setPasswordDataError] = useState(demo);
  const setData = (field: string, value: string) => {
    let resetDetails = Object.assign({}, resetData);
    resetDetails[field].value = value;
    setResetData({ ...resetDetails });
  };
  const submitHandler = () => {
    if (validate(resetData).isValidated === true) {
      // console.log("resetData", resetData);
      dispatch(
        resetPasswordAction({
          new_password: resetData.password.value,
          confirm_password: resetData.confirmPassword.value,
          token: resetData.token.value,
        })
      );
      // navigate(MYORDERS);
    } else {
      setPasswordDataError({ ...validate(resetData)?.errors });
      setIsFormValidated(true);
    }
  };
  useEffect(() => {
    setPasswordDataError({ ...validate(resetData)?.errors });
    setIsFormValidated(false);
  }, [resetData]);

  useSelector((state: any) => {
    if (state?.resetPassword?.type === RESET_PASSWORD_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.resetPassword?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.resetPassword?.type === RESET_PASSWORD_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
      // props.onHide();
      navigate(RESETSUCCESS);
    } else if (state?.resetPassword?.type === RESET_PASSWORD_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.resetPassword?.type === RESET_PASSWORD_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      } 
    }
  });
  // console.log(params);
  return (
    <div className="reset-password form-section">
      <Container>
        <div className="max-464">
          <h1>Reset Password</h1>
          <div>
            <PasswordGroups
              onChange={(e: any) => setData("password", e.target.value)}
              label={"Password"}
              error={
                isFormValidated && passwordDataError["password"]
                  ? passwordDataError["password"]
                  : null
              }
            />
            <PasswordGroups
              onChange={(e: any) => setData("confirmPassword", e.target.value)}
              label={"Confirm Password"}
              error={
                isFormValidated && passwordDataError["confirmPassword"]
                  ? passwordDataError["confirmPassword"]
                  : null
              }
            />
          </div>
          <CustomButton
            bg="fill"
            isLoading={isLoading(apiStatus)}
            disabled={isLoading(apiStatus)}
            onClick={() => submitHandler()}
          >
            RESET
          </CustomButton>
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
