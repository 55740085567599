import { Container } from "react-bootstrap";
import CheckoutAddress from "./CheckoutAddress/CheckoutAddress";
import CheckoutHeader from "../CheckoutHeader/CheckoutHeader";
import CheckoutLogin from "./CheckoutLogin/CheckoutLogin";
import CheckoutPayment from "./CheckoutPayment/CheckoutPayment";
import YourBag from "./YourBag/YourBag";
import ckpoint2 from "../../Assets/img/ck_delivery.png";
import ckpoint3 from "../../Assets/img/ck_premiumquality.png";
import ckpoint1 from "../../Assets/img/ck_securepayment.png";
import ProgressSteps from "./ProgressSteps/ProgressSteps";
import CkLogin from "./CheckoutLogin/CkLogin/CkLogin";
import CkSignup from "./CheckoutLogin/CkSignup/CkSignup";
import YourBagMobile from "./YourBagMobile/YourBagMobile";
import OrderConfirmation from "../OrderConfirmation/OrderConfirmation";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCheckoutAction,
  placeOrderAction,
  updateCheckoutAction,
} from "../../Redux/Checkout/CheckoutAction";
import { addressUpdateAction } from "../../Redux/Account/AccountAction";
import { QUOTE_ID } from "../../Constant/LocalStorage";
import { CHECKOUTPAGE } from "../../Constant/General";
import {
  ADD_REMOVE_CART_CLEAR,
  ADD_REMOVE_CART_FAILD,
  ADD_REMOVE_CART_REQUEST,
  ADD_REMOVE_CART_SUCCESS,
} from "../../Redux/Cart/CartType";
import {
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILD,
  PLACE_ORDER_LONG,
  PLACE_ORDER_REQUEST
} from "../../Redux/Checkout/CheckoutType"
import { API_STATUS } from "../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import CheckoutShipping from "./CheckoutShipping/CheckoutShipping";
import validate from "../../Utility/Validation";
import CkGuest from "./CheckoutLogin/CkGuest/CkGuest";
import { useNavigate } from "react-router-dom"
import { ORDERSUCCESS, HOME } from "../../Constant/Route";
import { showToast } from "../../Utility/General";

const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [activeTabCount, setActiveTabCount] = useState(1);
  const [disableButton, setDisableButton] = useState(false);
  const [isSignUp, setIsSignUp] = useState<any>(CHECKOUTPAGE.auth_signup);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const quoteId = localStorage.getItem(QUOTE_ID)
    ? localStorage.getItem(QUOTE_ID)
    : "";
  const checkoutRawData = useSelector(
    (state: any) => state?.getCheckoutReducer?.data
  );
  const updatedCheckoutRawData = useSelector(
    (state: any) => state?.updateCheckoutReducer?.data
  );

  const placeOrderRawData = useSelector(
    (state: any) => state?.placeOrderReducer?.data
  );
  const updateAddressData = useSelector(
    (state: any) => state?.addressUpdateData?.data
  )
  const cartDataStatus = useSelector((state: any) => state?.myCartGlobalData?.data?.statusCode);
  const cartCount = useSelector(
    (state: any) => state?.cartCountGlobalData?.data
  );
  let updateAddresstStatusCode = updateAddressData?.statusCode

  const [addRemoveCartApiStatus, setAddRemoveCartApiStatus] = useState<any>({});
  const [checkoutData, setCheckoutData] = useState<any>({});

  const [isShipping, setIsShipping] = useState(false);
  const [enableShipping, setEnableShipping] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const auth = useSelector((state: any) => state?.login?.isLoggedIn);
  const newQuoteId = auth ? "empty" : quoteId 
  const stepData = [
    {
      title: "Summary",
      isDone: true,
    },
    {
      title: "Data",
      isDone: false,
    },
    {
      title: "Thank you",
      isDone: false,
    },
  ];
  const PLACE_ORDER_DEFAULT_DATA = {
    email: {
      value: "",
      validation: ["required","email"],
      errors: ["Email is required.","Please Enter Valid Email"],
    },
    address: {
      value: "",
      validation: ["required"],
      errors: ["Address is required."],
    },
    shipping: {
      value: "",
      validation: ["required"],
      errors: ["Shipping is required."],
    },
    quoteId: {
      value: newQuoteId,
      validation: ["required"],
      errors: ["Email is required."],
    },
    paymentMethod: {
      value: "",
      validation: ["required"],
      errors: ["Please Select Payment Method."],
    },
    paymentType: {
      value: "",
      validation: ["required"],
      errors: ["Please Select Payment Type"],
    },
  };

  const [placeOrderData, setPlaceOrderData] = useState<any>(
    PLACE_ORDER_DEFAULT_DATA
  );
  // console.log("placeOrderData",placeOrderData);

  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [placeOrderDataError, setPlaceOrderDataError] = useState(demo);

  const setData = (field: string, value: string) => {
    let orderDataDetail = Object.assign({}, placeOrderData);
    if (orderDataDetail[field]) {
      orderDataDetail[field].value = value;
    }
    setPlaceOrderData({ ...orderDataDetail });
  };

  const checkoutDefaultData = {
    quoteId: newQuoteId,
    shipingId: "",
    isBilingSelect: "",
    address: {
      //alias: "",
      //firstname: "",
      //lastname: "",
      //phonenumber: "",
      //addressType: "",
      //countryCode: "",
      //block: "",
      //avenue: "",
      //street: "",
      //building: "",
      //floor: "",
      //apartment_number: "",
    },
    bilingaddress: {
      //alias: "",
      //firstname: "",
      //lastname: "",
      //phonenumber: "",
      //addressType: "",
      //countryCode: "",
      //block: "",
      //avenue: "",
      //street: "",
      //building: "",
      //floor: "",
      //apartment_number: "",
    },
  };
  const [updateCheckoutData, setUpdateCheckoutData] =
    useState(checkoutDefaultData);

  useEffect(() => {
    if(cartCount <= 0 && cartDataStatus !== "200") {
      navigate(HOME)
      showToast("success","Your cart is empty!")
    }
  },[cartDataStatus,cartCount])
  
  useEffect(() => {
    setData("email",JSON.parse(localStorage.getItem("user") || '{}').email)
  },[auth])

  useEffect(() => {}, [activeTabCount]);
  useEffect(() => {
    dispatch(getCheckoutAction({ quoteId: newQuoteId, same_as_billing_address: 0  }));
    if (auth) {
      setActiveTab(CHECKOUTPAGE.address);
    } else {
      setActiveTab(CHECKOUTPAGE.auth);
    }
  }, [updateAddresstStatusCode]);

  useEffect(() => {
    if(auth){
      dispatch(getCheckoutAction({ quoteId: newQuoteId, same_as_billing_address: 0 }));
    } 
  },[auth])
  

  useEffect(() => {
    let componentData: any = {};
    checkoutRawData?.component?.forEach((component: any) => {
      componentData[component?.componentId] = component;
      if (component?.componentId == "shippingMethod") {
        setData("address", "address");
        if (component?.selectedShipping != "") {
          setData("shipping", "shipping");
        }
      }
      if (component?.componentId == "purchasedProduct") {
        if (
          component?.purchasedProductData?.list?.length ==
            1 &&
          component?.purchasedProductData?.list[0]
            ?.productType == "virtual"
        ) {
          setEnableShipping(false);
        } else {
          setEnableShipping(false);
        }
      }
    });
    setCheckoutData({ ...componentData });
    // checkoutData?.purchasedProduct?.purchasedProductData?.length == 1;
  }, [checkoutRawData,auth]);

  useEffect(() => {
    if (updatedCheckoutRawData) {
      let componentData: any = {};
      updatedCheckoutRawData?.component?.forEach((component: any) => {
        componentData[component?.componentId] = component;
        if(component?.componentId=="shippingMethod"){
          setData("address", "address");
          if(component?.selectedShipping!=""){
            setData("shipping", "shipping");
          }
        }
        if (component?.componentId == "purchasedProduct") {
          if (
            component?.purchasedProductData?.list?.length == 1 &&
            component?.purchasedProductData?.list[0]?.productType == "virtual"
          ) {
            setEnableShipping(false);
          } else {
            setEnableShipping(false);
          }
        }
      });
      setCheckoutData({ ...checkoutData, ...componentData });
    }
  }, [updatedCheckoutRawData]);

  const setTab = (tabname: string) => {
    let activeTabNew = "";
    // console.log("chkout", tabname);
    if (
      (activeTab === CHECKOUTPAGE.address ||
        activeTabNew === CHECKOUTPAGE.auth) &&
      tabname === CHECKOUTPAGE.payment
    ) {
      activeTabNew = activeTab + "_" + tabname;
    } else {
      activeTabNew = tabname;
    }
    if (
      activeTabNew &&
      ((auth && activeTabNew !== CHECKOUTPAGE.auth) || !auth)
    ) {
      setActiveTab(activeTabNew);
    }
  };
  const setSubtab = (tabname: string) => {
    if (tabname === CHECKOUTPAGE.shipping) {
      setIsShipping(true);
    } else if (tabname === CHECKOUTPAGE.payment) {
      setIsPayment(true);
    }
  };

  const setAddressAndUpdateCheckout = (data: any) => {   
    // console.log("data --->",data);
    if (auth) {
      dispatch(
        addressUpdateAction({
          addressData: {
            alias: data.address.alias.value,
            firstname: data.address.firstName.value,
            lastname: data.address.lastName.value,
            phonenumber: data.address.phoneNumber.value,
            addressType: data.address.addressType.value, 
            countryCode: data.address.countryCode.value,
            block: data.address.block.value,
            avenue: data.address.avenue.value,
            street: data.address.street.value,
            building: data.address.building.value,
            customer_area: data.address.customer_area.value,
            floor: data.address.floor.value,
            apartment_number: data.address.apartment_number.value,
            isDefaultShipping: "1",
            isDefaultBilling: "1",
            same_as_billing: data.isBilingSelect.value,
            billingAddress: (data.isBilingSelect.value === "0") ? (
              {
              alias: data.bilingaddress.b_alias.value,
              firstname: data.bilingaddress.b_firstName.value,
              lastname: data.bilingaddress.b_lastName.value,
              phonenumber: data.bilingaddress.b_phoneNumber.value,
              addressType: data.bilingaddress.b_addressType.value, 
              countryCode: data.bilingaddress.b_countryCode.value,
              block: data.bilingaddress.b_block.value,
              avenue: data.bilingaddress.b_avenue.value,
              street: data.bilingaddress.b_street.value,
              building: data.bilingaddress.b_building.value,
              customer_area: data.bilingaddress.b_customer_area.value,
              floor: data.bilingaddress.b_floor.value,
              apartment_number: data.bilingaddress.b_apartment_number.value,             
             }) :("")
          },
          billingAddressID: data.isBilingSelect.value === "0" ? data.bilingaddress.b_addressId.value : data.address.addressId.value,
          isUpdate: data.address.isUpdate.value ? data.address.isUpdate.value : ("0"),
          addressId: data.address.addressId.value ? data.address.addressId.value : ("0"), 
        })
      )
    } else {
      let updateData: any = Object.assign({}, updateCheckoutData);
      updateData["quoteId"] = newQuoteId;
      updateData["shipingId"] = "";
      updateData["isBilingSelect"] = data["isBilingSelect"].value;
      updateData["updatePaymentMethod"] = "0";
      for (let key in data["address"]) {
        updateData["address"][key] = data["address"][key].value
      }
      if(data["isBilingSelect"].value === "0"){
        const newData = Object.keys(data["bilingaddress"]).reduce((a: any , b: any) => (a[b.substring(2)] = data["bilingaddress"][b], a), {})
        for(let key1 in newData) {
          updateData["bilingaddress"][key1] = newData[key1].value
        }
      }else {
          updateData["bilingaddress"] = {}
      }
      setUpdateCheckoutData({ ...updateData });
      dispatch(updateCheckoutAction(updateData));
    } 
  };

  const updateShippingMethod = (data: any) => {
    let updateData: any = Object.assign({}, updateCheckoutData);
    updateData["shipingId"] = data;
    dispatch(updateCheckoutAction(updateData));
  };

  const setPaymentType = (id: any, type: any) => {
    setData("paymentMethod", id);
    setData("paymentType", type);
    setData("shipping", "shipping")
    let updateData: any = Object.assign({}, updateCheckoutData);
    updateData["shipingId"] = "freeshipping_freeshipping";
    updateData["updatePaymentMethod"] = "1";
    dispatch(updateCheckoutAction(updateData));
  };

  const placeOrderHandler = (e: any) => {
    e.preventDefault();
        if (validate(placeOrderData).isValidated === true) {
          dispatch(
            placeOrderAction({
              quoteId: placeOrderData.quoteId.value,
              email: placeOrderData.email.value,
              paymentType: placeOrderData.paymentType.value,
              paymentMethod: placeOrderData.paymentMethod.value,
            })
         );
        } else {
          setPlaceOrderDataError({ ...validate(placeOrderData)?.errors });
          setIsFormValidated(true);
        }
  };

  useEffect(() => {
    setPlaceOrderDataError({ ...validate(placeOrderData)?.errors });
  }, [placeOrderData]);

  //useEffect(() => {
  //  if (placeOrderRawData?.paymentUrl) {
  //    window.location.href = placeOrderRawData?.paymentUrl;
  //    // window.open(placeOrderRawData?.paymentUrl, "_blank");
  //  }else{
  //    if(placeOrderRawData?.orderIdString){
  //      navigate(ORDERSUCCESS+`/${placeOrderRawData?.orderIdString}`);
  //    }
  //  }
  //}, [placeOrderRawData]);
  
  useSelector((state: any) => {
    if (state?.placeOrderReducer?.type === PLACE_ORDER_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.placeOrderReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.placeOrderReducer?.type === PLACE_ORDER_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
         setApiStatus(API_STATUS.SUCCESS);
         if (state?.placeOrderReducer?.data?.paymentUrl) {
              window.location.href = state?.placeOrderReducer?.data?.paymentUrl;
          }else{
              if(state?.placeOrderReducer?.data?.orderIdString){
                navigate(ORDERSUCCESS+`/${state?.placeOrderReducer?.data?.orderIdString}`);
              }
            }
        }
    } else if (state?.placeOrderReducer?.type === PLACE_ORDER_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.placeOrderReducer?.type === PLACE_ORDER_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  useSelector((state: any) => {
    if (state?.addToCartReducer?.type === ADD_REMOVE_CART_REQUEST) {
      if (addRemoveCartApiStatus !== API_STATUS.LOADING) {
        setAddRemoveCartApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.addToCartReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.addToCartReducer?.type === ADD_REMOVE_CART_SUCCESS
    ) {
      if (addRemoveCartApiStatus !== API_STATUS.SUCCESS) {
        setAddRemoveCartApiStatus(API_STATUS.SUCCESS);
        setDisableButton(false);
        dispatch({ type: ADD_REMOVE_CART_CLEAR });
        dispatch(getCheckoutAction({ quoteId: newQuoteId }));
      }
    } else if (state?.addToCartReducer?.type === ADD_REMOVE_CART_FAILD) {
      if (addRemoveCartApiStatus !== API_STATUS.FAILED) {
        setAddRemoveCartApiStatus(API_STATUS.FAILED);
      }
    }
  });

  const getActiveTab = (tabname: string) => {
    switch (tabname) {
      case CHECKOUTPAGE.auth:
        return (
          <CheckoutLogin
            onTabChange={(tabname: string, subtab: string) => (
              setTab(tabname), setSubtab(subtab)
            )}
            isFormValidated={isFormValidated}
            placeOrderData={placeOrderData}
            placeOrderDataError={placeOrderDataError}
            setData={(field: string, value: string) => setData(field, value)}
          ></CheckoutLogin>
        );
      case CHECKOUTPAGE.address:
        return (
          <>
            <CheckoutAddress
              activeTab={activeTab}
              addressData={checkoutData?.checkoutAddress}
              onTabChange={(tabname: string) => setTab(tabname)}
              onAddressChange={(address: any) =>
                setAddressAndUpdateCheckout(address)
              }
              setPaymentType={(id: any, type: any) =>
                setPaymentType(id, type)
              }
            ></CheckoutAddress>

            {enableShipping? (isShipping ? (
              <CheckoutShipping
                onTabChange={(tabname: string) => setTab(tabname)}
                onHeaderClick={(val: any) => setIsShipping(val)}
                shippingMethod={checkoutData?.shippingMethod}
                shippingMethodSelect={(val: any) => updateShippingMethod(val)}
                // onTabChange={(tabname: string) => setTab(tabname)}
              ></CheckoutShipping>
            ) : (
              <a
                className="next-step show-768 payment"
                // onClick={() => setTab(CHECKOUTPAGE.payment)}
                onClick={() => setIsShipping(true)}
              >
                Shipping
              </a>
            )):""}
            {isPayment ? (
              <CheckoutPayment
                onTabChange={(tabname: string) => setTab(tabname)}
                onHeaderClick={(val: any) => setIsPayment(val)}
                payment={checkoutData?.payment}
                setPaymentType={(id: any, type: any) =>
                  setPaymentType(id, type)
                }
                // onTabChange={(tabname: string) => setTab(tabname)}
              ></CheckoutPayment>
            ) : (
              <a
                className="next-step show-768 payment"
                onClick={() => setIsPayment(true)}
              >
                Payment
              </a>
            )}
          </>
        );
      // case CHECKOUTPAGE.payment:
      //   return (
      //     <>
      //       <CheckoutAddress
      //         activeTab={activeTab}
      //         onTabChange={(tabname: string) => setTab(tabname)}
      //       ></CheckoutAddress>
      //       <a className="next-step show-768 payment" onClick={() => setTab(CHECKOUTPAGE.payment)}>Shipping</a>
      //       <CheckoutShipping />
      //       <CheckoutPayment
      //         onTabChange={(tabname: string) => setTab(tabname)}
      //       ></CheckoutPayment>
      //     </>
      //   );
      // case CHECKOUTPAGE.address_payment:
      //   return (
      //     <>
      //       <CheckoutAddress
      //         activeTab={activeTab}
      //         onTabChange={(tabname: string) => setTab(tabname)}
      //       ></CheckoutAddress>
      //       <CheckoutPayment
      //         onTabChange={(tabname: string) => setTab(tabname)}
      //       ></CheckoutPayment>
      //     </>
      //   );
      // case CHECKOUTPAGE.auth_payment:
      //   return (
      //     <>
      //       <CheckoutAddress
      //         activeTab={activeTab}
      //         onTabChange={(tabname: string) => setTab(tabname)}
      //       ></CheckoutAddress>
      //       <CheckoutPayment
      //         onTabChange={(tabname: string) => setTab(tabname)}
      //       ></CheckoutPayment>
      //     </>
      //   );
      default:
        break;
    }
  };

  const getResponsiveCheckout = (tabnumber: number) => {
    if (tabnumber === 1) {
      return (
        <YourBag
          productDetail={checkoutData?.purchasedProduct}
          priceDetail={checkoutData?.priceDetails}
          disableButton={disableButton}
          onQtyChange={(val: any) => setDisableButton(val)}
        ></YourBag>
      );
    }else if (tabnumber === 2) {
      if(isSignUp == CHECKOUTPAGE.auth_signup && !auth){
        return (
          <CkSignup
            // isSignUp={() => setIsSignUp(false)}
            onButtonChg={(val: any) => setIsSignUp(val)}
            isFormValidated={isFormValidated}
            activeTabCount={activeTabCount}
            placeOrderData={placeOrderData}
            placeOrderDataError={placeOrderDataError}
            setData={(field: string, value: string) => setData(field, value)}
            onNextClick={(count: number) => setActiveTabCount(count)}
          ></CkSignup>
        );
      }else if (isSignUp == CHECKOUTPAGE.auth_login && !auth) {
        return (
          <CkLogin
            // isSignUp={() => setIsSignUp(true)}
            onButtonChg={(val: any) => setIsSignUp(val)}
            activeTabCount={activeTabCount}
            onNextClick={(count: number) => setActiveTabCount(count)}
          ></CkLogin>
        );
      }else if (isSignUp == CHECKOUTPAGE.auth_guest && !auth) {
        return (
          <CkGuest
            onButtonChg={(val: any) => setIsSignUp(val)}
            isFormValidated={isFormValidated}
            placeOrderData={placeOrderData}
            placeOrderDataError={placeOrderDataError}
            setData={(field: string, value: string) =>
              setData(field, value)
            }
          ></CkGuest>
        );
      } else {
        if(auth){
          setActiveTabCount(activeTabCount + 1)
        }
      }
    } else if (tabnumber === 3) {
      return (
        <CheckoutAddress
          onAddressChange={(address: any) =>
            setAddressAndUpdateCheckout(address)
          }
          addressData={checkoutData?.checkoutAddress}
          activeTab={activeTab}
        ></CheckoutAddress>
      );
    } else if (tabnumber === 4 && enableShipping) {
      return (
        <CheckoutShipping
          shippingMethod={checkoutData?.shippingMethod}
          shippingMethodSelect={(val: any) => updateShippingMethod(val)}
          activeTab={activeTab}
        ></CheckoutShipping>
      );
    } else if (tabnumber === 4 && enableShipping !== true || tabnumber === 5) {
      return (
        <CheckoutPayment
          payment={checkoutData?.payment}
          setPaymentType={(id: any, type: any) => setPaymentType(id, type)}
          activeTab={activeTab}
        ></CheckoutPayment>
      );
    } else if (tabnumber === 6) {
      return <OrderConfirmation></OrderConfirmation>;
    }
  };


  return (
    <>
      <div className="checkout-wrap desktop show-768">
        {/* <CheckoutHeader></CheckoutHeader> */}
        <Container>
          <div className="ck-main">
            <div className="ck-process">
              {getActiveTab(activeTab)}
              {/* <CheckoutLogin></CheckoutLogin>
              <CheckoutAddress></CheckoutAddress>
              <CheckoutPayment></CheckoutPayment> */}
            </div>
            <div className="ck-summary">
              <YourBag
                placeOrderData={placeOrderData}
                placeOrderDataError={placeOrderDataError}
                isFormValidated={isFormValidated}
                productDetail={checkoutData?.purchasedProduct}
                priceDetail={checkoutData?.priceDetails}
                disableButton={disableButton}
                onSubmit={(e: any) => placeOrderHandler(e)}
                onQtyChange={(val: any) => setDisableButton(val)}
              ></YourBag>
            </div>
          </div>
        </Container>

        <div className="checkout-footer">
          <Container>
            <div className="ckfooter-points">
              <div className="point-wrap">
                <img src={ckpoint1} alt="Point 1"></img>
                <p className="pt-title">Secure payment</p>
                <p className="pt-desc">Visa, Mastercard and KNET</p>
              </div>
              <div className="point-wrap">
                <img src={ckpoint2} alt="Point 2"></img>
                <p className="pt-title">50% discount on shipping</p>
                <p className="pt-desc">For Pural Club members</p>
              </div>
              <div className="point-wrap">
                <img src={ckpoint3} alt="Point 3"></img>
                <p className="pt-title">premium quality</p>
                <p className="pt-desc">100% organic cottons</p>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <div className="checkout-wrap mobile hide-767">
        <Container>
        <ProgressSteps value={stepData} activeTabCount={activeTabCount}></ProgressSteps>
          <div className="checkout-wrapper">
            {getResponsiveCheckout(activeTabCount)}
            {/* <YourBag></YourBag> */}
            {/* <CkSignup></CkSignup> */}
            {/* <CkLogin></CkLogin> */}
            {/* <CheckoutAddress></CheckoutAddress> */}
            {/* <CheckoutPayment></CheckoutPayment> */}
            {/* <OrderConfirmation></OrderConfirmation> */}
          </div>
          <YourBagMobile
            onNextClick={(count: number) => setActiveTabCount(count)}
            activeTabCount={activeTabCount}
            enableShipping={enableShipping}
            productDetail={checkoutData?.purchasedProduct}
            priceDetail={checkoutData?.priceDetails}
            placeOrderData={placeOrderData}
            placeOrderDataError={placeOrderDataError}
            isFormValidated={isFormValidated}
            onPlaceOrder={(e: any) => placeOrderHandler(e)}
          />
        </Container>
      </div>
    </>
  );
};

export default Checkout;
