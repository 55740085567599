import React, { useEffect, useState } from "react";
import { Container, Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isTemplateSpan } from "typescript";
import { API_STATUS } from "../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { getFaq } from "../../Redux/FAQs/FAQsAction";
import {
  FAQ_FAILD,
  FAQ_LONG,
  FAQ_REQUEST,
  FAQ_SUCCESS,
} from "../../Redux/FAQs/FAQsType";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import Loader from "../Loader/Loader";
import SomethingWrong from "../SomethingWrong/SomethingWrong";

const Faqs = () => {
  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);

  useEffect(() => {
    dispatch(getFaq({categoryId:1}));
  }, []);

  useSelector((state: any) => {
    if (state?.getFaq?.type === FAQ_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.getFaq?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.getFaq?.type === FAQ_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.getFaq?.type === FAQ_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.getFaq?.type === FAQ_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  const faqs = useSelector((state: any) => state?.getFaq?.data);

  // console.log(faqs)

  const accBreadcrumb = [
    {
      path: "/",
      label: "Home",
    },
    {
      label: "FAQs",
    },
  ];

  const regex = /(<([^>]+)>)/ig;
  
  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <div className="faqs">
         {
          (faqs !== undefined) ? ( 
          <Container>
            <Breadcrumbs value={accBreadcrumb}></Breadcrumbs>
            <h1>{faqs?.title}</h1>
            <Accordion>
              {faqs?.faqList[0]?.componentFaqListData.map((items: any, index: any) => 
               items.isActive === "1" ? ( 
                <Accordion.Item eventKey={index} key={index + "faqs"}>
                  <Accordion.Header>
                    <h6>{items.faqQuestion}</h6>
                  </Accordion.Header>
                  <Accordion.Body>
                    <p>{items.faqAnswer.replace(regex,'')}</p>
                  </Accordion.Body>
                </Accordion.Item>
             ) : ("")
              )}
            </Accordion>
          </Container>
          ) : (
            <SomethingWrong></SomethingWrong>
          )
         }
        </div>
      );
    case API_STATUS.LONG:
    return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
};

export default Faqs;
