import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { CHECKOUTPAGE } from "../../../Constant/General";
import CkGuest from "./CkGuest/CkGuest";
import CkLogin from "./CkLogin/CkLogin";
import CkSignup from "./CkSignup/CkSignup";

const CheckoutLogin = (props: any) => {

  const [key, setKey] = useState(CHECKOUTPAGE.auth_signup);

  const onButtonChg = (val: any) => {
    setKey(val);
  }

  const onButtonChg1 = (val: any) => {
    setKey("login");
  }

  return (
    <>
      <div className="step-title">
        {/* <div className="step-title" onClick={() => props.onTabChange('log in')}> */}
        Log in
      </div>
      <div className="login-steps">
        <Tabs id="login-tabs" activeKey={key} onSelect={(k: any) => setKey(k)}>
          <Tab eventKey={CHECKOUTPAGE.auth_signup} title="Sign Up">
            <CkSignup
              onButtonChg={(val: any) => onButtonChg(val)}
              onTabChange={(val: any) => props.onTabChange(val)}
            ></CkSignup>
          </Tab>
          <Tab eventKey={CHECKOUTPAGE.auth_login} title="Log in">
            <CkLogin
              onButtonChg={(val: any) => onButtonChg(val)}
              onTabChange={(val: any) => props.onTabChange(val)}
            ></CkLogin>
          </Tab>
          <Tab eventKey={CHECKOUTPAGE.auth_guest} title="Buy as a guest">
            <CkGuest
              onButtonChg={(val: any) => onButtonChg(val)}
              isFormValidated={props.isFormValidated}
              placeOrderData={props.placeOrderData}
              placeOrderDataError={props.placeOrderDataError}
              setData={(field: string, value: string) =>
                props.setData(field, value)
              }
              onTabChange={(val: any) => props.onTabChange(val)}
            ></CkGuest>
          </Tab>
        </Tabs>
      </div>
      <a
        className="next-step show-768 addr"
        onClick={() => props.onTabChange(CHECKOUTPAGE.address, "address")}
      >
        Address
      </a>
      {/*<a
        className="next-step show-768 payment"
        onClick={() => props.onTabChange(CHECKOUTPAGE.address, "shipping")}
      >
        Shipping
      </a>*/}
      <a
        className="next-step show-768 payment"
        onClick={() => props.onTabChange(CHECKOUTPAGE.address, "payment")}
      >
        Payment
      </a>
    </>
  );
}

export default CheckoutLogin;