import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import {  HOME, PERSONALDATA, ACCOUNT } from "../../Constant/Route";
import { Outlet, useLocation } from "react-router-dom";


const MobAccountPages = (props: any) => {
  const [location, setLocation] =  useState("")
  const navigate: any = useLocation()
  const newpath = location.substring(9)

  useEffect(() => {
    setLocation(navigate.pathname);
  },[navigate])

  const accBreadcrumb = [
    {
      path: HOME,
      label: "Home",
    },
    {
      path: ACCOUNT,
      label: "My Account",
    },
    {
      path: location,
      label: newpath
    }
  ];

  return (
    <div className="mobile-account-wrapper">
      <Container>
        <Breadcrumbs value={accBreadcrumb}></Breadcrumbs>
        <Outlet />
        {props.children}
      </Container>
    </div>
  );
};

export default MobAccountPages;
