import { Link, useNavigate } from "react-router-dom";
import Logo from "../../Assets/img/logo.svg";
import Logoblack from "../../Assets/img/logo_black.svg";
import { HOME, ORDERS } from "../../Constant/Route";
import { ToastContainer } from "react-toastify";

const CheckoutHeader = () => {

  return (
    <>
      <header className="checkout header">
        <div className="ckheader-container">
          <Link to={HOME} className="logo show-768">
            <img src={Logo} alt="" />
          </Link>
          <Link to={HOME} className="logo mobile hide-767">
            <img src={Logoblack} alt="" />
          </Link>
          <p className="f1632 show-768" >Process order</p>
        </div>
        <ToastContainer />
      </header>
    </>
  )
}

export default CheckoutHeader;