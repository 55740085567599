import CustomButton from "../../../Common/CustomButton/CustomButton";
import InputGroups from "../../../Common/InputGroups/InputGroups";
import PasswordGroups from "../../../Common/PasswordGroups/PasswordGroups";
import googleSocialLogin from "../../../../Assets/img/google.svg";
import fbSocialLogin from "../../../../Assets/img/FB.svg";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FORGOTPASSWORD } from "../../../../Constant/Route";
import { CHECKOUTPAGE } from "../../../../Constant/General";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../../Constant/Api";
import validate from "../../../../Utility/Validation";
import { loginAction } from "../../../../Redux/Auth/AuthAction";
import { LOGIN_FAILD, LOGIN_LONG, LOGIN_REQUEST, LOGIN_SUCCESS } from "../../../../Redux/Auth/AuthType";
import { SUCCESS_RESPONSE_CODE } from "../../../../Constant/Status";
import { isLoading } from "../../../../Utility/General";
import GoogleLogin from "../../../SocialLogin/GoogleLogin/GoogleLogin";
//import FacebookLogin from "../../../SocialLogin/FacebookLogin/FacebookLogin";
import { QUOTE_ID } from "../../../../Constant/LocalStorage";

const CkLogin = (props: any) => {
  
  const [signUpCalled, issignUpCalled] = useState(false);
  const quoteId = localStorage.getItem(QUOTE_ID)
    ? localStorage.getItem(QUOTE_ID)
    : "";

  function multipleCall() {

    issignUpCalled(true);
    props.onButtonChg(signUpCalled);
    props.isSignUp();
  }

  const dispatch = useDispatch();

  const INITIAL_LOGIN_DATA: any = {
    email: {
      value: '',
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
    password: {
      value: '',
      validation: ["required"],
      errors: ["Password is required."],
    },
  };
  const [loginData, setLoginData] = useState(INITIAL_LOGIN_DATA);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [loginDataError, setLoginDataError] = useState(demo);

  const setData = (field: string, value: string) => {
    let loginDetails = Object.assign({}, loginData);
    loginDetails[field].value = value;
    setLoginData({ ...loginDetails });
  };

  useEffect(() => {
    setLoginDataError({ ...validate(loginData)?.errors })
  }, [loginData])

  const loginHandler = (e: any) => {
    e.preventDefault();
    if (validate(loginData).isValidated === true) {
      dispatch(
        loginAction({
          email: loginData.email.value,
          password: loginData.password.value,
          loginType: "0",
          quoteId: quoteId,
        })
      );
    } else {
      setLoginDataError({ ...validate(loginData)?.errors });
      setIsFormValidated(true);
    }
  }

  useSelector((state: any) => {
    if (state?.login?.type === LOGIN_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.login?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.login?.type === LOGIN_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        props.onTabChange(CHECKOUTPAGE.address);
      }
    } else if (state?.login?.type === LOGIN_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.login?.type === LOGIN_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  return (
    <>
      <form className="login-form">
        <div className="form-fieldset">
          <InputGroups onChange={(e: any) => setData("email", e.target.value)}
            type="text"
            label="Email"
            defaultValue=""
            error={
              isFormValidated && loginDataError["email"]
                ? loginDataError["email"]
                : null
            }
          />
          <PasswordGroups onChange={(e: any) => setData("password", e.target.value)}
            label="Password"
            defaultValue=""
            error={
              isFormValidated && loginDataError["password"]
                ? loginDataError["password"]
                : null
            }
          />
          <div className="forg-pwdwrap">
            <Link to={FORGOTPASSWORD} className="forgot-pwd">Forgot your password?</Link>
          </div>
          <CustomButton
            isLoading={isLoading(apiStatus)}
            disabled={isLoading(apiStatus)}
            bg="fill"
            onClick={(e: any) => loginHandler(e)}
          >Log in</CustomButton>
        </div>
        <div className="seprator"><span>or</span></div>
        <div className="button-bar">
          <div className="google socialloginbtn">
            {/* <img src={googleSocialLogin} alt="Google Login"></img> */}
            <GoogleLogin />
          </div>
          {/*<div className="fb socialloginbtn">
            <FacebookLogin />
          </div>*/}
        </div>
        <div className="action-bar">
          <div className="custom-action">
            Don’t you have an account?
            <a onClick={() => props.onButtonChg(CHECKOUTPAGE.auth_signup)} className="signup-link">Sign up</a>
          </div>
          <div className="guest-action">
            You can also buy
            <a onClick={() => props.onButtonChg(CHECKOUTPAGE.auth_guest)} className="as-guest-link">as a guest</a>
          </div>
        </div>
      </form>
    </>
  )
}

export default CkLogin;