import CustomButton from "../../../Common/CustomButton/CustomButton";
import InputGroups from "../../../Common/InputGroups/InputGroups";
import PasswordGroups from "../../../Common/PasswordGroups/PasswordGroups";
import googleSocialLogin from "../../../../Assets/img/google.svg";
import fbSocialLogin from "../../../../Assets/img/FB.svg";
import { useEffect, useState } from "react";
import { CHECKOUTPAGE } from "../../../../Constant/General";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../../Constant/Api";
import validate from "../../../../Utility/Validation";
import { signUpAction } from "../../../../Redux/Auth/AuthAction";
import { SIGNUP_SUCCESS, SIGNUP_FAILD, SIGNUP_LONG, SIGNUP_REQUEST } from "../../../../Redux/Auth/AuthType";
import { SUCCESS_RESPONSE_CODE } from "../../../../Constant/Status";
import { isLoading } from "../../../../Utility/General";
import GoogleLogin from "../../../SocialLogin/GoogleLogin/GoogleLogin";
import { QUOTE_ID } from "../../../../Constant/LocalStorage";

//import FacebookLogin from "../../../SocialLogin/FacebookLogin/FacebookLogin";

const CkSignup = (props: any) => {

  let quoteId = localStorage.getItem(QUOTE_ID)
? localStorage.getItem(QUOTE_ID)
: "";

  // const [logInCalled, islogInCalled] = useState(false);

  // function multipleCall() {
    
  //   islogInCalled(true);
  //   props.onButtonChg(logInCalled);
  // }
  const dispatch = useDispatch();

  const INITIAL_SIGNUP_DATA: any = {
    firstname: {
      value: "",
      validation: ["required"],
      errors: ["Name is required."],
    },
    lastname: {
      value: "",
      validation: ["required"],
      errors: ["Surname is required."],
    },
    email: {
      value: "",
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
    password: {
      value: "",
      validation: ["required"],
      errors: ["Password is required."],
    },
  };

  const [signupData, setSignupData] = useState(INITIAL_SIGNUP_DATA);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [signupDataError, setSignupDataError] = useState(demo);

  const setData = (field: string, value: string) => {
    let signupDetails = Object.assign({}, signupData);
    signupDetails[field].value = value;
    setSignupData({ ...signupDetails });
  };

  useEffect(() => {
    setSignupDataError({ ...validate(signupData)?.errors });
  }, [signupData]);

  const signupHandler = (e: any) => {
    e.preventDefault();
    if (validate(signupData).isValidated === true) {
      dispatch(
        signUpAction({
          firstname: signupData.firstname.value,
          lastname: signupData.lastname.value,
          email: signupData.email.value,
          password: signupData.password.value,
          quoteId: quoteId,
        })
      );
    } else {
      setSignupDataError({ ...validate(signupData)?.errors });
      setIsFormValidated(true);
    }
  };

  useSelector((state: any) => {
    if (state?.signup?.type === SIGNUP_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.signup?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.signup?.type === SIGNUP_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        props.onButtonChg(CHECKOUTPAGE.auth_login);
      }
    } else if (state?.signup?.type === SIGNUP_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.signup?.type === SIGNUP_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  return (
    <>
      <form className="signup-form">
        <div className="form-fieldset">
          <InputGroups
            type="text"
            label="Name"
            autoComplete="off"
            onChange={(e: any) => setData("firstname", e.target.value)}
            error={
              isFormValidated && signupDataError["firstname"]
                ? signupDataError["firstname"]
                : null
            }
          />
          <InputGroups
            type="text"
            label="Surname"
            autoComplete="off"
            onChange={(e: any) => setData("lastname", e.target.value)}
            error={
              isFormValidated && signupDataError["lastname"]
                ? signupDataError["lastname"]
                : null
            }
          />
          <InputGroups
            type="email"
            label="Email"
            autoComplete="off"
            onChange={(e: any) => setData("email", e.target.value)}
            error={
              isFormValidated && signupDataError["email"]
                ? signupDataError["email"]
                : null
            }
          />
          <PasswordGroups
            label={"Password"}
            autoComplete="off"
            onChange={(e: any) => setData("password", e.target.value)}
            error={
              isFormValidated && signupDataError["password"]
                ? signupDataError["password"]
                : null
            }
          />
          <CustomButton
            isLoading={isLoading(apiStatus)}
            disabled={isLoading(apiStatus)}
            bg="fill"
            onClick={(e: any) => signupHandler(e)}
          >
            SIGN UP
          </CustomButton>
        </div>
        <div className="seprator">
          <span>or</span>
        </div>
        <div className="button-bar">
          <div className="google socialloginbtn">
            {/* <img src={googleSocialLogin} alt="Google Login"></img> */}
            <GoogleLogin/>
          </div>
          {/*<div className="fb socialloginbtn">
            <FacebookLogin/>
          </div>*/}
        </div>
        <div className="action-bar">
          <div className="custom-action">
            Do you already have an account?
            <a
              onClick={() => props.onButtonChg(CHECKOUTPAGE.auth_login)}
              className="login-link"
            >
              Log in
            </a>
          </div>
          <div className="guest-action">
            You can also buy
            <a
              onClick={() => props.onButtonChg(CHECKOUTPAGE.auth_guest)}
              className="as-guest-link"
            >
              as a guest
            </a>
          </div>
          {/* <div className="custom-action">
            Do you already have an account?
            <a onClick={() => multipleCall()} className="login-link">Log in</a>
          </div>
          <div className="guest-action">
            You can also buy
            <a onClick={() => props.onNextClick(props?.activeTabCount + 1)} className="as-guest-link">as a guest</a>
          </div> */}
        </div>
      </form>
    </>
  );
}

export default CkSignup;