import { useEffect, useState } from "react";
import AddressPopup from "./AddressPopup/AddressPopup";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import {
  ADDRESS_FAILD,
  ADDRESS_LONG,
  ADDRESS_REQUEST,
  ADDRESS_SUCCESS,
} from "../../../Redux/Account/AccountType";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { addressAction } from "../../../Redux/Account/AccountAction";
import Loader from "../../Loader/Loader";

const Addresses = () => {
  const [AddressModalShow, setAddressModalShow] = useState(false);

  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [addressData, setAddressData] = useState({});
  const address = useSelector((state: any) => state?.address?.data);
  const addressUpdate = useSelector(
    (state: any) => state?.addressUpdateData?.data
  );

  const addressUpdateStatusCode = addressUpdate?.statusCode;
  useEffect(() => {
    dispatch(addressAction());
  }, [addressUpdateStatusCode]);

  useSelector((state: any) => {
    if (state?.address?.type === ADDRESS_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.address?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.address?.type === ADDRESS_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.address?.type === ADDRESS_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.address?.type === ADDRESS_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  const [popupType, setPopupType] = useState("add");

  const addressPopupFun = (type: any, data: any) => {
    setPopupType(type);
    setAddressModalShow(true);
    setAddressData(data);
  };

  //const defaultAddress = [
  //  {
  //    addressId: "4",
  //    alias: "Default Shipping Address",
  //    firstName: "piyush1",
  //    lastName: "patel",
  //    block: "to-bamanva",
  //    avenue: "ta-patdi",
  //    street: "to-bamanvata-patdi",
  //    building: "Al-Tawaq",
  //    floor: "2nd",
  //    apartment_number: "1202",
  //    addressType: "Apartment",
  //    countryCode: "+965",
  //    countryId: "US",
  //    country: "United States",
  //    phonenumber: "9726540612",
  //    postCode: "0000",
  //    isSelected: "1",
  //  },
  //  {
  //    addressId: "4",
  //    alias: "Default Billing Address",
  //    firstName: "piyush1",
  //    lastName: "patel",
  //    block: "to-bamanva",
  //    avenue: "ta-patdi",
  //    street: "to-bamanvata-patdi",
  //    building: "Al-Tawaq",
  //    floor: "2nd",
  //    apartment_number: "1202",
  //    addressType: "Apartment",
  //    countryCode: "+965",
  //    countryId: "US",
  //    country: "United States",
  //    phonenumber: "9726540612",
  //    postCode: "0000",
  //    isSelected: "1",
  //  },
  //];

  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <>
          <div className="address-wrap">
            <div className="page-title">
              <h5>Addresses</h5>
            </div>
            <div className="page-content">
              <div className="default-address">
                <h6>Default Address</h6>
                <div>
                  {address?.component[0]?.shippingAddress.map(
                    (data: any, index: number) => {
                      return (
                        <div className="blocks" key={index + "adress"}>
                          <span>Shipping Address</span>
                          <div className="block-title">
                            <label>{data.alias}</label>
                            <a
                              className="right-action"
                              // onClick={() => setAddressModalShow(true)}
                              onClick={() => addressPopupFun("edit", data)}
                            ></a>
                          </div>
                          <div className="block-content">
                            <div className="addr-desc">
                              <div className="name-wrap">
                                <label>
                                  {data.firstName + " " + data.lastName}
                                </label>
                              </div>
                              <div className="addr-wrap">
                                <address>
                                  {data?.addressType === "Apartment"
                                  ? data.apartment_number +
                                    "," +
                                  data.floor +
                                  ","
                                  : ""}
                                 {data.building +
                                  "," +
                                  data.customer_area +
                                    "," +
                                  data.block +
                                  "," +
                                  data.avenue + 
                                  "," +
                                  data.street}
                                </address>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div>
                  {address?.component[1]?.billingAddress.map(
                    (data: any, index: number) => {
                      return (
                        <div className="blocks" key={index + "adress"}>
                          <span>Billing Address</span>
                          <div className="block-title">
                            <label>{data.alias}</label>
                            <a
                              className="right-action"
                              // onClick={() => setAddressModalShow(true)}
                              onClick={() => addressPopupFun("edit", data)}
                            ></a>
                          </div>
                          <div className="block-content">
                            <div className="addr-desc">
                              <div className="name-wrap">
                                <label>
                                  {data.firstName + " " + data.lastName}
                                </label>
                              </div>
                              <div className="addr-wrap">
                                <address>
                                  {data?.addressType === "Apartment"
                                  ? data.apartment_number +
                                    "," +
                                  data.floor +
                                  ","
                                  : ""}
                                  {data.building +
                                    "," +
                                    data.customer_area +
                                    "," +
                                    data.block +
                                    "," +
                                    data.avenue + 
                                    "," +
                                    data.street}
                                </address>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
              <div className="address-blocks">
                <h6>Additional Address Entries</h6>
                {address?.component[2]?.allAddress.map(
                  (data: any, index: number) => (
                    <div className="blocks" key={index + "adress"}>
                      {/*{console.log(data)}*/}
                      <div className="block-title">
                        <label>{data.alias}</label>
                        <a
                          className="right-action"
                          // onClick={() => setAddressModalShow(true)}
                          onClick={() => addressPopupFun("edit", data)}
                        ></a>
                      </div>
                      <div className="block-content">
                        <div className="addr-desc">
                          <div className="name-wrap">
                            <label>
                              {data.firstName + " " + data.lastName}
                            </label>
                          </div>
                          <div className="addr-wrap">
                            <address>
                            {data?.addressType === "Apartment"
                                ? data.apartment_number +
                                  "," +
                                data.floor +
                                 ","
                                : ""}
                            {data.building +
                              "," +
                             data.customer_area +
                              "," +
                             data.block +
                              "," +
                              data.avenue + 
                              "," +
                              data.street}
                            </address>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="add-new">
                <div className="add-newwrap">
                  <a
                    className="add-new-modal"
                    onClick={() => addressPopupFun("add", {})}
                  >
                    Add a new address
                  </a>
                </div>
              </div>
            </div>
          </div>
          <AddressPopup
            show={AddressModalShow}
            type={popupType}
            address={addressData}
            onHide={() => setAddressModalShow(false)}
          ></AddressPopup>
        </>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    // return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
};

export default Addresses;
