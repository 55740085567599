import { useState } from "react";
import { CHECKOUTPAGE } from "../../../Constant/General";
import InputGroups from "../../Common/InputGroups/InputGroups";
import CheckoutAddress from "../CheckoutAddress/CheckoutAddress";

const CheckoutPayment = (props: any) => {
  const [status, setStatus] = useState(1);

  const radioHandler = (status: any) => {
    setStatus(status);
  };

  return (
    <>
      {/* this is web header start */}
      <div
        className="step-title web-view"
        onClick={() => props.onHeaderClick(false)}
      >
        Payment
      </div>
      {/* this is web header end */}

      {/* this is mobile header start */}
      <div className="step-title mobile-view">Payment</div>
      {/* this is mobile header end */}
      <div className="payment-steps">
        {props?.payment?.paymentData?.list?.length > 0
          ? props?.payment?.paymentData?.list?.map(
              (item: any, index: number) => {
                return (
                  <div className="rd methodlist" key={index + "paymentMethod"}>
                    <input
                      type="radio"
                      id={"payment" + index}
                      name="payment-method"
                      value={item.paymentType}
                      onClick={() =>
                        props.setPaymentType(item.paymentId, item.paymentType)
                      }
                    />
                    <label htmlFor={"payment" + index}>{item.title}</label>
                  </div>
                );
              }
            )
          : ""}
      </div>
      {/* <div className="payment-steps">
        <div className="rd methodlist">
          <input
            type="radio"
            id="payment1"
            name="payment-gateway"
            checked={status === 1}
            onClick={() => radioHandler(1)}
          />
          <label htmlFor="payment1">Credit Card</label>
        </div>
        <div className={status === 1 ? "show details" : "hide details"}>
          <form className="ccard-form">
            <InputGroups label="Number card"></InputGroups>
            <div className="half">
              <InputGroups label="Expiry"></InputGroups>
              <InputGroups label="CVC"></InputGroups>
            </div>
            <InputGroups label="Cardholder"></InputGroups>
          </form>
        </div>
        <div className="rd methodlist">
          <input
            type="radio"
            id="payment2"
            name="payment-gateway"
            checked={status === 2}
            onClick={() => radioHandler(2)}
          />
          <label htmlFor="payment2">KNET</label>
        </div>
        <div className={status === 2 ? "show details" : "hide details"}>
          <form className="knet-form">
            <InputGroups label="KNET Number"></InputGroups>
          </form>
        </div>
      </div> */}
    </>
  );
};

export default CheckoutPayment;
