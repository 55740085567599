import Header from "../Header/Header";
import CheckoutHeader from "../CheckoutHeader/CheckoutHeader";
import DiscountBanner from "../DiscountBanner/DiscountBanner";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { CHECKOUT, ORDERSUCCESS } from "../../Constant/Route";

const MainHeader = () => {
  let location = useLocation();
  const [path, setPath] = useState("");
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);
  return (
    <>
      {path === ORDERSUCCESS || path === CHECKOUT ? (
        <CheckoutHeader />
      ) : (
        <>
          <DiscountBanner />
          <Header />
        </>
      )}
    </>
  );
};

export default MainHeader;
