export const MASTER_REQUEST = "MASTER_REQUEST";
export const MASTER_SUCCESS = "MASTER_SUCCESS";
export const MASTER_FAILD = "MASTER_FAILD";
export const MASTER_LONG = "MASTER_LONG";

export const MINI_CART_TRUE = "MINI_CART_TRUE";
export const MINI_CART_FALSE = "MINI_CART_FALSE";

export const DEFAULT_PARAMETER = "DEFAULT_PARAMETER";

export const NEWSLETTER_SUBSCRIPTION_REQUEST = "NEWSLETTER_SUBSCRIPTION_REQUEST";
export const NEWSLETTER_SUBSCRIPTION_SUCCESS = "NEWSLETTER_SUBSCRIPTION_SUCCESS";
export const NEWSLETTER_SUBSCRIPTION_FAILD = "NEWSLETTER_SUBSCRIPTION_FAILD";
export const NEWSLETTER_SUBSCRIPTION_LONG = "NEWSLETTER_SUBSCRIPTION_LONG";

export const ADD_REMOVE_WISH_LIST_REQUEST = "ADD_REMOVE_WISH_LIST_REQUEST";
export const ADD_REMOVE_WISH_LIST_SUCCESS = "ADD_REMOVE_WISH_LIST_SUCCESS";
export const ADD_REMOVE_WISH_LIST_FAILD = "ADD_REMOVE_WISH_LIST_FAILD";
export const ADD_REMOVE_WISH_LIST_LONG = "ADD_REMOVE_WISH_LIST_LONG";
export const ADD_REMOVE_WISH_LIST_CLEAR = "ADD_REMOVE_WISH_LIST_CLEAR";

export const CART_COUNT_GLOBAL = "CART_COUNT_GLOBAL";