export const CONTACTUS_REQUEST = "CONTACTUS_REQUEST";
export const CONTACTUS_SUCCESS = "CONTACTUS_SUCCESS";
export const CONTACTUS_FAILD = "CONTACTUS_FAILD";
export const CONTACTUS_LONG = "CONTACTUS_LONG";
export const CONTACTUS_CLEAR = "CONTACTUS_CLEAR";

export const GET_CONTACTUS_REQUEST = "GET_CONTACTUS_REQUEST";
export const GET_CONTACTUS_SUCCESS = "GET_CONTACTUS_SUCCESS";
export const GET_CONTACTUS_FAILD = "GET_CONTACTUS_FAILD";
export const GET_CONTACTUS_LONG = "GET_CONTACTUS_LONG";
export const GET_CONTACTUS_CLEAR = "GET_CONTACTUS_CLEAR";