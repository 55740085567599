import { END_POINTS, MAX_CALLS } from "../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import api from "../../Service/Axios";
import { PRODUCT_DETAIL_FAILD, PRODUCT_DETAIL_LONG, PRODUCT_DETAIL_REQUEST, PRODUCT_DETAIL_SUCCESS, PRODUCT_VARIENT_FAILD, PRODUCT_VARIENT_LONG, PRODUCT_VARIENT_REQUEST, PRODUCT_VARIENT_SUCCESS,  PRODUCT_LIST_FAILD, PRODUCT_LIST_LONG, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS } from "./ProductType";

export function productListAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: PRODUCT_LIST_REQUEST,
      payload: { type: PRODUCT_LIST_REQUEST },
    });
    function recursiveCall() {
      api
        .get(`${END_POINTS.productList}`,param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            // showToast("success", result.data.message);
            dispatch({
              type: PRODUCT_LIST_SUCCESS,
              payload: { data: result.data, type: PRODUCT_LIST_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PRODUCT_LIST_LONG,
              payload: { data: {}, type: PRODUCT_LIST_LONG },
            });
          } else {
            dispatch({
              type: PRODUCT_LIST_FAILD,
              payload: { data: {}, type: PRODUCT_LIST_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PRODUCT_LIST_LONG,
              payload: { data: {}, type: PRODUCT_LIST_LONG },
            });
          } else {
            dispatch({
              type: PRODUCT_LIST_FAILD,
              payload: { data: {}, type: PRODUCT_LIST_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function productDetailAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: PRODUCT_DETAIL_REQUEST,
      payload: { type: PRODUCT_DETAIL_REQUEST },
    });
    function recursiveCall() {
      api
        .get(`${END_POINTS.productDetail+param}`, {})
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            dispatch({
              type: PRODUCT_DETAIL_SUCCESS,
              payload: { data: result.data, type: PRODUCT_DETAIL_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PRODUCT_DETAIL_LONG,
              payload: { data: {}, type: PRODUCT_DETAIL_LONG },
            });
          } else {
            dispatch({
              type: PRODUCT_DETAIL_FAILD,
              payload: { data: {}, type: PRODUCT_DETAIL_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PRODUCT_DETAIL_LONG,
              payload: { data: {}, type: PRODUCT_DETAIL_LONG },
            });
          } else {
            dispatch({
              type: PRODUCT_DETAIL_FAILD,
              payload: { data: {}, type: PRODUCT_DETAIL_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function productDetailVariantAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: PRODUCT_VARIENT_REQUEST,
      payload: { type: PRODUCT_VARIENT_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.productDetailVarient}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            dispatch({
              type: PRODUCT_VARIENT_SUCCESS,
              payload: { data: result.data, type: PRODUCT_VARIENT_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PRODUCT_VARIENT_LONG,
              payload: { data: {}, type: PRODUCT_VARIENT_LONG },
            });
          } else {
            dispatch({
              type: PRODUCT_VARIENT_FAILD,
              payload: { data: {}, type: PRODUCT_VARIENT_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PRODUCT_VARIENT_LONG,
              payload: { data: {}, type: PRODUCT_VARIENT_LONG },
            });
          } else {
            dispatch({
              type: PRODUCT_VARIENT_FAILD,
              payload: { data: {}, type: PRODUCT_VARIENT_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

