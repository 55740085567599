import { PURAL_CLUB_CLEAR, PURAL_CLUB_FAILD, PURAL_CLUB_LONG, PURAL_CLUB_REQUEST, PURAL_CLUB_SUCCESS } from "./ClubType";


export function getClubPageReducer(state = {}, action: any) {
  switch (action.type) {
    case PURAL_CLUB_REQUEST:
      return { ...action.payload };
    case PURAL_CLUB_SUCCESS:
      return { ...action.payload };
    case PURAL_CLUB_FAILD:
      return { ...action.payload };
    case PURAL_CLUB_LONG:
      return { ...action.payload };
    case PURAL_CLUB_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
}