import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import validate from "../../../../Utility/Validation";
import CheckboxLabel from "../../../Common/CheckboxLabel/CheckboxLabel";
import CustomButton from "../../../Common/CustomButton/CustomButton";
import InputGroups from "../../../Common/InputGroups/InputGroups";
import SelectGropus from "../../../Common/SelectGroups/SelectGroups";
import { useSelector } from "react-redux";

const CkAddressPopup = (props: any) => {
  const { popupType } = props;
  const phoneNo = ["+965", "+569", "+695"]

  // const selectData = ["Apartment", "Tenament", "House"];
  const types = [
    { id: "Apartment", name: "Apartment" },
    { id: "Home", name: "Home" },
  ];
  const auth = useSelector((state: any) => state?.login?.isLoggedIn); 
  const INITIAL_ADDRESS_DATA: any = {
    isUpdate: {
      value: "0",
    },
    addressId: {
      value: "0",
    },
    b_addressId: {
      value: "0",
    },
    alias: {
      value: "",
      //validation: ["required"],
      //errors: ["Alias is required."],
    },
    firstName: {
      value: auth ? JSON.parse(localStorage.getItem("user") || '{}').firstname : "",
      validation: ["required"],
      errors: ["firstName is required."],
    },
    lastName: {
      value:  auth ? JSON.parse(localStorage.getItem("user") || '{}').lastname : "",
      validation: ["required"],
      errors: ["lastName is required."],
    },
    countryCode: {
      value: "+951",
      validation: ["required"],
      errors: ["Country Code is required."],
    },
    phoneNumber: {
      value: "",
      validation: ["required"],
      errors: ["Phone Number is required."],
    },
    addressType: {
      value: "Apartment",
      validation: ["required"],
      errors: ["Address Type is required."],
    },
    block: {
      value: "",
      validation: ["required"],
      errors: ["Block is required."],
    },
    avenue: {
      value: "",
      //validation: ["required"],
      //errors: ["Avenue is required."],
    },
    street: {
      value: "",
      validation: ["required"],
      errors: ["Street is required."],
    },
    building: {
      value: "",
      validation: ["required"],
      errors: ["Building is required."],
    },
    customer_area: {
      value: "",
      validation: ["required"],
      errors: ["Area is required."],
    },
    floor: {
      value: "",
      validation: ["requiredIf:addressType:Appartment"],
      errors: ["Floor is required."],
    },
    apartment_number: {
      value: "",
      validation: ["requiredIf:addressType:Appartment"],
      errors: ["Appartment Number is required."],
    },
    isBilingSelect: {
      value: "1",
    },
    isDefaultBilling: {
      value: "1",
    },
    isDefaultShipping: {
      value: "1"
    },
    //postCode: {
    //  value:""
    //},
    b_alias: {
      value: "",
      //validation: ["requiredIf:isBilingSelect:0"],
      //errors: ["Alias is required."],
    },
    b_firstName: {
      value: "",
      validation: ["requiredIf:isBilingSelect:0"],
      errors: ["firstName is required."],
    },
    b_lastName: {
      value: "",
      validation: ["requiredIf:isBilingSelect:0"],
      errors: ["Lastname is required."],
    },
    b_countryCode: {
      value: "+951",
      validation: ["requiredIf:isBilingSelect:0"],
      errors: ["Country Code is required."],
    },
    b_phoneNumber: {
      value: "",
      validation: ["requiredIf:isBilingSelect:0"],
      errors: ["Phone Number is required."],
    },
    b_addressType: {
      value: "Apartment",
      validation: ["requiredIf:isBilingSelect:0"],
      errors: ["Address Type is required."],
    },
    b_block: {
      value: "",
      validation: ["requiredIf:isBilingSelect:0"],
      errors: ["Block is required."],
    },
    b_avenue: {
      value: "",
      //validation: ["requiredIf:isBilingSelect:0"],
      //errors: ["Avenue is required."],
    },
    b_street: {
      value: "",
      validation: ["requiredIf:isBilingSelect:0"],
      errors: ["Street is required."],
    },
    b_building: {
      value: "",
      validation: ["requiredIf:isBilingSelect:0"],
      errors: ["Building is required."],
    },
    b_customer_area: {
      value: "",
      validation: ["requiredIf:isBilingSelect:0"],
      errors: ["Area is required."],
    },
    b_floor: {
      value: "",
      validation: ["requiredIf:b_addressType:Appartment"],
      errors: ["Floor is required."],
    },
    b_apartment_number: {
      value: "",
      validation: ["requiredIf:b_addressType:Appartment"],
      errors: ["Appartment Number is required."],
    },
  };
  const [addressData, setAddressData] = useState(INITIAL_ADDRESS_DATA);

  const editAddressData = (data: any) => {
    //console.log("data",data);
    if(typeof data !== "undefined" && Object.keys(data).length){
      let addressDetails = Object.assign({}, data);
      let assignAble = [
        "alias",
        "firstName",
        "lastName",
        "countryCode",
        "phoneNumber",
        "addressType",
        "block",
        "avenue",
        "street",
        "building",
        "customer_area",
        "floor",
        "apartment_number",
        "addressId",
        "isDefaultShipping",
        "isDefaultBilling"
      ];
      let assignAble2 = [
        "alias",
        "firstName",
        "lastName",,
        "phoneNumber",
        "addressType",
        "block",
        "avenue",
        "street",
        "building",
        "customer_area",
        "floor",
        "apartment_number",
        "addressId"
      ]
      Object.keys(addressDetails).forEach((component: any) => {
        if(props.addressType === "billing"){
            if (assignAble2.includes(component)) {
              let newComponent = 'b_' + component
              setData(newComponent, addressDetails[component]);
            }
            if(popupType !== "add"){
              Object.keys(props.currentShipping).forEach((componentShip: any) => {
                if (assignAble.includes(componentShip)) {
                  setData(componentShip, props.currentShipping[componentShip]);
                }
              });
            }
          }else {
            if (assignAble.includes(component)) {
            setData(component, addressDetails[component]);
            Object.keys(props.currentBilling).forEach((componentBill: any) => {
              if (assignAble2.includes(componentBill)) {
                let newComponent = 'b_' + componentBill
                setData(newComponent, props.currentBilling[componentBill]);
              }
            });
          }
        }
      });
      if(props.addressType === "billing" && data.addressId === props.currentShipping.addressId) {
        setData("isBilingSelect","1")
      }else if(props.addressType === "shipping" && data.addressId === props.currentBilling.addressId) {
        setData("isBilingSelect","1")
      }else{
        setData("isBilingSelect","0")
      }
      setData("isUpdate", "1");
    } else {
      setAddressData(INITIAL_ADDRESS_DATA)
    }
  }  

  const addAddressData = (data: any) => {
    //console.log("add data-->",data);
    if(typeof data !== "undefined" && Object.keys(data).length){
      let addressDetails = Object.assign({}, data);
      let assignAble = [
        "alias",
        "firstName",
        "lastName",
        "phoneNumber",
        "block",
        "avenue",
        "street",
        "building",
        "floor",
        "customer_area",
        "apartment_number",
        "addressId",
        "isDefaultBilling"
      ];
      let assignAble2 = [
        "alias",
        "firstName",
        "lastName",
        "countryCode",
        "phoneNumber",
        "addressType",
        "block",
        "avenue",
        "street",
        "building",
        "customer_area",
        "floor",
        "addressId",
        "apartment_number",
      ]
      Object.keys(addressDetails).forEach((component: any) => {
        if (assignAble2.includes(component)) {
          let newComponent = 'b_' + component
          setData(newComponent, addressDetails[component]);
        }
        assignAble.map((componentShip: any) => {
          if (assignAble.includes(componentShip)) {
            setData(componentShip, "");
          }
        });
      });
      setData("isBilingSelect","1 ")
      setData("isUpdate", "0");
      setData("firstName",auth ? JSON.parse(localStorage.getItem("user") || '{}').firstname : "");
      setData("lastName",auth ? JSON.parse(localStorage.getItem("user") || '{}').lastname : "");
    } else {
      setAddressData(INITIAL_ADDRESS_DATA)
    }
  }
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [addrDataError, setAddrDataError] = useState(demo);
  const setData = (field: string, value: string) => {   
    let addressDetails = Object.assign({}, addressData);
    addressDetails[field].value = value;
    setAddressData({ ...addressDetails });
  };  
  const submitHandler = (e: any) => { 
    e.preventDefault();
    if (validate(addressData).isValidated === true) {
      let addrData: any = {
        isBilingSelect: "",
        address: {},
        bilingaddress: {},
      };
      for (let key in addressData) {
        if (addressData["isBilingSelect"] == "1") {
          if (
            addressData[key] &&
            !key.includes("b_") &&
            key != "isBilingSelect"
          ) {

            addrData["address"][key] = addressData[key];
            //addrData["bilingaddress"][key] = addressData[key];
          }
        } else {
          if (
            addressData[key] &&
            !key.includes("b_") &&
            key != "isBilingSelect"
          ) {
            addrData["address"][key] = addressData[key];
          } else if (key.includes("b_")) {
            addrData["bilingaddress"][key] = addressData[key];             
          }
        }
        addrData["isBilingSelect"] = addressData["isBilingSelect"];
      }

      props.onSubmit(addrData);
      props.onHide();
    } else {
      setAddrDataError({ ...validate(addressData)?.errors });
      setIsFormValidated(true);
    }
  };

useEffect(() => {
  if(popupType === "edit"){
    editAddressData(props.editAddressData)
  } else if(popupType === "add"){
    addAddressData(props.currentBilling)
  }
  else{
    setAddressData(INITIAL_ADDRESS_DATA)
  }
},[props.editAddressData])

useEffect(() => {
  setAddrDataError({ ...validate(addressData)?.errors });
  setIsFormValidated(false);
}, [addressData]);

  //useEffect(() => {
  //  if (props.editAddressData) {
  //    let addressDetails = Object.assign({}, props.editAddressData);
  //    for (let key in addressDetails) {
  //      if(key === "address"){
  //        for(let key1 in addressDetails[key]){
  //          setData(key1, addressDetails[key][key1]);
  //        }
  //      }else if(key === "bilingaddress"){
  //        for(let key1 in addressDetails[key]){
  //          setData('b_'+key1, addressDetails[key][key1]);
  //        }
  //      }
        
  //      // if (props.addressData[key]) {
  //      //   addressDetails[key].value = props.addressData[key];
  //      // }
  //    }
  //    setAddressData({ ...addressDetails });
  //  }
  //}, [props.editAddressData]);


    
  return (
    <Modal {...props} centered className="ckaddress-popup">
      <Modal.Header closeButton>
        <h5 className="m-head">
          {popupType == "add" ? "Add Address" : "Edit Address"}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <form className="ckaddress-form">
          <InputGroups
            value={addressData?.alias?.value || ""}
            onChange={(e: any) => setData("alias", e.target.value)}
            error={
              isFormValidated && addrDataError["alias"]
                ? addrDataError["alias"]
                : null
            }
            //label={"Alias, for instance: “Home” (Optional)"}
            label={"Alias"}
          ></InputGroups>
          <div className="contact-details sub-section">
            <h4>Contact Details</h4>
            <InputGroups
              value={addressData?.firstName?.value || ""}
              onChange={(e: any) => setData("firstName", e.target.value)}
              error={
                isFormValidated && addrDataError["firstName"]
                  ? addrDataError["firstName"]
                  : null
              }
              type={"text"}
              label={"Name"}
            ></InputGroups>
            <InputGroups
              value={addressData?.lastName?.value || ""}
              onChange={(e: any) => setData("lastName", e.target.value)}
              error={
                isFormValidated && addrDataError["lastName"]
                  ? addrDataError["lastName"]
                  : null
              }
              type={"text"}
              label={"Surname"}
            ></InputGroups>
            <div className="phone input-groups">
              <select
                value={addressData?.countryCode?.value}
                onChange={(e: any) => setData("countryCode", e.target.value)}
              >
                {phoneNo.map((items: any, index: any) => (
                  <option key={index + "phone2"} value={items}>
                    {items}
                  </option>
                ))}
              </select>
              <InputGroups
                value={addressData?.phoneNumber?.value || ""}
                onChange={(e: any) => setData("phoneNumber", e.target.value)}
                error={
                  isFormValidated && addrDataError["phoneNumber"]
                    ? addrDataError["phoneNumber"]
                    : null
                }
                type={"text"}
                label={"Phone"}
              ></InputGroups>
            </div>
          </div>
          <div className="contact-details sub-section">
            <h4>Shipping Address</h4>
            <SelectGropus
              defaultSelect={addressData?.addressType?.value || ""}
              onChange={(e: any) => setData("addressType", e.target.value)}
              error={
                isFormValidated && addrDataError["addressType"]
                  ? addrDataError["addressType"]
                  : null
              }
              value={types}
            ></SelectGropus>
            <InputGroups
              value={addressData?.avenue?.value || ""}
              onChange={(e: any) => setData("avenue", e.target.value)}
              error={
                isFormValidated && addrDataError["avenue"]
                  ? addrDataError["avenue"]
                  : null
              }
              type={"text"}
              label={"Avenue"}
            ></InputGroups>
            <InputGroups
              value={addressData?.block?.value || ""}
              onChange={(e: any) => setData("block", e.target.value)}
              error={
                isFormValidated && addrDataError["block"]
                  ? addrDataError["block"]
                  : null
              }
              type={"text"}
              label={"Block"}
            ></InputGroups>
            <InputGroups
              value={addressData?.street?.value || ""}
              onChange={(e: any) => setData("street", e.target.value)}
              error={
                isFormValidated && addrDataError["street"]
                  ? addrDataError["street"]
                  : null
              }
              type={"text"}
              label={"Street"}
            ></InputGroups>

            <InputGroups
              value={addressData?.building?.value || ""}
              onChange={(e: any) => setData("building", e.target.value)}
              error={
                isFormValidated && addrDataError["building"]
                  ? addrDataError["building"]
                  : null
              }
              type={"text"}
              label={"Building"}
            ></InputGroups>
            <InputGroups
              value={addressData?.customer_area?.value}
              onChange={(e: any) => setData("customer_area", e.target.value)}
              error={
                isFormValidated && addrDataError["customer_area"]
                  ? addrDataError["customer_area"]
                  : null
              }
              type={"text"}
              label={"Area"}
            ></InputGroups>
            <div className="half">
              {addressData?.addressType?.value == "Apartment" ? (
                <div className="half">
                  <InputGroups
                    value={addressData?.floor?.value || ""}
                    onChange={(e: any) => setData("floor", e.target.value)}
                    error={
                      isFormValidated && addrDataError["floor"]
                        ? addrDataError["floor"]
                        : null
                    }
                    type={"text"}
                    label={"Floor"}
                  ></InputGroups>
                  <InputGroups
                    value={addressData?.apartment_number?.value || ""}
                    onChange={(e: any) =>
                      setData("apartment_number", e.target.value)
                    }
                    error={
                      isFormValidated && addrDataError["apartment_number"]
                        ? addrDataError["apartment_number"]
                        : null
                    }
                    type={"text"}
                    label={"Apartment number"}
                  ></InputGroups>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="same-as">
              <CheckboxLabel
                value={addressData?.isBilingSelect?.value}
                checked={!!parseInt(addressData?.isBilingSelect?.value)}
                onChange={(e: any) =>
                  setData("isBilingSelect", e.target.checked ? "1" : "0")
                }
              >
                Same billing address
              </CheckboxLabel>
            </div>
          </div>
          {addressData?.isBilingSelect?.value === "0" ? (
            <>
              <InputGroups
                value={addressData?.b_alias?.value || ""}
                onChange={(e: any) => setData("b_alias", e.target.value)}
                error={
                  isFormValidated && addrDataError["b_alias"]
                    ? addrDataError["b_alias"]
                    : null
                }
                label={"Alias"}
                //label={"Alias, for instance: “Home” (Optional)"}
              ></InputGroups>
              <div className="contact-details sub-section">
                <h4>Contact Details</h4>
                <InputGroups
                  value={addressData?.b_firstName?.value || ""}
                  onChange={(e: any) => setData("b_firstName", e.target.value)}
                  error={
                    isFormValidated && addrDataError["b_firstName"]
                      ? addrDataError["b_firstName"]
                      : null
                  }
                  type={"text"}
                  label={"Name"}
                ></InputGroups>
                <InputGroups
                  value={addressData?.b_lastName?.value || ""}
                  onChange={(e: any) => setData("b_lastName", e.target.value)}
                  error={
                    isFormValidated && addrDataError["b_lastName"]
                      ? addrDataError["b_lastName"]
                      : null
                  }
                  type={"text"}
                  label={"Surname"}
                ></InputGroups>
                <div className="phone input-groups">
                  <select
                    value={addressData?.b_countryCode?.value || ""}
                    onChange={(e: any) =>
                      setData("b_countryCode", e.target.value)
                    }
                  >
                    {phoneNo.map((items: any, index: any) => (
                      <option key={index + "phone3"} value={items}>
                        {items}
                      </option>
                    ))}
                  </select>
                  <InputGroups
                    value={addressData?.b_phoneNumber?.value || ""}
                    onChange={(e: any) =>
                      setData("b_phoneNumber", e.target.value)
                    }
                    error={
                      isFormValidated && addrDataError["b_phoneNumber"]
                        ? addrDataError["b_phoneNumber"]
                        : null
                    }
                    type={"text"}
                    label={"Phone"}
                  ></InputGroups>
                </div>
              </div>
              <div className="contact-details sub-section">
                <h4>Billing Address</h4>
                <SelectGropus
                  defaultSelect={addressData?.b_addressType?.value || ""}
                  onChange={(e: any) =>
                    setData("b_addressType", e.target.value)
                  }
                  error={
                    isFormValidated && addrDataError["b_addressType"]
                      ? addrDataError["b_addressType"]
                      : null
                  }
                  value={types}
                ></SelectGropus>
                <InputGroups
                  value={addressData?.b_avenue?.value || ""}
                  onChange={(e: any) => setData("b_avenue", e.target.value)}
                  error={
                    isFormValidated && addrDataError["b_avenue"]
                      ? addrDataError["b_avenue"]
                      : null
                  }
                  type={"text"}
                  label={"Avenue"}
                ></InputGroups>
                <InputGroups
                  value={addressData?.b_block?.value || ""}
                  onChange={(e: any) => setData("b_block", e.target.value)}
                  error={
                    isFormValidated && addrDataError["b_block"]
                      ? addrDataError["b_block"]
                      : null
                  }
                  type={"text"}
                  label={"Block"}
                ></InputGroups>
                <InputGroups
                  value={addressData?.b_street?.value || ""}
                  onChange={(e: any) => setData("b_street", e.target.value)}
                  error={
                    isFormValidated && addrDataError["b_street"]
                      ? addrDataError["b_street"]
                      : null
                  }
                  type={"text"}
                  label={"Street"}
                ></InputGroups>

                <InputGroups
                  value={addressData?.b_building?.value || ""}
                  onChange={(e: any) => setData("b_building", e.target.value)}
                  error={
                    isFormValidated && addrDataError["b_building"]
                      ? addrDataError["b_building"]
                      : null
                  }
                  type={"text"}
                  label={"Building"}
                ></InputGroups>
                <InputGroups
                  value={addressData?.b_customer_area?.value}
                  onChange={(e: any) =>
                    setData("b_customer_area", e.target.value)
                  }
                  error={
                    isFormValidated && addrDataError["b_customer_area"]
                      ? addrDataError["b_customer_area"]
                      : null
                  }
                  type={"text"}
                  label={"Area"}
                ></InputGroups>
                <div className="half">
                  {addressData?.b_addressType?.value == "Apartment" ? (
                    <div className="half">
                      <InputGroups
                        value={addressData?.b_floor?.value}
                        onChange={(e: any) =>
                          setData("b_floor", e.target.value)
                        }
                        error={
                          isFormValidated && addrDataError["b_floor"]
                            ? addrDataError["b_floor"]
                            : null
                        }
                        type={"text"}
                        label={"Floor"}
                      ></InputGroups>
                      <InputGroups
                        value={addressData?.b_apartment_number?.value || ""}
                        onChange={(e: any) =>
                          setData("b_apartment_number", e.target.value)
                        }
                        error={
                          isFormValidated && addrDataError["b_apartment_number"]
                            ? addrDataError["b_apartment_number"]
                            : null
                        }
                        type={"text"}
                        label={"Apartment number"}
                      ></InputGroups>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </form>
        <div className="action-bar">
          <CustomButton onClick={(e: any) => submitHandler(e)} bg="fill">
            {popupType == "add" ? "Add Address" : "UPDATE"}
          </CustomButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CkAddressPopup;
