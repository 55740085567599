export const ADD_REMOVE_CART_REQUEST = "ADD_REMOVE_CART_REQUEST";
export const ADD_REMOVE_CART_SUCCESS = "ADD_REMOVE_CART_SUCCESS";
export const ADD_REMOVE_CART_FAILD = "ADD_REMOVE_CART_FAILD";
export const ADD_REMOVE_CART_LONG = "ADD_REMOVE_CART_LONG";
export const ADD_REMOVE_CART_CLEAR = "ADD_REMOVE_CART_CLEAR";


export const MYCART_REQUEST = "MYCART_REQUEST";
export const MYCART_SUCCESS = "MYCART_SUCCESS";
export const MYCART_FAILD = "MYCART_FAILD";
export const MYCART_CLEAR = "MYCART_CLEAR";
export const MYCART_LONG = "MYCART_LONG";
export const MYCART_NO_DATA = "MYCART_NO_DATA";
export const MYCART_GLOBAL = "MYCART_GLOBAL";