import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../../../Constant/Status";
import { changePasswordAction } from "../../../../Redux/Account/AccountAction";
import { CHANGE_PASSWORD_DATA_FAILD, CHANGE_PASSWORD_DATA_LONG, CHANGE_PASSWORD_DATA_REQUEST, CHANGE_PASSWORD_DATA_SUCCESS } from "../../../../Redux/Account/AccountType";
import { isLoading } from "../../../../Utility/General";
import validate from "../../../../Utility/Validation";
import CustomButton from "../../../Common/CustomButton/CustomButton";
import InputGroups from "../../../Common/InputGroups/InputGroups";
import PasswordGroups from "../../../Common/PasswordGroups/PasswordGroups";

const PDataNamePopup = (props: any) => {
  const dispatch = useDispatch();
  const INITIAL_PASSWORD_DATA: any = {
    oldpassword: {
      value: "",
      validation: ["required"],
      errors: ["Old Password is required."],
    },
    password: {
      value: "",
      validation: ["required"],
      errors: ["New Password is required."],
    },
    conformpassword: {
      value: "",
      validation: ["required", "sameAs:password"],
      errors: ["Confirm Password is required.", "Password not match."],
    },
  };

  const [passwordData, setPasswordData] = useState(INITIAL_PASSWORD_DATA);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [passwordDataError, setPasswordDataError] = useState(demo);

  const setData = (field: string, value: string) => {
    let passwordDetails = Object.assign({}, passwordData);
    passwordDetails[field].value = value;
    setPasswordData({ ...passwordDetails });
  };
  useEffect(() => {
    setPasswordDataError({ ...validate(passwordData)?.errors });
  }, [passwordData]);

  const submitHandler = (e: any) => {
    e.preventDefault();
    if (validate(passwordData).isValidated === true) {
      dispatch(
        changePasswordAction({
          oldPassword: passwordData.oldpassword.value,
          password: passwordData.password.value,
          conformpassword: passwordData.conformpassword.value,
        })
      );
    } else {
      setPasswordDataError({ ...validate(passwordData)?.errors });
      setIsFormValidated(true);
    }
  };

  useSelector((state: any) => {
    if (state?.changePassword?.type === CHANGE_PASSWORD_DATA_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.changePassword?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.changePassword?.type === CHANGE_PASSWORD_DATA_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        props.onHide();
      }
    } else if (state?.changePassword?.type === CHANGE_PASSWORD_DATA_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.changePassword?.type === CHANGE_PASSWORD_DATA_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  return (
    <Modal {...props} centered className="pdatapwd-popup">
      <Modal.Header closeButton>
        <h5 className="m-head">Password</h5>
      </Modal.Header>
      <Modal.Body>
        <form className="personaldata-form">
          <PasswordGroups
            label={"Password"}
            value={passwordData?.oldpassword?.value}
            onChange={(e: any) => setData("oldpassword", e.target.value)}
            error={
                isFormValidated && passwordDataError["oldpassword"]
                  ? passwordDataError["oldpassword"]
                  : null
              }
          ></PasswordGroups>
          <PasswordGroups
            label={"New password (8 characters min)"}
            value={passwordData?.password?.value}
            onChange={(e: any) => setData("password", e.target.value)}
            error={
                isFormValidated && passwordDataError["password"]
                  ? passwordDataError["password"]
                  : null
              }
          ></PasswordGroups>
          <PasswordGroups
            label={"Repeat new password"}
            value={passwordData?.conformpassword?.value}
            onChange={(e: any) => setData("conformpassword", e.target.value)}
            error={
                isFormValidated && passwordDataError["conformpassword"]
                  ? passwordDataError["conformpassword"]
                  : null
              }
          ></PasswordGroups>
        </form>
      </Modal.Body>
      <div className="action-bar">
        <CustomButton
          bg="fill"
          isLoading={isLoading(apiStatus)}
          disabled={isLoading(apiStatus)}
          onClick={(e: any) => submitHandler(e)}
        >
          Update
        </CustomButton>
      </div>
    </Modal>
  );
};
export default PDataNamePopup;
