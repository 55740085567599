import { Col, Container, Row } from "react-bootstrap";
import filoimg1 from "../../Assets/img/filosophy1.svg";
import filoimg2 from "../../Assets/img/filosophy2.svg";
import filoimg3 from "../../Assets/img/filosophy3.svg";
import abtbannerDesk from "../../Assets/img/banner-aboutpural.jpg";
import abtbannershapeDesk from "../../Assets/img/shape-aboutpural.svg";
import abtbannerMob from "../../Assets/img/mbanner-aboutpural.jpg";
import abtbannershapeMob from "../../Assets/img/mshape-aboutpural.svg";
import commaIcon from "../../Assets/img/comma.svg";
import AboutImg1 from "../../Assets/img/about-img-1.png";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { API_STATUS } from "../../Constant/Api";
import { getCmsPage } from "../../Redux/CmsPages/CmsPagesAction";
import {
  CMS_FAILD,
  CMS_LONG,
  CMS_REQUEST,
  CMS_SUCCESS,
} from "../../Redux/CmsPages/CmsPagesType";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import Loader from "../Loader/Loader";
import CustomButton from "../Common/CustomButton/CustomButton";
import { useNavigate } from "react-router-dom";
import { PRODUCT_LISTING } from "../../Constant/Route";

const AboutPural = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  // const [pageTitle, setPageTitle] = useState('');
  const cmsPage = useSelector((state: any) => state?.cms?.data?.component[0]);
  // console.log(cmsPage);
  useEffect(() => {
    dispatch(getCmsPage({ urlKey: "about-us" }));
  }, []);

  useSelector((state: any) => {
    if (state?.cms?.type === CMS_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.cms?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.cms?.type === CMS_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.cms?.type === CMS_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.cms?.type === CMS_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });
  const fpoints = [
    {
      img: filoimg1,
      title: "BABY CARES ipsum dolor sit consei cotateur in order tol",
      msg: "Lorem ipsum dolor sit amet, consecteteur ma adipiscing elit sedor.",
    },
    {
      img: filoimg2,
      title: "NATURAL PRODUCTS dolor sit consei cotateur in order tol",
      msg: "Lorem ipsum dolor sit amet, consecteteur ma adipiscing elit sedor.",
    },
    {
      img: filoimg3,
      title: "Lorem ipsum dolor sit consei cotateur in order tol",
      msg: "Lorem ipsum dolor sit amet, consecteteur ma adipiscing elit sedor.",
    },
  ];

  const accBreadcrumb = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "/about",
      label: "About Pural",
    },
  ];
  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <div className="abtpural-wrap">
          {cmsPage?.data?.content?.map((comp: any, index: number) => {
            if (comp.componentId == "bannerComponent") {
              return (
                <>
                  <div className="cmsbanner-wrap desktop show-768">
                    <div className="cmsbanner-img">
                      <img
                        src={comp?.bannerComponentData?.banner_image}
                        alt="Banner Image"
                      ></img>
                    </div>
                    <div className="cmsbanner-inwrap">
                      <Container>
                        <div className="cmsbanner-content">
                          <img src={abtbannershapeDesk} alt="Vector"></img>
                          <div className="cmsshape">
                            <Breadcrumbs value={accBreadcrumb}></Breadcrumbs>
                            <h1>{comp.bannerComponentData.title}</h1>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </div>
                  <div className="cmsbanner-wrap mobile hide-767">
                    <div className="cmsbanner-img">
                      <img
                        src={comp?.bannerComponentData?.mobile_banner_image}
                        alt="Banner Image"
                      ></img>
                      <Breadcrumbs value={accBreadcrumb}></Breadcrumbs>
                    </div>
                    <div className="cmsbanner-inwrap">
                      <div className="cmsbanner-content">
                        <img src={abtbannershapeMob} alt="Vector"></img>
                        <div className="abtshape">
                          <h1>{cmsPage?.data?.title}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            } else if (comp.componentId == "mainComponent") {
              return (
                <Container>
                  <div className="info-wrap">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: comp?.mainComponentData?.editor,
                      }}
                    ></div>
                    {/* <h2>Kicking nappy rash to the curb!</h2>
                  <h3>
                    How many times have you dreaded changing your baby’s nappy?
                  </h3>
                  <p>
                    Pulling open the sticky tabs and glimpsing the angry red
                    skin straight away.
                  </p>
                  <br />
                  <p>
                    Blotches, bumps, rashes, soreness, and a baby in so much
                    discomfort.
                  </p>
                  <br />
                  <p>
                    Watching your baby in pain is the worst thing about being a
                    parent.
                  </p>
                  <br />
                  <p>You’ve tried every nappy, every cream.</p>
                  <br />
                  <p>
                    You’ve spent hours sitting them in the bath or covering the
                    house in towels so you can give them nappy-free time.
                  </p>
                  <br />
                  <p>You’ve been on every message board. But nothing works</p>
                  <br />
                  <p>Surely there must be a better way?</p>
                  <br />
                  <br />

                  <h2>We’re here to help.</h2>
                  <img src={AboutImg1} alt="" />
                  <h3>
                    <b>We know that you want the very best for your baby.</b>
                  </h3>
                  <p>
                    Which is why you spend ages standing in the nappy aisle of
                    the supermarket trying to decide whether this brand will
                    bring your daughter out in a rash, or whether that one is
                    full of harmful dyes that will spark your son’s allergies.
                  </p>
                  <br />
                  <br />
                  <h3>
                    <b>Pural cares about your baby’s bottom.</b>
                  </h3>
                  <p>
                    And we’ve designed our nappies to keep it as happy as
                    possible! Clinically tested, and certified by Allergy UK;
                    our nappys keep your baby’s delicate skin free from
                    irritation. Banishing nappy rash from your life.
                  </p>
                  <br />
                  <p>
                    While our <a>Pural Club membership</a> keeps your costs
                    down.
                  </p> */}
                  </div>
                </Container>
              );
            } else if (comp.componentId == "sectionThirdComponent") {
              return (
                <div className="filosophy-wrap">
                  <Container>
                    <div className="filo-innerwrap">
                      <h1>{comp?.sectionThirdComponentData?.main_title}</h1>
                      <Row>
                        {comp?.sectionThirdComponentData?.data.map((data:any, index:number) => (
                          <React.Fragment key={index + "footer"}>
                            <Col
                              xl={4}
                              lg={4}
                              md={4}
                              sm={12}
                              xs={12}
                              className="filo-points"
                            >
                              <div className="for-img">
                                <img src={data.image} alt="Filosophy Image"></img>
                              </div>
                              <div className="for-desc">
                                <h4>{data.title}</h4>
                                <p>{data.sub_title}</p>
                              </div>
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                    </div>
                  </Container>
                </div>
              );
            } else if (comp.componentId == "sectionFourthComponent") {
              return (
                <div className="pural-mappies-section-2">
                  <Container>
                    <img src={commaIcon} alt="" />
                    <h4>{comp?.sectionFourthComponentData?.title}</h4>
                  </Container>
                </div>
              );
            } else if (comp.componentId == "sectionFifthComponent") {
              return (
                <div className="allery-safe-section">
                  <Container>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: comp?.sectionFifthComponentData?.editor,
                      }}
                    ></div>
                    {/* <label>
                      You’re busy. You don’t have time to wait and test and shop
                      around.
                    </label>
                    <h4>
                      You need premium quality nappies, made from certified
                      allergy-safe materials, delivered straight to your door.
                    </h4>
                    <p>And that’s what you get from Pural.</p> */}
                    <CustomButton
                      bg="fill"
                      onClick={() => navigate(PRODUCT_LISTING)}
                    >
                      {comp?.sectionFifthComponentData?.button_title}
                    </CustomButton>
                  </Container>
                </div>
              );
            }
          })}
          {/* <Container>
            <div
              dangerouslySetInnerHTML={{ __html: cmsPage?.data?.content }}
            ></div>
          </Container> */}
        </div>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    // return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
};

export default AboutPural;
