import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import TinySlider from "tiny-slider-react";
// import OwlCarousel from "react-owl-carousel";
// import Productimg1 from "../../Assets/img/ProductImage1.png";
// import Productimg2 from "../../Assets/img/ProductImage2.png";
// import Productimg3 from "../../Assets/img/ProductImage3.png";
import MemberPrice from "../../Assets/img/member-price.svg";
import SelectGropus from "../Common/SelectGroups/SelectGroups";
import IncDecBox from "../Common/IncDecBox/IncDecBox";
import CustomButton from "../Common/CustomButton/CustomButton";
// import productPoints1 from "../../Assets/img/productPoints1.svg";
// import productPoints2 from "../../Assets/img/productPoints2.svg";
// import productPoints3 from "../../Assets/img/productPoints3.svg";
// import productPoints4 from "../../Assets/img/productPoints4.svg";
import Product1 from "../../Assets/img/product_1.png";
import Product2 from "../../Assets/img/product_2.png";
import Product3 from "../../Assets/img/product_3.png";
import SubscriptionPopup from "./SubscriptionPopup/SubscriptionPopup";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import { useParams } from "react-router-dom";
import { generateQuery } from "../../Utility/General";
import { useDispatch, useSelector } from "react-redux";
import {
  productDetailAction,
  productDetailVariantAction,
} from "../../Redux/Product/ProductAction";
import {
  PRODUCT_DETAIL_FAILD,
  PRODUCT_DETAIL_LONG,
  PRODUCT_DETAIL_REQUEST,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_VARIENT_FAILD,
  PRODUCT_VARIENT_LONG,
  PRODUCT_VARIENT_REQUEST,
  PRODUCT_VARIENT_SUCCESS,
} from "../../Redux/Product/ProductType";
import { API_STATUS } from "../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import Loader from "../Loader/Loader";
import { addToCartAction } from "../../Redux/Cart/CartAction";
import { CONFIGURABLEDATA, QUOTE_ID } from "../../Constant/LocalStorage";
import {
  ADD_REMOVE_CART_FAILD,
  ADD_REMOVE_CART_LONG,
  ADD_REMOVE_CART_REQUEST,
  ADD_REMOVE_CART_SUCCESS,
} from "../../Redux/Cart/CartType";
import SomethingWrong from "../SomethingWrong/SomethingWrong";
import { isLoading } from "../../Utility/General";

const DetailPage = () => {
  const breadsData = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "/detail",
      label: "Diapers",
    },
  ];

  // const ItemImgSlider = {
  //   autoplay: false,
  //   loop: true,
  //   dots: true,
  //   nav: false,
  //   responsiveClass: true,
  //   responsive: {
  //     0: {
  //       items: 1,
  //       margin: 0,
  //     },
  //   },
  // };

  const TinyItemImgSlider = {
    mouseDrag: true,
    loop: false,
    controls: false,
    controlsText: ["", ""],
    responsive: {
      0: {
        items: 1,
      },
    },
  };

  // const PointsSlider = {
  //   responsiveClass: true,
  //   autoplay: false,
  //   loop: true,
  //   dots: false,
  //   responsive: {
  //     0: {
  //       items: 2.5,
  //       margin: 8,
  //     },
  //     768: {
  //       items: 3.5,
  //       margin: 40,
  //     },
  //     992: {
  //       items: 4,
  //       margin: 40,
  //     },
  //   },
  // };

  const TinyPointsSlider = {
    mouseDrag: true,
    loop: false,
    nav: false,
    controls: false,
    responsive: {
      0: {
        items: 2.5,
        gutter: 8,
        autoWidth: true,
      },
      768: {
        items: 3.5,
        gutter: 20,
        autoWidth: false,
        // autoWidth: true,
      },
      992: {
        items: 4,
        gutter: 40,
      },
    },
  };

  const detailTableData = [
    {
      size: "New born",
      weight: "Up to 5 Kg",
      qty: "30",
    },
    {
      size: "Small",
      weight: "3 - 6 Kg",
      qty: "68",
    },
    {
      size: "Medium",
      weight: "6 - 11 Kg",
      qty: "60",
    },
    {
      size: "Large",
      weight: "10 - 14 Kg",
      qty: "52",
    },
  ];

  const [quoteId, setQuoteId] = useState(
    localStorage.getItem(QUOTE_ID) ? localStorage.getItem(QUOTE_ID) : ""
  );
  const [subscriptionModalShow, setsubscriptionModalShow] = useState(false);
  const [productDetail, setProductDetail] = useState<any>([]);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [variantApiStatus, setVariantApiStatus] = useState(API_STATUS.SUCCESS);
  const [addToCartStatus, setAddToCartStatus] = useState(API_STATUS.SUCCESS);

  // console.log(productDetail);

  const urlParams = useParams();
  const dispatch = useDispatch();
  const productDetailData = useSelector(
    (state: any) => state.productDetailReducer.data
  );
  const selectVarient = useSelector(
    (state: any) => state?.selectedVarient?.data
  );

  const INITIAL_CART_DATA = {
    quoteId: quoteId,
    productQuery: [
      {
        cartFlag: 1,
        itemId: "",
        productId: "",
        urlKey: urlParams.slug,
        qty: 1,
        varientQuery: [{ attributeId: "", optionId: "" }],
      },
    ],
  };
  const [addCartData, setAddCartData] = useState(INITIAL_CART_DATA);
  const [isValidate, setIsValidate] = useState(true);
  const [isVariable, setIsVariable] = useState(false);
  const [selectVariantError, setSelectVariantError] = useState<any>();

  const selectVariant = (attributeId: any, value: any) => {
    if (value != "0") {
      setAddCartData({
        ...addCartData,
        productQuery: [
          {
            ...addCartData.productQuery[0],
            varientQuery: [{ attributeId: attributeId, optionId: value }],
          },
        ],
      });
      let variantQuery = {
        urlKey: urlParams.slug,
        varientQuery: [
          {
            attributeId: attributeId,
            optionId: value,
          },
        ],
      };
      // localStorage.setItem(CONFIGURABLEDATA, JSON.stringify(variantQuery));
      dispatch(productDetailVariantAction(variantQuery));
      setIsValidate(true);
    } else {
      setIsValidate(false);
      setSelectVariantError("Please select the Size");
    }
  };
  const addToCart = () => {
    if (isValidate) {
      dispatch(addToCartAction(addCartData));
    } else {
      setSelectVariantError("Please select the Size");
    }
  };
  useEffect(() => {
    let urlKey = urlParams.slug;
    if (urlKey) {
      dispatch(productDetailAction(generateQuery({ urlKey: urlKey })));
    }
  }, [urlParams.slug]);

  useEffect(() => {
    let productDetailRaw: any = {};
    productDetailData?.component?.forEach((component: any) => {
      productDetailRaw[component?.componentId] = component;
      if (component?.componentId == "attributes") {
        setIsValidate(false);
        setIsVariable(true);
      }
    });
    // console.log(productDetailRaw);
    setProductDetail({ ...productDetailRaw });
  }, [productDetailData]);

  useEffect(() => {
    if (isVariable) {
      selectVariant(
        productDetail["attributes"]?.attributesData?.list[0]?.attributeId,
        productDetail["attributes"]?.attributesData?.list[0]?.options[0]
          .optionId
      );
    }
  }, [isVariable, productDetail["attributes"]?.attributesData?.list[0]]);

  useEffect(() => {
    selectVarient?.component?.forEach((component: any) => {
      if (
        component?.componentId == "productDetailsComponent" &&
        productDetail &&
        productDetail["productData"] &&
        productDetail["productData"]["productData"]
      ) {
        productDetail["productData"]["productData"] =
          component?.productDetailsComponentData;
        if (component?.productStock) {
          productDetail["productData"]["productData"]["productStock"] =
            component?.productStock;
        }
      }
    });
    setProductDetail({ ...productDetail });
  }, [selectVarient]);

  useSelector((state: any) => {
    if (state?.productDetailReducer?.type === PRODUCT_DETAIL_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.productDetailReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.productDetailReducer?.type === PRODUCT_DETAIL_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        // let data = localStorage.getItem(CONFIGURABLEDATA)
        //   ? localStorage.getItem(CONFIGURABLEDATA)
        //   : null;
        // if (data) {
        //   let jsonData = JSON.parse(data);
        //   console.log(jsonData);
        //   if (urlParams.slug === jsonData.urlKey) {
        //     // console.log('hereeeeeeee')
        //     setAddCartData({
        //       ...addCartData,
        //       productQuery: [
        //         {
        //           ...addCartData.productQuery[0],
        //           varientQuery: jsonData.varientQuery,
        //         },
        //       ],
        //     });
        //     // dispatch(productDetailVariantAction(jsonData));
        //   }
        // }
      }
    } else if (state?.productDetailReducer?.type === PRODUCT_DETAIL_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.productDetailReducer?.type === PRODUCT_DETAIL_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }

    if (state?.selectedVarient?.type === PRODUCT_VARIENT_REQUEST) {
      if (variantApiStatus !== API_STATUS.LOADING) {
        setVariantApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.selectedVarient?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.selectedVarient?.type === PRODUCT_VARIENT_SUCCESS
    ) {
      if (variantApiStatus !== API_STATUS.SUCCESS) {
        setVariantApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.selectedVarient?.type === PRODUCT_VARIENT_LONG) {
      if (variantApiStatus !== API_STATUS.LONG) {
        setVariantApiStatus(API_STATUS.LONG);
      }
    } else if (state?.selectedVarient?.type === PRODUCT_VARIENT_FAILD) {
      if (variantApiStatus !== API_STATUS.FAILED) {
        setVariantApiStatus(API_STATUS.FAILED);
      }
    }

    if (state?.addToCartReducer?.type === ADD_REMOVE_CART_REQUEST) {
      if (addToCartStatus !== API_STATUS.LOADING) {
        setAddToCartStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.addToCartReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.addToCartReducer?.type === ADD_REMOVE_CART_SUCCESS
    ) {
      if (addToCartStatus !== API_STATUS.SUCCESS) {
        setAddToCartStatus(API_STATUS.SUCCESS);
      }
      //if (!quoteId) {
      //  // setQuoteId(quoteId);
      //  setAddCartData({
      //    ...addCartData,
      //    quoteId: quoteId,
      //  });
      //}
    } else if (state?.addToCartReducer?.type === ADD_REMOVE_CART_LONG) {
      if (addToCartStatus !== API_STATUS.LONG) {
        setAddToCartStatus(API_STATUS.LONG);
      }
    } else if (state?.addToCartReducer?.type === ADD_REMOVE_CART_FAILD) {
      if (addToCartStatus !== API_STATUS.FAILED) {
        setAddToCartStatus(API_STATUS.FAILED);
      }
    }
  });

  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return variantApiStatus === API_STATUS.LOADING ? (
        <Loader></Loader>
      ) : (
        <>
          <div className="product-detail">
            <Container>
              <div className="product-detail-wrap">
                <div className="breadcrumb-top hide-767">
                  <Breadcrumbs value={breadsData}></Breadcrumbs>
                </div>
                <div className="details-wrap">
                  <div className="for-img">
                    {/* {productDetail["productImage"]?.productImage?.length > 0 ? (
                      <OwlCarousel className="owl-theme" {...ItemImgSlider}>
                        {productDetail["productImage"]?.productImage.map(
                          (data: any, index: number) => (
                            <div
                              className="item"
                              key={index + "details-for-img"}
                            >
                              <img src={data.link} alt="Nappies" />
                            </div>
                          )
                        )}
                      </OwlCarousel>
                    ) : null} */}
                    {productDetail["productImage"]?.productImage?.length > 0 ? (
                      <TinySlider settings={TinyItemImgSlider}>
                        {productDetail["productImage"]?.productImage.map(
                          (data: any, index: number) => (
                            <div key={index + "details-for-img"}>
                              <img src={data.link} alt="Nappies" />
                            </div>
                          )
                        )}
                      </TinySlider>
                    ) : null}
                  </div>
                  <div className="for-desc">
                    <div className="desc-wrap">
                      <div className="product-name">
                        <h3>
                          {productDetail["productData"]?.productData?.title}
                        </h3>
                      </div>
                      <div className="product-info">
                        <div className="price-wrap">
                          <p>
                            {
                              productDetail["productData"]?.productData
                                ?.actualPrice
                            }
                          </p>
                        </div>
                        {productDetail["productData"]?.productData
                          ?.discountedPrice ? (
                          <div className="member-label">
                            <span>
                              {
                                productDetail["productData"]?.productData
                                  ?.discountedPrice
                              }{" "}
                              member price
                            </span>
                            <img
                              src={MemberPrice}
                              alt="Member Price"
                              onClick={() => setsubscriptionModalShow(true)}
                            ></img>
                          </div>
                        ) : null}
                      </div>
                      <div className="about-prod">
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              productDetail["productDescription"]
                                ?.productDescriptionData?.shortDescription,
                          }}
                        ></div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              productDetail["productDescription"]
                                ?.productDescriptionData?.description,
                          }}
                        ></div>
                        {/* <p>
                          Our diapers absorve more than 15 times its weight and
                          offers a 12 hours protection. Each pack has different
                          number of nappies:
                        </p> */}
                        {/* <ul>
                          {attributes.map((data: any, index: number) => (
                            <li key={index + "about-prod"}>
                              <span>{data.name}</span> size pack: {data.no}{" "}
                              nappies
                            </li>
                          ))}
                        </ul> */}
                      </div>
                      <div className="product-action">
                        {productDetail["attributes"]?.attributesData?.list
                          ?.length ? (
                          <div className="size-dropdown">
                            {productDetail[
                              "attributes"
                            ]?.attributesData?.list?.map(
                              (item: any, index2: number) => {
                                if (item.type === "SS") {
                                  let sizes: any = [
                                    // { id: 0, name: "Select Size" },
                                  ];
                                  item?.options.map(
                                    (items: any, index: number) =>
                                      // console.log(items)
                                      sizes.push({
                                        id: items.optionId,
                                        name: items.value,
                                      })
                                  );
                                  return (
                                    <SelectGropus
                                      key={item.attributeId + index2}
                                      defaultSelect={
                                        addCartData.productQuery[0]
                                          .varientQuery[0].optionId
                                      }
                                      onChange={(e: any) =>
                                        selectVariant(
                                          item.attributeId,
                                          e.target.value
                                        )
                                      }
                                      value={sizes}
                                    ></SelectGropus>
                                  );
                                }
                              }
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="inc-decbox">
                          <IncDecBox
                            maxVal={
                              productDetail["productData"]?.productData
                                ?.productStock
                            }
                            value={addCartData.productQuery[0].qty}
                            onChange={(value: any) => {
                              setAddCartData({
                                ...addCartData,
                                productQuery: [
                                  {
                                    ...addCartData.productQuery[0],
                                    qty: value,
                                  },
                                ],
                              });
                            }}
                          ></IncDecBox>
                        </div>
                      </div>
                      {!isValidate ? (
                        <label className="add-to-cart">
                          {selectVariantError}
                        </label>
                      ) : null}

                      <div className="action-bar">
                        {productDetail["productData"]?.productData
                          ?.productStock != "0" ? (
                          <CustomButton
                            bg="fill"
                            // onClick={() => setsubscriptionModalShow(true)}
                            onClick={() => addToCart()}
                            isLoading={isLoading(addToCartStatus)}
                            disabled={isLoading(addToCartStatus)}
                          >
                            Add To Bag
                          </CustomButton>
                        ) : (
                          <CustomButton disabled={true} bg="fill">
                            Out Of Stock
                          </CustomButton>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
            <div className="detail-tables">
              <Container>
                <table>
                  <thead>
                    <tr>
                      <th>Size</th>
                      <th>Weight</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productDetail["DynamicRow"]?.dynamicRowComponent?.data.map((items: any, index: number) => (
                      <tr key={index + Math.random() + "tabledata"}>
                        <td>{items.size}</td>
                        <td>{items.weight}</td>
                        <td>{items.quantity}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Container>
            </div>
            <div className={`take-look-section ${productDetail["ProductFeatures"]?.color != false ? (productDetail["ProductFeatures"]?.color + "-bg") : "green-bg"}`}>
              <Container>
                <h4>{productDetail["ProductFeatures"]?.title}</h4>
                {
                  productDetail["ProductFeatures"]?.productFeaturesRowComponent.data.map((items: any, index: number) => (
                    <div className="img-content" key={index + 'static-data'}>
                      <img src={items?.image} alt="" />
                      <p>{items?.text}</p>
                    </div>
                  ))
                }
                {/* <div className="img-content">
                  <img src={Product1} alt="" />
                  <p>Comfortable stretchy waistbands</p>
                </div>
                <div className="img-content">
                  <img src={Product2} alt="" />
                  <p>12 hour leak lock protection</p>
                </div>
                <div className="img-content">
                  <img src={Product3} alt="" />
                  <p>Absorb more than 15x their weight</p>
                </div> */}
              </Container>
            </div>
            <div className="product-points">
              <div className="in-point">
                {/* {productDetail["productFeature"]?.productFeatureData?.data
                  ?.length > 0 ? (
                  <OwlCarousel className="owl-theme" {...PointsSlider}>
                    {productDetail[
                      "productFeature"
                    ]?.productFeatureData?.data.map(
                      (data: any, index: number) => (
                        <div
                          className="point-wrap"
                          key={index + "product-points"}
                        >
                          <div className="for-img">
                            <img
                              src={data.attachment}
                              alt="Product Point"
                            ></img>
                          </div>
                          <div className="for-desc">
                            <h4>{data.title}</h4>
                          </div>
                        </div>
                      )
                    )}
                  </OwlCarousel>
                ) : null} */}

                {productDetail["productFeature"]?.productFeatureData?.data
                  ?.length > 0 ? (
                  <TinySlider settings={TinyPointsSlider}>
                    {productDetail[
                      "productFeature"
                    ]?.productFeatureData?.data.map(
                      (data: any, index: number) => (
                        <div key={index + "product-points"}>
                          <div className="point-wrap">
                            <div className="for-img">
                              <img
                                src={data.attachment}
                                alt="Product Point"
                              ></img>
                            </div>
                            <div className="for-desc">
                              <h4>{data.title}</h4>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </TinySlider>
                ) : null}
              </div>
            </div>
          </div>

          <SubscriptionPopup
            show={subscriptionModalShow}
            onHide={() => setsubscriptionModalShow(false)}
          ></SubscriptionPopup>
        </>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
      return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
};

export default DetailPage;
