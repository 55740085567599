import { END_POINTS, MAX_CALLS } from "../../Constant/Api";
import { QUOTE_ID } from "../../Constant/LocalStorage";
import {
  NO_DATA_ERROR_CODE,
  SUCCESS_RESPONSE_CODE,
} from "../../Constant/Status";
import api from "../../Service/Axios";
import { showToast } from "../../Utility/General";
import { GET_CHECKOUT_FAILD, GET_CHECKOUT_LONG, GET_CHECKOUT_REQUEST, GET_CHECKOUT_SUCCESS, PAYMENT_CONFIRM_FAILD, PAYMENT_CONFIRM_LONG, PAYMENT_CONFIRM_REQUEST, PAYMENT_CONFIRM_SUCCESS, PLACE_ORDER_FAILD, PLACE_ORDER_LONG, PLACE_ORDER_REQUEST, PLACE_ORDER_SUCCESS, UPDATE_CHECKOUT_FAILD, UPDATE_CHECKOUT_LONG, UPDATE_CHECKOUT_REQUEST, UPDATE_CHECKOUT_SUCCESS, QUOTE_UPDATE_ADDRESS_REQUEST, QUOTE_UPDATE_ADDRESS_SUCCESS,QUOTE_UPDATE_ADDRESS_FAILD, QUOTE_UPDATE_ADDRESS_LONG } from "./CheckoutType";

export function getCheckoutAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: GET_CHECKOUT_REQUEST,
      payload: { type: GET_CHECKOUT_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.getCheckout}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            // showToast("success", result.data.message);
            dispatch({
              type: GET_CHECKOUT_SUCCESS,
              payload: { data: result.data, type: GET_CHECKOUT_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: GET_CHECKOUT_LONG,
              payload: { data: {}, type: GET_CHECKOUT_LONG },
            });
          } else {
            dispatch({
              type: GET_CHECKOUT_FAILD,
              payload: { data: {}, type: GET_CHECKOUT_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: GET_CHECKOUT_LONG,
              payload: { data: {}, type: GET_CHECKOUT_LONG },
            });
          } else {
            dispatch({
              type: GET_CHECKOUT_FAILD,
              payload: { data: {}, type: GET_CHECKOUT_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function updateCheckoutAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_CHECKOUT_REQUEST,
      payload: { type: UPDATE_CHECKOUT_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.updateCheckout}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            if(result.data.showMessage === "1"){
              showToast("success", result.data.message);
            }
            dispatch({
              type: UPDATE_CHECKOUT_SUCCESS,
              payload: { data: result.data, type: UPDATE_CHECKOUT_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: UPDATE_CHECKOUT_LONG,
              payload: { data: {}, type: UPDATE_CHECKOUT_LONG },
            });
          } else {
            dispatch({
              type: UPDATE_CHECKOUT_FAILD,
              payload: { data: {}, type: UPDATE_CHECKOUT_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: UPDATE_CHECKOUT_LONG,
              payload: { data: {}, type: UPDATE_CHECKOUT_LONG },
            });
          } else {
            dispatch({
              type: UPDATE_CHECKOUT_FAILD,
              payload: { data: {}, type: UPDATE_CHECKOUT_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}


export function placeOrderAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: PLACE_ORDER_REQUEST,
      payload: { type: PLACE_ORDER_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.placeOrder}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            showToast("success", result.data.message);
            dispatch({
              type: PLACE_ORDER_SUCCESS,
              payload: { data: result.data, type: PLACE_ORDER_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PLACE_ORDER_LONG,
              payload: { data: {}, type: PLACE_ORDER_LONG },
            });
          } else {
            dispatch({
              type: PLACE_ORDER_FAILD,
              payload: { data: {}, type: PLACE_ORDER_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PLACE_ORDER_LONG,
              payload: { data: {}, type: PLACE_ORDER_LONG },
            });
          } else {
            dispatch({
              type: PLACE_ORDER_FAILD,
              payload: { data: {}, type: PLACE_ORDER_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function paymentConfirmAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: PAYMENT_CONFIRM_REQUEST,
      payload: { type: PAYMENT_CONFIRM_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.paymentSuccess}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            showToast("success", result.data.message);
            localStorage.setItem(QUOTE_ID, "");
            dispatch({
              type: PAYMENT_CONFIRM_SUCCESS,
              payload: { data: result.data, type: PAYMENT_CONFIRM_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PAYMENT_CONFIRM_LONG,
              payload: { data: {}, type: PAYMENT_CONFIRM_LONG },
            });
          } else {
            dispatch({
              type: PAYMENT_CONFIRM_FAILD,
              payload: { data: {}, type: PAYMENT_CONFIRM_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PAYMENT_CONFIRM_LONG,
              payload: { data: {}, type: PAYMENT_CONFIRM_LONG },
            });
          } else {
            dispatch({
              type: PAYMENT_CONFIRM_FAILD,
              payload: { data: {}, type: PAYMENT_CONFIRM_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}
export function quoteUpdateAddressAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: QUOTE_UPDATE_ADDRESS_REQUEST,
      payload: { type: QUOTE_UPDATE_ADDRESS_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.quoteUpdateAddress}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            showToast("success", result.data.message);
            dispatch({
              type: QUOTE_UPDATE_ADDRESS_SUCCESS,
              payload: { data: result.data, type: QUOTE_UPDATE_ADDRESS_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: QUOTE_UPDATE_ADDRESS_LONG,
              payload: { data: {}, type: QUOTE_UPDATE_ADDRESS_LONG },
            });
          } else {
            dispatch({
              type: QUOTE_UPDATE_ADDRESS_FAILD,
              payload: { data: {}, type: QUOTE_UPDATE_ADDRESS_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: QUOTE_UPDATE_ADDRESS_LONG,
              payload: { data: {}, type: QUOTE_UPDATE_ADDRESS_LONG },
            });
          } else {
            dispatch({
              type: QUOTE_UPDATE_ADDRESS_FAILD,
              payload: { data: {}, type: QUOTE_UPDATE_ADDRESS_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}
