const validate = (schema: any) => {

    const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var numberRegex = /^\d+$/;
    let data = schema
    let isValidated = true
    let obj: any = {}
    for (const d in data) {
        if (data[d].validation) {
            for (let i = 0; i < data[d].validation.length; i++) {
                // console.log(data[d].validation[i])
                if (data[d].validation[i] === "required" && (data[d].value === null || data[d].value === "")) {
                    obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                    isValidated = false
                }
                else if (data[d].validation[i] === "email" && !emailRegex.test(data[d].value)) {
                    obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"

                    isValidated = false
                }
                else if (data[d].validation[i] === "number" && !numberRegex.test(data[d].value)) {
                    obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                    isValidated = false
                }
                else if (data[d].validation[i].includes(":")) {
                    let type = data[d].validation[i].split(":")[0]
                    let range = data[d].validation[i].split(":")[1]
                    let exactVal = data[d].validation[i].split(":")[2]
                    
                    if (type === "min" && data[d].value?.length < range) {
                        obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                        isValidated = false
                    }
                    else if (type === "max" && data[d].value?.length > range) {
                        obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                        isValidated = false
                    }
                    else if (type === "len" && Number(data[d].value?.length) !== Number(range)) {
                        obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                        isValidated = false
                    }
                    else if (type === "requiredIf"  &&  (data[d].value === null || data[d].value === "" && ((exactVal&& data[range].value == exactVal) || (!exactVal && data[range].value==1)))) {
                        obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                        isValidated = false
                    }
                    else if (type === "sameAs") {
                        
                        let sameAs = range;
                        let check = 1;
                        if (range.includes(",")) {
                            sameAs = range.split(",")[0]
                            let checkKey = range.split(",")[1]
                            check = data[checkKey].value;
                        }
                        if (check == 1) {
                            if (data[d].value != data[sameAs].value) {
                                obj[d] = data[d].errors[i] ? data[d].errors[i] : "Error"
                                isValidated = false
                            }
                        }
                    }
                }
            }
        }
    }
    return { "errors": obj, "isValidated": isValidated }
}
export default validate;