import { combineReducers } from "redux";
import {
  addressData,
  addressUpdateData,
  changePassword,
  communication,
  getOrders,
  getSubscriptionReducer,
  // getYouScreenReducer,
  personalData,
  setCommunication,
  setPersonalData,
  invoiceReducer,
} from "./Account/AccountReducer";
import {
  forgotPassword,
  getLoginShowReducer,
  login,
  resetPassword,
  signup,
} from "./Auth/AuthReducer";
import { addToCartReducer, myCartGlobalReducer } from "./Cart/CartReducer";
import { getCheckoutReducer, paymentConfirmReducer, placeOrderReducer, updateCheckoutReducer, quoteUpdateAddressReducer } from "./Checkout/CheckoutReducer";
import { getClubPageReducer } from "./Club/ClubReducer";
import { cmsPage } from "./CmsPages/CmsPagesReducer";
import { contactUs, getContactUs } from "./ContactUs/ContactUsReducer";
import { faq } from "./FAQs/FAQsReducer";
import {
  cartCountGlobalReducer,
  defaultParameter,
  master,
  newsletterSubscription,
} from "./General/GeneralReducer";
import { home } from "./Home/HomeReducer";
import { productDetailPage, productDetailVarient, productListReducer } from "./Product/ProductReducer";

export default combineReducers({
  master: master,
  defaultParameter: defaultParameter,
  login: login,
  signup: signup,
  home: home,
  cms: cmsPage,
  contactUs: contactUs,
  getContactUs: getContactUs,
  forgotPassword: forgotPassword,
  resetPassword: resetPassword,
  changePassword: changePassword,
  // getYouScreenReducer: getYouScreenReducer,
  getCommunication: communication,
  setCommunication: setCommunication,
  getSubscriptionReducer: getSubscriptionReducer,
  getFaq: faq,
  getOrders: getOrders,
  personalData: personalData,
  setPersonalData: setPersonalData,
  address: addressData,
  addressUpdateData: addressUpdateData,
  newslatter: newsletterSubscription,
  productDetailReducer: productDetailPage,
  productListReducer:productListReducer,
  addToCartReducer: addToCartReducer,
  cartCountGlobalData: cartCountGlobalReducer,
  myCartGlobalData: myCartGlobalReducer,
  selectedVarient: productDetailVarient,
  getCheckoutReducer: getCheckoutReducer,
  updateCheckoutReducer: updateCheckoutReducer,
  placeOrderReducer: placeOrderReducer,
  paymentConfirmReducer: paymentConfirmReducer,
  quoteUpdateAddressReducer: quoteUpdateAddressReducer,
  getClubPageReducer: getClubPageReducer,
  getLoginShowReducer: getLoginShowReducer,
  invoiceReducer: invoiceReducer,
  // DEMO
  // servicePackageList: servicePackageReducer,
});
