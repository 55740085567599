// Created by @MIS1131 for routing directory
import React, { Component, Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import ScrollToTop from "../Constant/ScrollToTop";
import AboutPural from "../Component/AboutPural/AboutPural";
import AccountPages from "../Component/AccountPages/AccountPages";
import Addresses from "../Component/AccountPages/Addresses/Addresses";
import Orders from "../Component/AccountPages/Orders/Orders";
import PersonalData from "../Component/AccountPages/PersonalData/PersonalData";
import Subscription from "../Component/AccountPages/Subscription/Subscription";
import Checkout from "../Component/Checkout/Checkout";
import CheckoutHeader from "../Component/CheckoutHeader/CheckoutHeader";
import Club from "../Component/Club/Club";
import Typo from "../Component/Common/Typo/Typo";
import ContactUs from "../Component/ContactUs/ContactUs";
import DetailPage from "../Component/DetailPage/DetailPage";
import Footer from "../Component/Footer/Footer";
import DiscountBanner from "../Component/DiscountBanner/DiscountBanner";
import Header from "../Component/Header/Header";
import Home from "../Component/Home/Home";
import Loader from "../Component/Loader/Loader";
import OrderConfirmation from "../Component/OrderConfirmation/OrderConfirmation";
import CommunicationSettings from "../Component/AccountPages/CommunicationSettings/CommunicationSettings";
import ShippingReturns from "../Component/ShippingReturns/ShippingReturns";
import Faqs from "../Component/Faqs/Faqs";
import PrivacyPolicy from "../Component/PrivacyPolicy/PrivacyPolicy";
import TermsAndCondition from "../Component/TermsAndCondition/TermsAndCondition";
import SomethingWrong from "../Component/SomethingWrong/SomethingWrong";
import MobAccountPages from "../Component/AccountPages/MobAccountPages";
import AccountSideMenu from "../Component/AccountPages/AccountSideMenu/AccountSideMenu";
import Login from "../Component/Login/Login";
import ForgotPassword from "../Component/ForgotPassword/ForgotPassword";
import ForgotSuccess from "../Component/ForgotSuccess/ForgotSuccess";
import ResetPassword from "../Component/ResetPassword/ResetPassword";
import Signup from "../Component/Signup/Signup";
import CmsPage from "../Component/CmsPage/CmsPage";
import ResetSuccess from "../Component/ResetSuccess/ResetSuccess";
import ProductListing from "../Component/ProductListing/ProductListing";

import {
  ABOUT,
  ACCOUNT,
  CLUB,
  CONTACT,
  DETAILPAGE,
  HOME,
  ORDERS,
  TYPO,
  LOADER,
  SUBSCRIPTION,
  PERSONALDATA,
  ADRESSES,
  ORDERSUCCESS,
  MOBILEORDERS,
  CHECKOUT,
  COMMUNICATIONSETTINGS,
  SHIPPINGRETURNS,
  FAQS,
  PRIVACYPOLICY,
  TERMSANDCONDITION,
  SOMETHINGWRONG,
  LOGIN,
  FORGOTPASSWORD,
  FORGOTSUCCESS,
  RESETPASSWORD,
  SIGNUP,
  RESETSUCCESS,
  PRODUCT_DETAIL,
  PRODUCT_LISTING,
} from "../Constant/Route";
import { Console } from "console";
import MainHeader from "../Component/MainHeader/MainHeader";
import ProtectedRoutes from "./ProtectedRoutes";

const Routesconfig = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  useEffect(() => {
    window.addEventListener("load", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    window.addEventListener("resize", (event: any) => {
      setWindowSize(event?.currentTarget?.innerWidth);
    });
    return () => {
      window.removeEventListener("load", () => {});
      window.removeEventListener("resize", () => {});
    };
  }, [windowSize]);
  // console.log("ORDERSUCCESS", ORDERSUCCESS);
  return (
    <BrowserRouter>
      {/* <DiscountBanner /> */}
      {/* <Header /> */}
      {/* <CheckoutHeader /> */}
      <MainHeader />
      <ScrollToTop>
        <Routes>
          <Route path={HOME} element={<Home />} />
          <Route path={TYPO} element={<Typo />} />
          <Route path={LOADER} element={<Loader />} />
          {/* <Route path={ABOUT} element={<CmsPage slug="about-us" />} /> */}
          <Route path={ABOUT} element={<AboutPural />} />
          <Route path={CLUB} element={<Club />} />
          {/*<Route path={CONTACT} element={<CmsPage slug="contact-us" />} />*/}
          <Route path={CONTACT} element={<ContactUs />} />
          <Route path={PRODUCT_DETAIL+"/:slug"} element={<DetailPage />} />
          {/* <Route path={DETAILPAGE} element={<DetailPage />} /> */}
          <Route path={PRODUCT_LISTING} element={<ProductListing />} />
          <Route path={LOGIN} element={<Login />} />
          <Route path={SIGNUP} element={<Signup />} />
          <Route path={FORGOTPASSWORD} element={<ForgotPassword />} />
          <Route path={FORGOTSUCCESS} element={<ForgotSuccess />} />
          <Route path={RESETPASSWORD + "/:token"} element={<ResetPassword />} />
          <Route path={RESETSUCCESS} element={<ResetSuccess />} />
          {windowSize > 992 ? (
            <>
              <Route
                path={ACCOUNT}
                element={
                  <ProtectedRoutes>
                    <AccountPages />
                  </ProtectedRoutes>
                }
              >
                <Route path={ORDERS} element={<Orders />} />
                <Route path={SUBSCRIPTION} element={<Subscription />} />
                <Route path={PERSONALDATA} element={<PersonalData />} />
                <Route path={ADRESSES} element={<Addresses />} />
                <Route
                  path={COMMUNICATIONSETTINGS}
                  element={<CommunicationSettings />}
                />
              </Route>
            </>
          ) : (
            <Route
              path={ACCOUNT}
              element={
                <ProtectedRoutes>
                  <MobAccountPages />
                </ProtectedRoutes>
              }
            >
              <Route path={ACCOUNT} element={<AccountSideMenu />} />
              <Route path={ORDERS} element={<Orders />} />
              <Route path={SUBSCRIPTION} element={<Subscription />} />
              <Route path={PERSONALDATA} element={<PersonalData />} />
              <Route path={ADRESSES} element={<Addresses />} />
              <Route
                path={COMMUNICATIONSETTINGS}
                element={<CommunicationSettings />}
              />
            </Route>
          )}
          <Route path={ORDERSUCCESS+"/:id"} element={<OrderConfirmation />} />
          <Route path={MOBILEORDERS} element={<Orders />} />
          <Route path={CHECKOUT} element={<Checkout />} />
          <Route path={FAQS} element={<Faqs />} />
          <Route
            path={SHIPPINGRETURNS}
            element={<CmsPage slug="shipping-and-returns" />}
          />
          {/* <Route path={SHIPPINGRETURNS} element={<ShippingReturns />} /> */}
          <Route
            path={PRIVACYPOLICY}
            element={<CmsPage slug="privacy-policy" />}
          />
          {/* <Route path={PRIVACYPOLICY} element={<PrivacyPolicy />} /> */}
          <Route
            path={TERMSANDCONDITION}
            element={<CmsPage slug="terms-and-condition" />}
          />
          {/* <Route path={TERMSANDCONDITION} element={<TermsAndCondition />} /> */}
          <Route path="*" element={<SomethingWrong />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </BrowserRouter>
  );
};

export default Routesconfig;
