import React, { useEffect, useState } from "react";
import InputGroups from "../../Common/InputGroups/InputGroups";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../../Constant/Api";
import validate from "../../../Utility/Validation";
import {
  NEWSLETTER_SUBSCRIPTION_FAILD,
  NEWSLETTER_SUBSCRIPTION_LONG,
  NEWSLETTER_SUBSCRIPTION_REQUEST,
  NEWSLETTER_SUBSCRIPTION_SUCCESS,
} from "../../../Redux/General/GeneralType";
import { SUCCESS_RESPONSE_CODE } from "../../../Constant/Status";
import { newsletterSubscriptionAction } from "../../../Redux/General/GeneralAction";
import { isLoading } from "../../../Utility/General";
import { useLocation } from "react-router-dom";

const SubscribeNewsletter = () => {
  const dispatch = useDispatch();
  let location = useLocation();
  const [path, setPath] = useState("");
  const INITIAL_NEWSLATTER_DATA: any = {
    email: {
      value: "",
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
  };

  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);

  const [newslatterData, setNewslatterData] = useState(INITIAL_NEWSLATTER_DATA);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [newslatterDataError, setNewslatterDataError] = useState(demo);
  useEffect(() => {
    if(path !== location.pathname){
      setIsFormValidated(false);
      setPath(location.pathname);
    }
  }, [location]);
  const setData = (field: string, value: string) => {
    let signupDetails = Object.assign({}, newslatterData);
    signupDetails[field].value = value;
    setNewslatterData({ ...signupDetails });
  };

  useEffect(() => {
    setNewslatterDataError({ ...validate(newslatterData)?.errors });
    setIsFormValidated(false);
  }, [newslatterData]);

  const newslatterHandler = () => {
    if (validate(newslatterData).isValidated === true) {
      dispatch(
        newsletterSubscriptionAction({
          email: newslatterData.email.value,
        })
      );
    } else {
      setNewslatterDataError({ ...validate(newslatterData)?.errors });
      setIsFormValidated(true);
    }
  };

  useSelector((state: any) => {
    if (state?.newslatter?.type === NEWSLETTER_SUBSCRIPTION_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.newslatter?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.newslatter?.type === NEWSLETTER_SUBSCRIPTION_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        setNewslatterData({...INITIAL_NEWSLATTER_DATA});
      }
    } else if (state?.newslatter?.type === NEWSLETTER_SUBSCRIPTION_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.newslatter?.type === NEWSLETTER_SUBSCRIPTION_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  return (
    <div className="subscribe-newsletter">
      <h6>Don’t miss any updates</h6>
      <p>Subscribe to our newsletter and get the latest news of our brand! </p>
      <div className="input-btn">
        <InputGroups
          label="Enter e-mail"
          inputmode="email"
          value={newslatterData.email.value}
          autoComplete="off"
          onChange={(e: any) => setData("email", e.target.value)}
          error={
            isFormValidated && newslatterDataError["email"]
              ? newslatterDataError["email"]
              : null
          }
        />
        <CustomButton
          isLoading={isLoading(apiStatus)}
          disabled={isLoading(apiStatus)}
          bg="fill"
          bgColor="white"
          onClick={() => newslatterHandler()}
        >
          SUBSCRIBE to newsletter
        </CustomButton>
      </div>
    </div>
  );
};

export default SubscribeNewsletter;
