import { CMS_FAILD, CMS_LONG, CMS_REQUEST, CMS_SUCCESS } from "./CmsPagesType";

export function cmsPage(state = {}, action: any) {
  switch (action.type) {
    case CMS_REQUEST:
      return { ...action.payload };
    case CMS_SUCCESS:
      return { ...action.payload };
    case CMS_FAILD:
      return { ...action.payload };
    case CMS_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}
