import { useEffect, useState } from "react";
import { useSelector , useDispatch } from "react-redux";
import { CHECKOUTPAGE } from "../../../Constant/General";
import RadioLabel from "../../Common/RadioLabel/RadioLabel";
import CkAddressPopup from "./CkAddressPopup/CkAddressPopup";
import EditIcon from "../../../Assets/img/Edit.svg";
import { quoteUpdateAddressAction } from "../../../Redux/Checkout/CheckoutAction"
import { QUOTE_ID } from "../../../Constant/LocalStorage";

const CheckoutAddress = (props: any) => {
  const [CkAddressModalShow, setCkAddressModalShow] = useState(false);
  const [popupType, setPopupType] = useState('add');
  const authData = useSelector((state: any) => state?.login?.data);
  const [addressData, setAddressData] = useState<any>();
  const [editAddressData, setEditAddressData] = useState<any>(props?.addressData);
  const [isLoginUSer,setIsLogInuser] = useState<any>(authData);
  const dispatch = useDispatch()
  const [addressType,setAddressType] = useState("shipping")
  let quoteId = localStorage.getItem(QUOTE_ID)
  ? localStorage.getItem(QUOTE_ID)
  : "";
  const updateAddress = (data: any) => {
    setAddressData(data);
    props.onAddressChange(data);
  }
  // const addressList = [
  //   {
  //     checked: true,
  //     addrShipping: [
  //       {
  //         name: "Hamad AlMuraikhi",
  //         address: "Avenue Lorem ipsum, Block 5, Street 1, Building 2, floor 4th, number 3",
  //         landmark: "Qortuba Health Center",
  //       },
  //     ],
  //     addrBilling: [
  //       {
  //         name: "Hamad AlMuraikhi",
  //         address: "Avenue Lorem ipsum, Block 5, Street 1, Building 2, floor 4th, number 3",
  //         landmark: "Qortuba Health Center",
  //       },
  //     ],
  //   },
  // ]
  const editAddress = (data: any) => {
    setEditAddressData(data);
    setCkAddressModalShow(true);
  };
  const addAddressFormOpen = () => {
    setPopupType('add');
    editAddress({})
  }
  const updateDefaultQuoteAddress = (id:any, event:any) => {     
     if(event === "1"){
      dispatch(quoteUpdateAddressAction({
        addressId: id,
        isDefaultShipping: event,
        quoteId:quoteId,
      }))
     }
    }
    
  useEffect(() => {
    let addrData: any = {
      isBilingSelect: "",
      address: {},
      bilingaddress: {},
      shippingAddress: {},
    };
    if (props?.addressData?.checkoutAddressData) {
      if (props?.addressData?.checkoutAddressData?.address.length > 0) {
        addrData["address"] =
          props?.addressData?.checkoutAddressData?.address;
        }
        if (
          props?.addressData?.checkoutAddressData?.billingAddress?.length > 0
        ) {
          addrData["bilingaddress"] =
            props?.addressData?.checkoutAddressData?.billingAddress[0];
        }
        if(props?.addressData?.checkoutAddressData?.shippingAddress?.length > 0){
          addrData["shippingAddress"] = props?.addressData?.checkoutAddressData?.shippingAddress[0]
        }
        setAddressData({ ...addressData, ...addrData });
    }
  }, [props.addressData]);

  const handleOnClickEvent = () => {
    props.onTabChange(CHECKOUTPAGE.auth);
    if(!authData){
      props.setPaymentType("","");
    }
  }
  
  const ShippingAddress = () => {
    if(addressData?.shippingAddress !== undefined && JSON.stringify(addressData?.shippingAddress) !== '{}'){
      return(
        <div className="checkout-address-box">
          <div className="rd">
            <RadioLabel 
              type="radio"
              value={addressData?.shippingAddress?.isDefaultShipping}
              name="select-addr"
              defaultChecked={true}
              onChange={(e:any) => {updateDefaultQuoteAddress(addressData?.shippingAddress?.addressId,e.target.checked ? "1" : "0"
              )}}
            />
          </div>
          <div className="address-shipping">
            <h5>Shipping Address</h5>
            <div>
              <div className="ar-name">
                {addressData?.shippingAddress?.firstName +
                  " " +
                  addressData?.shippingAddress?.lastName}
              </div>
              <address>
                {addressData?.shippingAddress?.addressType == "Apartment"
                  ? addressData?.shippingAddress?.apartment_number +
                    "," +
                    addressData?.shippingAddress?.floor +
                    ","
                  : ""}
                  {addressData?.shippingAddress?.block +
                  "," +
                  addressData?.shippingAddress?.avenue +
                  "," +
                  addressData?.shippingAddress?.street}
              </address>
              <div className="ar-landmark">
                {addressData?.shippingAddress?.building +
                  "," +
                  addressData?.shippingAddress?.customer_area}
              </div>
            </div>
          </div>
          <img
            src={EditIcon}
            onClick={() => {
              setPopupType("edit");
              editAddress(addressData?.shippingAddress)
              setAddressType("shipping")
            }}
            className="edit-icons"
            alt=""
          />
      </div>
      )
    }
  }
  const Addressess = () => {
    if(addressData?.address?.length > 0){
      return addressData?.address.map(
        (data: any, index: number) => {
          return (
            <div className="checkout-address-box" key={index + "adress"}>
              <div className="rd">
                <RadioLabel
                  type="radio"
                  value={data.isDefaultShipping}
                  name="select-addr"
                  defaultChecked={!!parseInt(data.isDefaultShipping)}
                  onChange={(e:any) =>  updateDefaultQuoteAddress(data.addressId,e.target.checked ? "1" : "0"
                  )}
                />
              </div>
              <div className="address-shipping">
                <h5>Shipping Address</h5>
                <div>
                  <div className="ar-name">
                    {data.firstName + " " + data.lastName}
                  </div>
                  <address>
                    {data.addressType == "Apartment"
                      ? data.apartment_number +
                        "," +
                        data.floor +
                        ","
                      : ""}
                    {data.block +
                      "," +
                      data.avenue +
                      "," +
                      data.street}
                  </address>
                  <div className="ar-landmark">
                    {data.building+
                      "," +
                      data.customer_area}
                    </div>
                </div>
              </div>
              <img
                src={EditIcon}
                onClick={() => {
                  setPopupType("edit");
                  editAddress(data);
                  setAddressType("address");
                }}
                className="edit-icons"
                alt=""
              />
            </div>
          );
        }
      )}
  }
  //const BillingAddress = () => {
  //  if(addressData?.bilingaddress !== undefined &&JSON.stringify(addressData?.bilingaddress) !== '{}'){
  //    return(
  //      <div className="address-billing">
  //          <h5>Billing Address</h5>
  //           <img
  //              src={EditIcon}
  //              onClick={() =>{
  //                setPopupType("edit");
  //                editAddress(addressData?.bilingaddress);
  //                setAddressType("billing")
  //              }}
  //              className="edit-icons"
  //              alt=""
  //            />
  //          <div>
  //            <div className="ar-name">
  //              {addressData?.bilingaddress?.firstName +
  //                " " +
  //               addressData?.bilingaddress?.lastName}
  //            </div>
  //              <address>
  //                {addressData?.bilingaddress?.addressType == "Apartment"
  //                  ? addressData?.bilingaddress?.apartment_number +
  //                    "," +
  //                    addressData?.bilingaddress?.floor +
  //                    ","
  //                : ""}
  //                {addressData?.bilingaddress?.block +
  //                   "," +
  //                  addressData?.bilingaddress?.avenue +
  //                  "," +
  //                  addressData?.bilingaddress?.street}
  //              </address>
  //                <div className="ar-landmark">
  //                  {addressData?.bilingaddress?.building +
  //                    "," +
  //                 addressData?.bilingaddress?.customer_area}
  //                </div>
  //          </div>
  //      </div>
  //    )  
  //  }
  //}
  const FormData = () => {
    if(isLoginUSer){
      return(
        <div className="addr-content">
            {ShippingAddress()}
            {Addressess()}
            {/*{BillingAddress()}*/}
            <div className="add-new">
              <div className="add-newwrap">
                <a
                  className="add-new-modal"
                  onClick={() => {
                    addAddressFormOpen()
                  }}
                >
                  Add a new shipping address
                </a>
              </div>
          </div>
        </div>
      )
    }else{
      if(addressData?.shippingAddress !== undefined &&  JSON.stringify(addressData?.shippingAddress) !== '{}'){
       return(
        <div className="addr-content">
              {ShippingAddress()}
        </div>
       )
      }else{
        return(
          <div className="add-new">
            <div className="add-newwrap">
              <a
                className="add-new-modal"
                onClick={() => {
                  addAddressFormOpen()
                }}
              >
                Add a new shipping address
              </a>
            </div>
          </div>
        )
      }
    }
  }

  return (
    <>
      <a
        className="prev-step show-768 login"
        onClick={() => handleOnClickEvent()}
      >
        Hello {authData?.firstname}! <span>Confirm the next steps and buy</span>
      </a>
      <div className="step-title" onClick={() => props.onTabChange("address")}>
        Address
      </div>
      <div className="address-steps">
        <div className="addr-innerwrap no-border" key={"0addressSetpParent"}>
              {FormData()}
        </div>
      </div>
      <CkAddressPopup
        show={CkAddressModalShow}
        editAddressData={editAddressData}
        onSubmit={(data: any) => updateAddress(data)}
        onHide={() => setCkAddressModalShow(false)}
        popupType={popupType}
        addressType={addressType}
        currentBilling={addressData?.bilingaddress}
        currentShipping={(popupType == "add") ? {} : addressData?.shippingAddress}
      ></CkAddressPopup>
    </>
  );
};

export default CheckoutAddress;
