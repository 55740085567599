const ProgressSteps = (props: any) => {
  return (
    <>
       <div className="step-wrapper mobile-view">
        <div className="process-progress mo">
            <li className={props.activeTabCount === 1 ? "info-progress done" : "info-progress"}>
              <div className="numbers"></div>
              <p className="tab-info">Summary</p>
            </li>
            <li className={props.activeTabCount === 2 || props.activeTabCount === 3 || props.activeTabCount === 4 || props.activeTabCount === 5  ? "info-progress done" : "info-progress"}>
              <div className="numbers"></div>
              <p className="tab-info">Data</p>
            </li>
            <li className={props.activeTabCount === 6 ? "info-progress done" : "info-progress"}>
              <div className="numbers"></div>
              <p className="tab-info">Thank You</p>
            </li>
        </div>
      </div>
    </>
  );
};

export default ProgressSteps;
