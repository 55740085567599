import { PRODUCT_DETAIL_CLEAR, PRODUCT_DETAIL_FAILD, PRODUCT_DETAIL_LONG, PRODUCT_DETAIL_REQUEST, PRODUCT_DETAIL_SUCCESS, PRODUCT_VARIENT_CLEAR, PRODUCT_VARIENT_FAILD, PRODUCT_VARIENT_LONG, PRODUCT_VARIENT_REQUEST, PRODUCT_VARIENT_SUCCESS, PRODUCT_LIST_CLEAR, PRODUCT_LIST_FAILD, PRODUCT_LIST_LONG, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS } from "./ProductType";

export function productListReducer(state = {}, action: any) {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { ...action.payload };
    case PRODUCT_LIST_SUCCESS:
      return { ...action.payload };
    case PRODUCT_LIST_FAILD:
      return { ...action.payload };
    case PRODUCT_LIST_LONG:
      return { ...action.payload };
    case PRODUCT_LIST_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function productDetailPage(state = {}, action: any) {
  switch (action.type) {
    case PRODUCT_DETAIL_REQUEST:
      return { ...action.payload };
    case PRODUCT_DETAIL_SUCCESS:
      return { ...action.payload };
    case PRODUCT_DETAIL_FAILD:
      return { ...action.payload };
    case PRODUCT_DETAIL_LONG:
      return { ...action.payload };
    case PRODUCT_DETAIL_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function productDetailVarient(state = {}, action: any) {
  switch (action.type) {
    case PRODUCT_VARIENT_REQUEST:
      return { ...action.payload };
    case PRODUCT_VARIENT_SUCCESS:
      return { ...action.payload };
    case PRODUCT_VARIENT_FAILD:
      return { ...action.payload };
    case PRODUCT_VARIENT_LONG:
      return { ...action.payload };
    case PRODUCT_VARIENT_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
}
