import { Container } from "react-bootstrap";
import successImg from "../../Assets/img/order-succ.png";
import CustomButton from "../Common/CustomButton/CustomButton";
import { HOME, ORDERS } from "../../Constant/Route";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { paymentConfirmAction } from "../../Redux/Checkout/CheckoutAction";
import { PAYMENT_CONFIRM_FAILD,PAYMENT_CONFIRM_LONG,PAYMENT_CONFIRM_REQUEST,PAYMENT_CONFIRM_SUCCESS } from "../../Redux/Checkout/CheckoutType"
import { API_STATUS } from "../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { isLoading } from "../../Utility/General"

const OrderConfirmation = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const urlParams = useParams();
  const orderData = useSelector((state: any) => state?.paymentConfirmReducer?.data)
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  useEffect(()=>{
    // 000000095&&#%23!91
    let orderkey = urlParams.id;
    dispatch(paymentConfirmAction({ orderId :orderkey}));
  },[])
  const auth = useSelector((state: any) => state?.login?.isLoggedIn);
  const navigation = (e: any) => {
    if(auth) {
      navigate(ORDERS);
    }else {
      navigate(HOME)
    }
  }

  useSelector((state: any) => {
    if (state?.paymentConfirmReducer?.type === PAYMENT_CONFIRM_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.paymentConfirmReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.paymentConfirmReducer?.type === PAYMENT_CONFIRM_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.paymentConfirmReducer?.type === PAYMENT_CONFIRM_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.paymentConfirmReducer?.type === PAYMENT_CONFIRM_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  return (
    <div className="order-confirmwrap">
      <Container>
        <div className="os-wrapper">
          <div className="oc-head">
            <h3>Thank you! We have received your order.</h3>
          </div>
          <img src={successImg} className="oc-img" alt="Order Placed"></img>
          <div className="oc-desc">
            <p>
              Your order ID: <strong>#{orderData?.orderIncreamentId}</strong>
            </p>
            <p>We will notify you once the product leaves our warehouse.</p>
          </div>
          <div className="action-bar">
            <CustomButton
              bg={"fill"}
              onClick={(e: any) => navigation(e)}
              isLoading={isLoading(apiStatus)}
              disabled={isLoading(apiStatus)}
            >
              {auth ? "Go to my account" : "Go to Home"}
            </CustomButton>
            <div className="go-back-wrapper">
              <Link className="go-back-btn" to={HOME}>
                <span>Go Back</span>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default OrderConfirmation;