import { END_POINTS, MAX_CALLS } from "../../Constant/Api";
import { QUOTE_ID, USER } from "../../Constant/LocalStorage";
import {
  NO_DATA_ERROR_CODE,
  SUCCESS_RESPONSE_CODE,
} from "../../Constant/Status";
import api from "../../Service/Axios";
import { showToast } from "../../Utility/General";
import { CART_COUNT_GLOBAL } from "../General/GeneralType";
import {
  ADD_REMOVE_CART_FAILD,
  ADD_REMOVE_CART_LONG,
  ADD_REMOVE_CART_REQUEST,
  ADD_REMOVE_CART_SUCCESS,
  MYCART_FAILD,
  MYCART_GLOBAL,
  MYCART_LONG,
  MYCART_NO_DATA,
  MYCART_REQUEST,
  MYCART_SUCCESS,
} from "./CartType";

export function addToCartAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: ADD_REMOVE_CART_REQUEST,
      payload: { type: ADD_REMOVE_CART_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.addToCart}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            showToast("success", result.data.message);
            dispatch({
              type: ADD_REMOVE_CART_SUCCESS,
              payload: { data: result.data, type: ADD_REMOVE_CART_SUCCESS },
            });
            dispatch({
              type: CART_COUNT_GLOBAL,
              payload: { data: result.data?.cartCount },
            });
            if (
              !localStorage.getItem(QUOTE_ID) || localStorage.getItem(QUOTE_ID) !== ""
            ) {
              localStorage.setItem(QUOTE_ID, result.data?.quoteId);
            }
            let quoteId = localStorage.getItem(QUOTE_ID)
              ? localStorage.getItem(QUOTE_ID)
              : "";
            dispatch(myCart({ quoteId: quoteId }));
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: ADD_REMOVE_CART_LONG,
              payload: { data: {}, type: ADD_REMOVE_CART_LONG },
            });
          } else {
            dispatch({
              type: ADD_REMOVE_CART_FAILD,
              payload: { data: {}, type: ADD_REMOVE_CART_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: ADD_REMOVE_CART_LONG,
              payload: { data: {}, type: ADD_REMOVE_CART_LONG },
            });
          } else {
            dispatch({
              type: ADD_REMOVE_CART_FAILD,
              payload: { data: {}, type: ADD_REMOVE_CART_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function myCart(params: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: MYCART_REQUEST,
      payload: { type: MYCART_REQUEST },
    });
    function recursiveCall() {
      return api
        .post(END_POINTS.myCart, params)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            // dispatch({
            //   type: MYCART_SUCCESS,
            //   payload: { data: result.data, type: MYCART_SUCCESS },
            // });

            dispatch({
              type: MYCART_GLOBAL,
              payload: { data: result.data, type: MYCART_SUCCESS },
            });
          } else if (result.data.errorCode === NO_DATA_ERROR_CODE) {
            dispatch({
              type: MYCART_NO_DATA,
              payload: { data: result.data, type: MYCART_NO_DATA },
            });

            dispatch({
              type: MYCART_GLOBAL,
              payload: { data: result.data },
            });
          } else {
            dispatch({
              type: MYCART_FAILD,
              payload: { data: {}, type: MYCART_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: MYCART_LONG,
              payload: { data: {}, type: MYCART_LONG },
            });
          } else {
            dispatch({
              type: MYCART_FAILD,
              payload: { data: {}, type: MYCART_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}
