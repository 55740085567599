import React from "react";
import ForgotSuccessImg from "../../Assets/img/forgot-success.png";
import { Container } from "react-bootstrap";
import CustomButton from "../Common/CustomButton/CustomButton";
import { FORGOTPASSWORD, HOME, LOGIN } from "../../Constant/Route";
import { useNavigate } from "react-router-dom";

const ForgotSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="forgot-success">
      <Container>
        <div className="FS-wrapper">
          <img src={ForgotSuccessImg} alt="Order Placed" />
          <h1>Got it!</h1>
          <p>You will shortly receive an email with the steps to reset your password.</p>
          <CustomButton bg="fill" onClick={() => navigate(LOGIN)}>go back</CustomButton>
        </div>
      </Container>
    </div>
  );
};

export default ForgotSuccess;
