import CartImg1 from "../../../../Assets/img/cart-img-1.png";
import CartImgpl from "../../../../Assets/img/cart-img-placeholder.png";
import IncDecBox from "../../../Common/IncDecBox/IncDecBox";
import SelectGropus from "../../../Common/SelectGroups/SelectGroups";
import CartDelete from "../../../../Assets/img/cart-trash.svg";
import CustomButton from "../../../Common/CustomButton/CustomButton";
import { useState } from "react";

const BagSummary = (props: any) => {

  return (
    <>
      <div className={`cart-items ${props.showSummary ? "show" : "hide"}`}>
        <div className="bag-content">
          <div className="cart-box">
            {props?.productDetail?.purchasedProductData?.list?.map(
              (data: any, index: number) => (
                <div className="item" key={index + "cartItems2"}>
                  <img src={data.image} alt="" className="cart-data-img" />
                  <div className="cart-data">
                    <p className="f1626">{data.title}</p>
                    {/* {
                      data.subscriptionActive == "1" ?
                        <div className="with-sub">
                          <p>{data.timePeriod}</p>
                        </div> :
                        <div className="with-category">
                          <p>{data.category}</p>
                        </div>
                    } */}
                    <div className="with-category">
                      <p>{data?.variant?.length? data?.variant[0]?.value:""}</p>
                    </div>
                  </div>
                  <div className="price-action">
                    <p className="price-text">{data.price}</p>
                  </div>
                </div>
              )
            )}
            {/* {orderData.map((data: any, index: number) => (
              <div className="item" key={index + "cartItems2"}>
                <img src={data.productImg} alt="" className="cart-data-img" />
                <div className="cart-data">
                  <p className="f1626">{data.productName}</p>
                  {
                      data.subscriptionActive == "1" ?
                        <div className="with-sub">
                          <p>{data.timePeriod}</p>
                        </div> :
                        <div className="with-category">
                          <p>{data.category}</p>
                        </div>
                    }
                  <div className="with-category">
                    <p>{data.category}</p>
                  </div>
                </div>
                <div className="price-action">
                  <p className="price-text">{data.productPrice}</p>
                </div>
              </div>
            ))} */}
          </div>
          <div className="shipping-section">
            <span className="label-text">Shipping</span>
            <span className="value-text price-text">-</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default BagSummary;