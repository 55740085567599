import React from 'react';
import { Link } from "react-router-dom";
import OrderConfirmation from '../../OrderConfirmation/OrderConfirmation';
import AccountBreadcrumb from '../AccountBreadcrumb/AccountBreadcrumb';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import CheckboxLabel from '../CheckboxLabel/CheckboxLabel';
import CustomButton from "../CustomButton/CustomButton";
import IncDecBox from "../IncDecBox/IncDecBox";
import InputGroups from "../InputGroups/InputGroups";
import PasswordGroups from '../PasswordGroups/PasswordGroups';
import RadioLabel from "../RadioLabel/RadioLabel";
import SelectGropus from "../SelectGroups/SelectGroups";
import TextareaGroups from "../TextareaGroups/TextareaGroups";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const Typo = () => {
	
	const selectData = [
		{id: 1, name: 'one'},
		{id: 2, name: 'two'},
		{id: 3, name: 'three'},
	];

	const breadsData = [
		{
			path: "/",
			label: "Home",
		},
		{
			path: "/about",
			label: "About",
		},
		{
			path: "/contact",
			label: "Contact Us",
		},
	];

  const accBreadsData = [
		{
			path: "/",
			label: "Home",
		},
		{
			label: "About",
		},
		{
			label: "Contact Us",
		},
	];

  const [age, setAge] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
	
	return (
		<>
			{/* <h1>This is H1</h1>
			<h2>This is H2</h2>
			<h3>This is H3</h3>
			<p>This is P</p>
			<span className="price-text">50 (Price Text)</span>

			<br></br><br></br>

			<CustomButton bg="fill" title={"About Pural"}></CustomButton>

			<br></br><br></br>

			<CustomButton title={"About Pural"}></CustomButton>

			<br></br><br></br>

			<CustomButton title={"Disabled"} disabled></CustomButton>

			<br></br><br></br>

			<InputGroups label={"Name"}></InputGroups>
			<InputGroups label={"Email"}></InputGroups>

			<br></br><br></br>

			<SelectGropus value={selectData}></SelectGropus>

			<br></br><br></br>

			<RadioLabel id="default">Default</RadioLabel>&nbsp;&nbsp;&nbsp;
			<RadioLabel id="default-checked" defaultChecked={"true"}>Default Checked</RadioLabel>

			<br></br><br></br>

			<TextareaGroups rowno={4} colno={8}>Address</TextareaGroups>

			<br></br><br></br>

			<IncDecBox value={1}></IncDecBox>

			<br></br><br></br>
			<ul>
				<li>
					<Link to="/about">About</Link>
				</li>
			</ul>

			<br></br><br></br>

			<Breadcrumbs value={breadsData}></Breadcrumbs>

			<br></br><br></br>

			<PasswordGroups error="Error Message" label={"Password"}></PasswordGroups>
			<OrderConfirmation></OrderConfirmation>
			<br></br><br></br>

			<br></br><br></br>

			<CheckboxLabel>Hello</CheckboxLabel>
			
			<br></br><br></br>
			<br></br><br></br>
      <AccountBreadcrumb value={accBreadsData}></AccountBreadcrumb>

      <br></br><br></br>

      <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          label="Age"
          onChange={handleChange}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    </Box>


    <br></br><br></br> */}

		</>
	)
}

export default Typo;