import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.scss";
import Routesconfig from "./Routes/Routesconfig";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "./Constant/Api";
import {
  defaultParameterAction,
  getMaster,
} from "./Redux/General/GeneralAction";
import {
  MASTER_FAILD,
  MASTER_LONG,
  MASTER_REQUEST,
  MASTER_SUCCESS,
} from "./Redux/General/GeneralType";
import { SUCCESS_RESPONSE_CODE } from "./Constant/Status";
import Loader from "./Component/Loader/Loader";
import { QUOTE_ID } from "./Constant/LocalStorage";
import { myCart } from "./Redux/Cart/CartAction";

function App() {
  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(API_STATUS.LOADING);
  let quoteId = localStorage.getItem(QUOTE_ID)
    ? localStorage.getItem(QUOTE_ID)
    : "";
  useEffect(() => {
    dispatch(getMaster({ quoteId: quoteId }));
  }, []);

  useSelector((state: any) => {
    if (state?.master?.type === MASTER_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.master?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.master?.type === MASTER_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.master?.type === MASTER_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.master?.type === MASTER_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }

    if (state?.login?.isLoggedIn) {
      let defaultParameter = Object.assign({}, state?.defaultParameter);
      // defaultParameter.websiteId = state?.master?.data?.websiteSettings?.websiteId;
      // defaultParameter.groupId = state?.master?.data?.websiteSettings?.groupId;
      // defaultParameter.storeId = state?.master?.data?.websiteSettings?.storeId;
      if (
        defaultParameter.customerToken !== state?.login?.data?.customerToken
      ) {
        defaultParameter.customerToken = state?.login?.data?.customerToken;
        dispatch(defaultParameterAction({...defaultParameter}));
        dispatch(getMaster({ quoteId: quoteId }));
      }
    } 
    // else {
    //   let defaultParameter = Object.assign({}, state?.defaultParameter);
    //   defaultParameter.customerToken = state?.master?.data?.websiteSettings?.customerToken;
    //   defaultParameter.websiteId = state?.master?.data?.websiteSettings?.websiteId;
    //   defaultParameter.groupId = state?.master?.data?.websiteSettings?.groupId;
    //   defaultParameter.storeId = state?.master?.data?.websiteSettings?.storeId;
    //   dispatch(defaultParameterAction({...defaultParameter}));
    // }
  });

  // return (
  //   <div className="App">
  //     <Routesconfig></Routesconfig>
  //   </div>
  // );

  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <div className="App">
          <Routesconfig></Routesconfig>
        </div>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    // return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
}

export default App;
