import {
  ADDRESS_FAILD,
  ADDRESS_LONG,
  ADDRESS_REQUEST,
  ADDRESS_SUCCESS,
  CHANGE_PASSWORD_DATA_CLEAR,
  CHANGE_PASSWORD_DATA_FAILD,
  CHANGE_PASSWORD_DATA_LONG,
  CHANGE_PASSWORD_DATA_REQUEST,
  CHANGE_PASSWORD_DATA_SUCCESS,
  COMMUNICATION_FAILD,
  COMMUNICATION_LONG,
  COMMUNICATION_REQUEST,
  COMMUNICATION_SUCCESS,
  FORM_ADDRESS_FAILD,
  FORM_ADDRESS_LONG,
  FORM_ADDRESS_REQUEST,
  FORM_ADDRESS_SUCCESS,
  GET_SUBSCRIPTION_CLEAR,
  GET_SUBSCRIPTION_FAILD,
  GET_SUBSCRIPTION_LONG,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  ORDERS_FAILD,
  ORDERS_LONG,
  ORDERS_NO_DATA,
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  PERSONAL_DATA_FAILD,
  PERSONAL_DATA_LONG,
  PERSONAL_DATA_REQUEST,
  PERSONAL_DATA_SUCCESS,
  SET_COMMUNICATION_FAILD,
  SET_COMMUNICATION_LONG,
  SET_COMMUNICATION_REQUEST,
  SET_COMMUNICATION_SUCCESS,
  SET_PERSONAL_DATA_CLEAR,
  SET_PERSONAL_DATA_FAILD,
  SET_PERSONAL_DATA_LONG,
  SET_PERSONAL_DATA_REQUEST,
  SET_PERSONAL_DATA_SUCCESS,
  YOUR_SCREEN_CLEAR,
  YOUR_SCREEN_FAILD,
  YOUR_SCREEN_LONG,
  YOUR_SCREEN_REQUEST,
  YOUR_SCREEN_SUCCESS,
  INVOICE_REQUEST,
  INVOICE_SUCCESS,
  INVOICE_FAILED ,
  INVOICE_LONG ,
  INVOICE_NO_DATA,

} from "./AccountType";


export function invoiceReducer(state = {}, action: any) {
  switch (action.type) {
    case INVOICE_REQUEST:
      return { ...action.payload };
    case INVOICE_SUCCESS:
      return { ...action.payload };
    case INVOICE_NO_DATA:
      return { ...action.payload };
    case INVOICE_FAILED:
      return { ...action.payload };
    case INVOICE_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}




export function getOrders(state = {}, action: any) {
  switch (action.type) {
    case ORDERS_REQUEST:
      return { ...action.payload };
    case ORDERS_SUCCESS:
      return { ...action.payload };
    case ORDERS_NO_DATA:
      return { ...action.payload };
    case ORDERS_FAILD:
      return { ...action.payload };
    case ORDERS_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function personalData(state = {}, action: any) {
  switch (action.type) {
    case PERSONAL_DATA_REQUEST:
      return { ...action.payload };
    case PERSONAL_DATA_SUCCESS:
      return { ...action.payload };
    case PERSONAL_DATA_FAILD:
      return { ...action.payload };
    case PERSONAL_DATA_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function addressData(state = {}, action: any) {
  switch (action.type) {
    case ADDRESS_REQUEST:
      return { ...action.payload };
    case ADDRESS_SUCCESS:
      return { ...action.payload };
    case ADDRESS_FAILD:
      return { ...action.payload };
    case ADDRESS_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function addressUpdateData(state = {}, action: any) {
  switch (action.type) {
    case FORM_ADDRESS_REQUEST:
      return { ...action.payload };
    case FORM_ADDRESS_SUCCESS:
      return { ...action.payload };
    case FORM_ADDRESS_FAILD:
      return { ...action.payload };
    case FORM_ADDRESS_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function setPersonalData(state = {}, action: any) {
  switch (action.type) {
    case SET_PERSONAL_DATA_REQUEST:
      return { ...action.payload };
    case SET_PERSONAL_DATA_SUCCESS:
      return { ...action.payload };
    case SET_PERSONAL_DATA_FAILD:
      return { ...action.payload };
    case SET_PERSONAL_DATA_LONG:
      return { ...action.payload };
    case SET_PERSONAL_DATA_CLEAR:
      return {};
    default:
      return state;
  }
}

export function communication(state = {}, action: any) {
  switch (action.type) {
    case COMMUNICATION_REQUEST:
      return { ...action.payload };
    case COMMUNICATION_SUCCESS:
      return { ...action.payload };
    case COMMUNICATION_FAILD:
      return { ...action.payload };
    case COMMUNICATION_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function setCommunication(state = {}, action: any) {
  switch (action.type) {
    case SET_COMMUNICATION_REQUEST:
      return { ...action.payload };
    case SET_COMMUNICATION_SUCCESS:
      return { ...action.payload };
    case SET_COMMUNICATION_FAILD:
      return { ...action.payload };
    case SET_COMMUNICATION_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}

export function changePassword(state = {}, action: any) {
  switch (action.type) {
    case CHANGE_PASSWORD_DATA_REQUEST:
      return { ...action.payload };
    case CHANGE_PASSWORD_DATA_SUCCESS:
      return { ...action.payload };
    case CHANGE_PASSWORD_DATA_FAILD:
      return { ...action.payload };
    case CHANGE_PASSWORD_DATA_LONG:
      return { ...action.payload };
    case CHANGE_PASSWORD_DATA_CLEAR:
      return {};
    default:
      return state;
  }
}

export function getSubscriptionReducer(state = {}, action: any) {
  switch (action.type) {
    case GET_SUBSCRIPTION_REQUEST:
      return { ...action.payload };
    case GET_SUBSCRIPTION_SUCCESS:
      return { ...action.payload };
    case GET_SUBSCRIPTION_FAILD:
      return { ...action.payload };
    case GET_SUBSCRIPTION_LONG:
      return { ...action.payload };
    case GET_SUBSCRIPTION_CLEAR:
      return {};
    default:
      return state;
  }
}

// export function getYouScreenReducer(state = {}, action: any) {
//   switch (action.type) {
//     case YOUR_SCREEN_REQUEST:
//       return { ...action.payload };
//     case YOUR_SCREEN_SUCCESS:
//       return { ...action.payload };
//     case YOUR_SCREEN_FAILD:
//       return { ...action.payload };
//     case YOUR_SCREEN_LONG:
//       return { ...action.payload };
//     case YOUR_SCREEN_CLEAR:
//       return {};
//     default:
//       return state;
//   }
// }
