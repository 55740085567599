import CustomButton from "../../../Common/CustomButton/CustomButton";
import InputGroups from "../../../Common/InputGroups/InputGroups";
import PasswordGroups from "../../../Common/PasswordGroups/PasswordGroups";
import { CHECKOUTPAGE } from "../../../../Constant/General";

const CkGuest = (props: any) => {
  return (
    <>
      <form className="guest-form">
        <InputGroups
          value={props.placeOrderData?.email?.value}
          onChange={(e: any) => {props.setData("email", e.target.value); props.setData("address","")}}
          error={
            props.isFormValidated && props.placeOrderDataError["email"]
              ? props.placeOrderDataError["email"]
              : null
          }
          label="Guest Email"
        ></InputGroups>
        {/* <PasswordGroups label="Guest Password"></PasswordGroups> */}
        <div className="action-bar">
          <div className="login-action">
            Do you already have an account?
            <a
              onClick={() => props.onButtonChg(CHECKOUTPAGE.auth_login)}
              className="login-link"
            >
              Log in
            </a>
          </div>
          <div className="signup-action">
            Don’t you have an account?
            <a
              onClick={() => props.onButtonChg(CHECKOUTPAGE.auth_signup)}
              className="signup-link"
            >
              Sign Up
            </a>
          </div>
        </div>
      </form>
    </>
  );
}

export default CkGuest;