export const PRODUCT_DETAIL_REQUEST = "PRODUCT_DETAIL_REQUEST";
export const PRODUCT_DETAIL_SUCCESS = "PRODUCT_DETAIL_SUCCESS";
export const PRODUCT_DETAIL_FAILD = "PRODUCT_DETAIL_FAILD";
export const PRODUCT_DETAIL_LONG = "PRODUCT_DETAIL_LONG";
export const PRODUCT_DETAIL_CLEAR = "PRODUCT_DETAIL_CLEAR";


export const PRODUCT_VARIENT_REQUEST = "PRODUCT_VARIENT_REQUEST";
export const PRODUCT_VARIENT_SUCCESS = "PRODUCT_VARIENT_SUCCESS";
export const PRODUCT_VARIENT_FAILD = "PRODUCT_VARIENT_FAILD";
export const PRODUCT_VARIENT_LONG = "PRODUCT_VARIENT_LONG";
export const PRODUCT_VARIENT_CLEAR = "PRODUCT_DETAIL_CLEAR";

export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAILD = "PRODUCT_LIST_FAILD";
export const PRODUCT_LIST_LONG = "PRODUCT_LIST_LONG";
export const PRODUCT_LIST_CLEAR = "PRODUCT_LIST_CLEAR";