import React, { useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import GoogleImg from "../../../Assets/img/google.svg";
import { IResolveParams, LoginSocialGoogle } from "reactjs-social-login";
import { loginAction } from "../../../Redux/Auth/AuthAction";
import {GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google"
import jwt_decode from "jwt-decode";

export default function GoogleLoginPopup(props:any) {
  const dispatch = useDispatch();
  const onLoginStart = useCallback(() => {}, []);
  const dispatchLogin = (provider: string, data: any) => {
    if (provider == "google") {
      dispatch(
        loginAction({
          firstname: data.given_name,
          lastname: data.family_name,
          email: data.email,
          gmailUserId: data.jti,
          loginType: "1",
          //gmailUserId: data.id,
        })
      );
    } else {
      console.log(data);
    }
  };

  function handleCredentialResponse(response: any) {
    const responsePayload = jwt_decode(response.credential);
    //console.log(responsePayload);
    dispatchLogin("google",responsePayload);
 }
  return (
    //<LoginSocialGoogle
    //  className="social-btns"
    //  client_id={process.env.REACT_APP_GG_APP_ID || ""}
    //  onLoginStart={onLoginStart}
    //  onResolve={({ provider, data }: IResolveParams) => {
    //    dispatchLogin("google", data);
    //  }}
    //  onReject={(err) => {
    //    console.log(err);
    //  }}
    //>
    //  <img src={GoogleImg} alt="" />
    //</LoginSocialGoogle>

    <GoogleOAuthProvider clientId={process.env.REACT_APP_GG_APP_ID || ""}>
      <GoogleLogin
        onSuccess={(credentialResponse: any) => {
          //console.log(credentialResponse);
          handleCredentialResponse(credentialResponse);
        }}
        onError={() => {
          console.log('Login Failed');
        }}
        theme="filled_blue"
        text={props.btnMsg}
         />
    </GoogleOAuthProvider>

  );
}
