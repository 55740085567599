import { Container } from "react-bootstrap";
import logo from "../../Assets/img/logo.svg";
import whatsapp from "../../Assets/img/footer_wp.svg";
import insta from "../../Assets/img/footer_ig.svg";
import {
  ABOUT,
  CLUB,
  CONTACT,
  DETAILPAGE,
  HOME,
  SHIPPINGRETURNS,
  FAQS,
  TERMSANDCONDITION,
  PRIVACYPOLICY,
  CHECKOUT,
  ORDERSUCCESS,
  PRODUCT_LISTING
} from "../../Constant/Route";
import { Link, useLocation } from "react-router-dom";
import SubscribeNewsletter from "./SubscribeNewsletter/SubscribeNewsletter";
import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

const Footer = () => {
  const masterData = useSelector((state: any) => state?.master?.data);
  let location = useLocation();
  const [path, setPath] = useState("");
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);
  return (
    <div
      className={`footer-wrapper ${
        path === ORDERSUCCESS || path === CHECKOUT ? "remove-footer" : ""
      }`}
    >
      <div className="subscribe-wrapper">
        <Container>
          <SubscribeNewsletter />
        </Container>
      </div>
      <div className="footer-menu-wrapper">
        <Container>
          <div className="footer-up-layout">
            <div className="footer-left-section">
              <Link to={HOME} className="footer-logo">
                <img src={logo} alt="Footer Logo"></img>
              </Link>
              <SubscribeNewsletter />
            </div>
            <div className="footer-right-section">
              <div className="f-menu-box">
                <ul>
                  <li>
                    <Link to={HOME}>Home</Link>
                  </li>
                  <li>
                    <Link to={ABOUT}>About</Link>
                  </li>
                  <li>
                    <Link to={PRODUCT_LISTING}>Diapers</Link>
                  </li>
                  <li>
                    <Link to={CLUB}>Pural Club</Link>
                  </li>
                  <li>
                    <Link to={CONTACT}>Contact</Link>
                  </li>
                </ul>
              </div>
              <div className="f-menu-box">
              <p>Help center</p>
                <ul>
                {/* <li>
                    <Link to={"*"}>Help center</Link>
                  </li> */}
                  <li>
                    <Link to={SHIPPINGRETURNS}>Shipping and returns</Link>
                  </li>
                  <li>
                    <Link to={FAQS}>FAQ</Link>
                  </li>
                  <li>
                    <Link to={TERMSANDCONDITION}>Terms and conditions</Link>
                  </li>
                  <li>
                    <Link to={PRIVACYPOLICY}>Privacy policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
        <div className="copy-right-section">
          <Container>
            <div className="copy-contents">
              <p>All rights reserved ©{moment().year()}</p>
              <div className="WI-icons">
                <a href={masterData?.footerData?.WHATSAPP} target="_blank">
                  <img src={whatsapp} alt="Whatsapp Link" ></img>
                </a>
                <a href={masterData?.footerData?.INSTAGRAM} target="_blank">
                  <img src={insta} alt="Instagram Link"></img>
                </a>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
    // <>
    // 	<div className="footer-wrap">
    // 		<Container>
    // 			<div className="footer-logo">
    // 				<img src={logo} alt="Footer Logo"></img>
    // 			</div>

    // 			<div className="footer-menu">
    // 				<ul>
    // 					<li>
    // 						<a href={HOME} className="f1624">Home</a>
    // 					</li>
    // 					<li>
    // 						<a href={ABOUT} className="f1624">About</a>
    // 					</li>
    // 					<li>
    // 						<a href={DETAILPAGE} className="f1624">Diapers</a>
    // 					</li>
    // 					<li>
    // 						<a href={CLUB} className="f1624">Pural Club</a>
    // 					</li>
    // 					<li>
    // 						<a href={CONTACT} className="f1624">Contact</a>
    // 					</li>
    // 				</ul>
    // 			</div>

    // 			<div className="footer-social">
    // 				<ul>
    // 					<li className="media wp">
    // 						<a href="#">
    // 							<img src={whatsapp} alt="Whatsapp Link"></img>
    // 						</a>
    // 					</li>
    // 					<li className="media ig">
    // 						<a href="#">
    // 							<img src={insta} alt="Instagram Link"></img>
    // 						</a>
    // 					</li>
    // 				</ul>
    // 			</div>

    // 			<div className="footer-bottom">
    // 				<span className="f1432">All rights reserved ©2022</span>
    // 			</div>

    // 		</Container>
    // 	</div>
    // </>
  );
};

export default Footer;
