import React, { useState } from "react";

const TextareaGroups = (props: any) => {
	return (
		<>
			<div className="textarea-groups">
				<label>{props.children}</label>
				<textarea
					rows={props.rowno}
					cols={props.colno}
					placeholder={props.placeholder}
					className={props.error && 'error'}
					onChange={props.onChange}
					value={props.value}
					name={props.name}
					ref={props.ref}
				></textarea>
				{props.error && <span className="error">{props.error}</span>}
			</div>
		</>
	)
}

export default TextareaGroups;