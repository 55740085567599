import { HOME_FAILD, HOME_LONG, HOME_REQUEST, HOME_SUCCESS } from "./HomeType";

// const defaultParameters = {
//   customerId: "",
//   customerToken: "",
//   websiteId: "",
//   groupId: "",
//   storeId: "",
// };

export function home(state = {}, action: any) {
  switch (action.type) {
    case HOME_REQUEST:
      return { ...action.payload };
    case HOME_SUCCESS:
      return { ...action.payload };
    case HOME_FAILD:
      return { ...action.payload };
    case HOME_LONG:
      return { ...action.payload };
    default:
      return state;
  }
}
