import React, { useEffect, useState } from "react";
import profileIcon from "../../../Assets/img/acc-profile.svg";
import CustomButton from "../../Common/CustomButton/CustomButton";
import { Outlet } from "react-router-dom";
import {
  ADRESSES,
  HOME,
  ORDERS,
  PERSONALDATA,
  SUBSCRIPTION,
  COMMUNICATIONSETTINGS,
} from "../../../Constant/Route";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { USER, QUOTE_ID } from "../../../Constant/LocalStorage";
import { useDispatch, useSelector } from "react-redux";
import { LOGOUT_SUCCESS } from "../../../Redux/Auth/AuthType";
import { showToast } from "../../../Utility/General";
import { defaultParameterAction } from "../../../Redux/General/GeneralAction";

const AccountSideMenu = () => {
  const navigate2 = useNavigate();
  const dispatch = useDispatch();

  const sideLinks: any = [
    {
      name: "Orders",
      links: ORDERS,
    },
    {
      name: "Subscription",
      links: SUBSCRIPTION,
    },
    {
      name: "Personal data",
      links: PERSONALDATA,
    },
    {
      name: "Addresses",
      links: ADRESSES,
    },
    {
      name: "Communication settings",
      links: COMMUNICATIONSETTINGS,
    },
  ];

  const [currentLocation, setCurrentLocation] = useState(null);
  const navigate: any = useLocation();

  useEffect(() => {
    setCurrentLocation(navigate.pathname);
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem(USER);
    localStorage.removeItem(QUOTE_ID);
    dispatch({
      type: LOGOUT_SUCCESS,
      payload: { type: LOGOUT_SUCCESS },
    });
    const defaultParameters = {
      customerId: "",
      customerToken: "",
      websiteId: "",
      groupId: "",
      storeId: "",
    }
    let defaultParameter = Object.assign({}, defaultParameters);
    dispatch(defaultParameterAction({...defaultParameter}));
    navigate(HOME);
    showToast("success", "Logout Successfully")
  };

  const authData = useSelector((state: any) => state?.login?.data);

  return (
    <div className="account-menu-wrapper">
      <div className="personal-info">
        <div className="profile-img">
          <img src={profileIcon} alt="Profile Icon"></img>
        </div>
        <div className="profile-text">
          <h3>Hi {authData.firstname}!</h3>
          <a href="#" className="email">
            {authData.email}
          </a>
        </div>
      </div>
      <div className="menu-list">
        <ul className="acc-menu">
          {sideLinks.map((items: any, index: number) =>
            items?.links ? (
              <li
                className={currentLocation === items?.links ? "active" : ""}
                key={index + "sideMenuItems"}
              >
                <Link to={items?.links} className="sidebar-links">
                  {items.name}
                </Link>
              </li>
            ) : (
              <li key={index + "sideMenuItems2"}>
                <h5>{items.name}</h5>
              </li>
            )
          )}
        </ul>
        <div className="sidebar-action">
          <CustomButton
            title="Log out"
            onClick={() => handleLogout()}
            bg="fill"
          >
            Log out
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default AccountSideMenu;
