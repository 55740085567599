import { END_POINTS, MAX_CALLS } from "../../Constant/Api";
import { NO_DATA_ERROR_CODE, SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import api from "../../Service/Axios";
import { showToast } from "../../Utility/General";
import { LOGIN_SUCCESS } from "../Auth/AuthType";
import { USER } from "../../Constant/LocalStorage";
import {
  ADDRESS_FAILD,
  ADDRESS_LONG,
  ADDRESS_REQUEST,
  ADDRESS_SUCCESS,
  CHANGE_PASSWORD_DATA_FAILD,
  CHANGE_PASSWORD_DATA_LONG,
  CHANGE_PASSWORD_DATA_REQUEST,
  CHANGE_PASSWORD_DATA_SUCCESS,
  COMMUNICATION_FAILD,
  COMMUNICATION_LONG,
  COMMUNICATION_REQUEST,
  COMMUNICATION_SUCCESS,
  FORM_ADDRESS_FAILD,
  FORM_ADDRESS_LONG,
  FORM_ADDRESS_REQUEST,
  FORM_ADDRESS_SUCCESS,
  GET_SUBSCRIPTION_FAILD,
  GET_SUBSCRIPTION_LONG,
  GET_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_SUCCESS,
  ORDERS_FAILD,
  ORDERS_LONG,
  ORDERS_NO_DATA,
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  PERSONAL_DATA_FAILD,
  PERSONAL_DATA_LONG,
  PERSONAL_DATA_REQUEST,
  PERSONAL_DATA_SUCCESS,
  SET_COMMUNICATION_FAILD,
  SET_COMMUNICATION_LONG,
  SET_COMMUNICATION_REQUEST,
  SET_COMMUNICATION_SUCCESS,
  SET_PERSONAL_DATA_FAILD,
  SET_PERSONAL_DATA_LONG,
  SET_PERSONAL_DATA_REQUEST,
  SET_PERSONAL_DATA_SUCCESS,
  YOUR_SCREEN_FAILD,
  YOUR_SCREEN_LONG,
  YOUR_SCREEN_REQUEST,
  YOUR_SCREEN_SUCCESS,
  INVOICE_REQUEST,
  INVOICE_SUCCESS,
  INVOICE_FAILED ,
  INVOICE_LONG  ,
  INVOICE_NO_DATA,
} from "./AccountType";

// export function getYouScreen() {
//   let recallCount = 0;
//   return (dispatch: any) => {
//     dispatch({
//       type: YOUR_SCREEN_REQUEST,
//       payload: { type: YOUR_SCREEN_REQUEST },
//     });
//     function recursiveCall() {
//       api
//         .post(END_POINTS.youScreen, {})
//         .then((result) => {
//           if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
//             dispatch({
//               type: YOUR_SCREEN_SUCCESS,
//               payload: { data: result.data, type: YOUR_SCREEN_SUCCESS },
//             });
//           } else if (recallCount < MAX_CALLS) {
//             recursiveCall();
//             recallCount++;
//             dispatch({
//               type: YOUR_SCREEN_LONG,
//               payload: { data: {}, type: YOUR_SCREEN_LONG },
//             });
//           } else {
//             dispatch({
//               type: YOUR_SCREEN_FAILD,
//               payload: { data: {}, type: YOUR_SCREEN_FAILD },
//             });
//           }
//         })
//         .catch((error) => {
//           if (recallCount < MAX_CALLS) {
//             recursiveCall();
//             recallCount++;
//             dispatch({
//               type: YOUR_SCREEN_LONG,
//               payload: { data: {}, type: YOUR_SCREEN_LONG },
//             });
//           } else {
//             dispatch({
//               type: YOUR_SCREEN_FAILD,
//               payload: { data: {}, type: YOUR_SCREEN_FAILD },
//             });
//           }
//         });
//     }
//     recursiveCall();
//   };
// }

export function getOrdersAction() {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: ORDERS_REQUEST,
      payload: { type: ORDERS_REQUEST },
    });
    function recursiveCall() {
      api
        .post(END_POINTS.orders, {})
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            dispatch({
              type: ORDERS_SUCCESS,
              payload: { data: result.data, type: ORDERS_SUCCESS },
            });
          } else if (result.data.errorCode === NO_DATA_ERROR_CODE) {
            dispatch({
              type: ORDERS_NO_DATA,
              payload: { data: result.data, type: ORDERS_NO_DATA },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: ORDERS_LONG,
              payload: { data: {}, type: ORDERS_LONG },
            });
          } else {
            dispatch({
              type: ORDERS_FAILD,
              payload: { data: {}, type: ORDERS_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: ORDERS_LONG,
              payload: { data: {}, type: ORDERS_LONG },
            });
          } else {
            dispatch({
              type: ORDERS_FAILD,
              payload: { data: {}, type: ORDERS_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function personalDataAction(loginData:any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: PERSONAL_DATA_REQUEST,
      payload: { type: PERSONAL_DATA_REQUEST },
    });
    function recursiveCall() {
      api
        .post(END_POINTS.personalData, {})
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            dispatch({
              type: PERSONAL_DATA_SUCCESS,
              payload: { data: result.data, type: PERSONAL_DATA_SUCCESS },
            });
            let personData = JSON.parse(localStorage.getItem('user') || '{}');
            
            personData.firstname = result?.data?.firstName;
            personData.lastname = result?.data?.lastName;

            localStorage.setItem(USER, JSON.stringify(personData))

            //let personalData = Object.assign({}, loginData);
            //personalData.firstname = result?.data?.firstName;
            //personalData.lastname = result?.data?.lastName;
            //dispatch({
            //  type: LOGIN_SUCCESS,
            //  payload: { data: personalData, type: LOGIN_SUCCESS },
            //});
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PERSONAL_DATA_LONG,
              payload: { data: {}, type: PERSONAL_DATA_LONG },
            });
          } else {
            dispatch({
              type: PERSONAL_DATA_FAILD,
              payload: { data: {}, type: PERSONAL_DATA_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: PERSONAL_DATA_LONG,
              payload: { data: {}, type: PERSONAL_DATA_LONG },
            });
          } else {
            dispatch({
              type: PERSONAL_DATA_FAILD,
              payload: { data: {}, type: PERSONAL_DATA_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function setPersonalDataAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: SET_PERSONAL_DATA_REQUEST,
      payload: { type: SET_PERSONAL_DATA_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.setPersonalData}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            showToast("success", result.data.message);
            dispatch({
              type: SET_PERSONAL_DATA_SUCCESS,
              payload: { data: result.data, type: SET_PERSONAL_DATA_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: SET_PERSONAL_DATA_LONG,
              payload: { data: {}, type: SET_PERSONAL_DATA_LONG },
            });
          } else {
            showToast("error", result.data.message);
            dispatch({
              type: SET_PERSONAL_DATA_FAILD,
              payload: { data: {}, type: SET_PERSONAL_DATA_FAILD },
            });
          }
        })
        .catch((error) => {
          showToast("error", error);
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: SET_PERSONAL_DATA_LONG,
              payload: { data: {}, type: SET_PERSONAL_DATA_LONG },
            });
          } else {
            dispatch({
              type: SET_PERSONAL_DATA_FAILD,
              payload: { data: {}, type: SET_PERSONAL_DATA_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function changePasswordAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type:CHANGE_PASSWORD_DATA_REQUEST,
      payload: { type: CHANGE_PASSWORD_DATA_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.changePassword}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            showToast("success", result.data.message);
            dispatch({
              type: CHANGE_PASSWORD_DATA_SUCCESS,
              payload: { data: result.data, type: CHANGE_PASSWORD_DATA_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: CHANGE_PASSWORD_DATA_LONG,
              payload: { data: {}, type: CHANGE_PASSWORD_DATA_LONG },
            });
          } else {
            showToast("error", result.data.message);
            dispatch({
              type: CHANGE_PASSWORD_DATA_FAILD,
              payload: { data: {}, type: CHANGE_PASSWORD_DATA_FAILD },
            });
          }
        })
        .catch((error) => {
          showToast("error", error);
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: CHANGE_PASSWORD_DATA_LONG,
              payload: { data: {}, type: CHANGE_PASSWORD_DATA_LONG },
            });
          } else {
            dispatch({
              type: CHANGE_PASSWORD_DATA_FAILD,
              payload: { data: {}, type: CHANGE_PASSWORD_DATA_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function addressAction() {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: ADDRESS_REQUEST,
      payload: { type: ADDRESS_REQUEST },
    });
    function recursiveCall() {
      api
        .post(END_POINTS.getAddress, {})
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            dispatch({
              type: ADDRESS_SUCCESS,
              payload: { data: result.data, type: ADDRESS_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: ADDRESS_LONG,
              payload: { data: {}, type: ADDRESS_LONG },
            });
          } else {
            dispatch({
              type: ADDRESS_FAILD,
              payload: { data: {}, type: ADDRESS_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: ADDRESS_LONG,
              payload: { data: {}, type: ADDRESS_LONG },
            });
          } else {
            dispatch({
              type: ADDRESS_FAILD,
              payload: { data: {}, type: ADDRESS_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function addressUpdateAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: FORM_ADDRESS_REQUEST,
      payload: { type: FORM_ADDRESS_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.setAddress}`, param)
        // .post(END_POINTS.setAddress, {})
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            showToast("success", result.data.message);
            dispatch({
              type: FORM_ADDRESS_SUCCESS,
              payload: { data: result.data, type: FORM_ADDRESS_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: FORM_ADDRESS_LONG,
              payload: { data: {}, type: FORM_ADDRESS_LONG },
            });
          } else {
            dispatch({
              type: FORM_ADDRESS_FAILD,
              payload: { data: {}, type: FORM_ADDRESS_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: FORM_ADDRESS_LONG,
              payload: { data: {}, type: FORM_ADDRESS_LONG },
            });
          } else {
            dispatch({
              type: FORM_ADDRESS_FAILD,
              payload: { data: {}, type: FORM_ADDRESS_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function getCommunicationAction() {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: COMMUNICATION_REQUEST,
      payload: { type: COMMUNICATION_REQUEST },
    });
    function recursiveCall() {
      api
        .post(END_POINTS.getCommunication, {})
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            dispatch({
              type: COMMUNICATION_SUCCESS,
              payload: { data: result.data, type: COMMUNICATION_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: COMMUNICATION_LONG,
              payload: { data: {}, type: COMMUNICATION_LONG },
            });
          } else {
            dispatch({
              type: COMMUNICATION_FAILD,
              payload: { data: {}, type: COMMUNICATION_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: COMMUNICATION_LONG,
              payload: { data: {}, type: COMMUNICATION_LONG },
            });
          } else {
            dispatch({
              type: COMMUNICATION_FAILD,
              payload: { data: {}, type: COMMUNICATION_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function setCommunicationAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: SET_COMMUNICATION_REQUEST,
      payload: { type: SET_COMMUNICATION_REQUEST },
    });
    function recursiveCall() {
      api
        // .post(END_POINTS.setCommunication, {})
        .post(`${END_POINTS.setCommunication}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            dispatch({
              type: SET_COMMUNICATION_SUCCESS,
              payload: { data: result.data, type: SET_COMMUNICATION_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: SET_COMMUNICATION_LONG,
              payload: { data: {}, type: SET_COMMUNICATION_LONG },
            });
          } else {
            dispatch({
              type: SET_COMMUNICATION_FAILD,
              payload: { data: {}, type: SET_COMMUNICATION_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: SET_COMMUNICATION_LONG,
              payload: { data: {}, type: SET_COMMUNICATION_LONG },
            });
          } else {
            dispatch({
              type: SET_COMMUNICATION_FAILD,
              payload: { data: {}, type: SET_COMMUNICATION_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}

export function getSubscriptionAction() {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: GET_SUBSCRIPTION_REQUEST,
      payload: { type: GET_SUBSCRIPTION_REQUEST },
    });
    function recursiveCall() {
      api
        .post(END_POINTS.getSubscription, {})
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            dispatch({
              type: GET_SUBSCRIPTION_SUCCESS,
              payload: { data: result.data, type: GET_SUBSCRIPTION_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: GET_SUBSCRIPTION_LONG,
              payload: { data: {}, type: GET_SUBSCRIPTION_LONG },
            });
          } else {
            dispatch({
              type: GET_SUBSCRIPTION_FAILD,
              payload: { data: {}, type: GET_SUBSCRIPTION_FAILD },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: GET_SUBSCRIPTION_LONG,
              payload: { data: {}, type: GET_SUBSCRIPTION_LONG },
            });
          } else {
            dispatch({
              type: GET_SUBSCRIPTION_FAILD,
              payload: { data: {}, type: GET_SUBSCRIPTION_FAILD },
            });
          }
        });
    }
    recursiveCall();
  };
}



export function invoiceAction(param: any) {
  let recallCount = 0;
  return (dispatch: any) => {
    dispatch({
      type: INVOICE_REQUEST,
      payload: { type: INVOICE_REQUEST },
    });
    function recursiveCall() {
      api
        .post(`${END_POINTS.createInvoice}`, param)
        .then((result) => {
          if (result.data.statusCode === SUCCESS_RESPONSE_CODE) {
            showToast("success", result.data.message);
            dispatch({
              type: INVOICE_SUCCESS,
              payload: { data: result.data, type: INVOICE_SUCCESS },
            });
          } else if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: INVOICE_LONG,
              payload: { data: {}, type: INVOICE_LONG },
            });
          } else {
            showToast("error", result.data.message);
            dispatch({
              type: INVOICE_FAILED,
              payload: { data: {}, type: INVOICE_FAILED },
            });
          }
        })
        .catch((error) => {
          if (recallCount < MAX_CALLS) {
            recursiveCall();
            recallCount++;
            dispatch({
              type: INVOICE_LONG,
              payload: { data: {}, type: INVOICE_LONG },
            });
          } else {
            dispatch({
              type: INVOICE_FAILED,
              payload: { data: {}, type: INVOICE_FAILED },
            });
          }
        });
    }
    recursiveCall();
  };
}