import { useState } from "react";
import InputGroups from "../../Common/InputGroups/InputGroups";
import CheckoutAddress from "../CheckoutAddress/CheckoutAddress";

const CheckoutShipping = (props: any) => {
  const [status, setStatus] = useState(1);

  const radioHandler = (status: any) => {
    setStatus(status);
  };

  return (
    <div className="shipping-parent">
      {/* this is web header start */}
      <div
        className="step-title web-view"
        onClick={() => props.onHeaderClick(false)}
      >
        Shipping
      </div>
      {/* this is web header end */}

      {/* this is mobile header start */}
      <div className="step-title mobile-view">Shipping</div>
      {/* this is mobile header end */}
      <div className="payment-steps">
        {props?.shippingMethod?.shippingMethodData?.list?.length > 0
          ? props?.shippingMethod?.shippingMethodData?.list?.map(
              (item: any, index: number) => {
                return (
                  <div className="rd methodlist" key={index + "shippingMethod"}>
                    <input
                      type="radio"
                      id={"shipping" + index}
                      name="shipping-method"
                      value={item.id}
                      defaultChecked={item.isSelected == "1"}
                      onClick={() => props.shippingMethodSelect(item.id)}
                    />
                    <label htmlFor={"shipping" + index}>
                      {item.rightText + " (" + item.leftText + ")"}
                    </label>
                  </div>
                );
              }
            )
          : ""}
      </div>
      {/* <div className="payment-steps">
        <div className="rd methodlist">
          <input
            type="radio"
            id="payment1"
            name="payment-gateway"
            checked={status === 1}
            onClick={() => radioHandler(1)}
          />
          <label htmlFor="payment1">Credit Card</label>
        </div>
        <div className="rd methodlist">
          <input
            type="radio"
            id="payment2"
            name="payment-gateway"
            checked={status === 2}
            onClick={() => radioHandler(2)}
          />
          <label htmlFor="payment2">KNET</label>
        </div>
      </div> */}
    </div>
  );
};

export default CheckoutShipping;