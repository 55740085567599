import { GET_CHECKOUT_CLEAR, GET_CHECKOUT_FAILD, GET_CHECKOUT_LONG, GET_CHECKOUT_REQUEST, GET_CHECKOUT_SUCCESS, PAYMENT_CONFIRM_CLEAR, PAYMENT_CONFIRM_FAILD, PAYMENT_CONFIRM_LONG, PAYMENT_CONFIRM_REQUEST, PAYMENT_CONFIRM_SUCCESS, PLACE_ORDER_CLEAR, PLACE_ORDER_FAILD, PLACE_ORDER_LONG, PLACE_ORDER_REQUEST, PLACE_ORDER_SUCCESS, UPDATE_CHECKOUT_CLEAR, UPDATE_CHECKOUT_FAILD, UPDATE_CHECKOUT_LONG, UPDATE_CHECKOUT_REQUEST, UPDATE_CHECKOUT_SUCCESS, QUOTE_UPDATE_ADDRESS_FAILD, QUOTE_UPDATE_ADDRESS_LONG, QUOTE_UPDATE_ADDRESS_REQUEST, QUOTE_UPDATE_ADDRESS_SUCCESS, QUOTE_UPDATE_ADDRESS_CLEAR} from "./CheckoutType";


export function getCheckoutReducer(state = {}, action: any) {
  switch (action.type) {
    case GET_CHECKOUT_REQUEST:
      return { ...action.payload };
    case GET_CHECKOUT_SUCCESS:
      return { ...action.payload };
    case GET_CHECKOUT_FAILD:
      return { ...action.payload };
    case GET_CHECKOUT_LONG:
      return { ...action.payload };
    case GET_CHECKOUT_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function updateCheckoutReducer(state = {}, action: any) {
  switch (action.type) {
    case UPDATE_CHECKOUT_REQUEST:
      return { ...action.payload };
    case UPDATE_CHECKOUT_SUCCESS:
      return { ...action.payload };
    case UPDATE_CHECKOUT_FAILD:
      return { ...action.payload };
    case UPDATE_CHECKOUT_LONG:
      return { ...action.payload };
    case UPDATE_CHECKOUT_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function placeOrderReducer(state = {}, action: any) {
  switch (action.type) {
    case PLACE_ORDER_REQUEST:
      return { ...action.payload };
    case PLACE_ORDER_SUCCESS:
      return { ...action.payload };
    case PLACE_ORDER_FAILD:
      return { ...action.payload };
    case PLACE_ORDER_LONG:
      return { ...action.payload };
    case PLACE_ORDER_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function paymentConfirmReducer(state = {}, action: any) {
  switch (action.type) {
    case PAYMENT_CONFIRM_REQUEST:
      return { ...action.payload };
    case PAYMENT_CONFIRM_SUCCESS:
      return { ...action.payload };
    case PAYMENT_CONFIRM_FAILD:
      return { ...action.payload };
    case PAYMENT_CONFIRM_LONG:
      return { ...action.payload };
    case PAYMENT_CONFIRM_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
}

export function quoteUpdateAddressReducer(state = {}, action: any) {
  switch (action.type) {
    case QUOTE_UPDATE_ADDRESS_REQUEST:
      return { ...action.payload };
    case QUOTE_UPDATE_ADDRESS_SUCCESS:
      return { ...action.payload };
    case QUOTE_UPDATE_ADDRESS_FAILD:
      return { ...action.payload };
    case QUOTE_UPDATE_ADDRESS_LONG:
      return { ...action.payload };
    case QUOTE_UPDATE_ADDRESS_CLEAR:
      return { ...action.payload };
    default:
      return state;
  }
}