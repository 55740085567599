// PIYUSH CHANGES
export const HOME = '/';
export const TYPO = '/typo';
export const LOADER = '/loader'

// RUSHIKESH CHANGES
export const ABOUT = '/about';
export const CLUB = '/club';
export const CONTACT = '/contact';
export const DETAILPAGE = '/detail';
export const PRODUCT_DETAIL = '/product';
export const ACCOUNT = '/account';
export const ORDERS = ACCOUNT + '/orders';
export const SUBSCRIPTION = ACCOUNT + '/subscription';
export const PERSONALDATA = ACCOUNT + '/personaldata';
export const ADRESSES = ACCOUNT + '/addresses';
export const COMMUNICATIONSETTINGS = ACCOUNT + '/communicationsettings';
export const SHIPPINGRETURNS = '/shippingreturns';
export const FAQS = '/faqs';
export const PRIVACYPOLICY = '/privacypolicy';
export const TERMSANDCONDITION = '/termsandcondition';
export const SOMETHINGWRONG = '/somethingwrong';
export const ORDERSUCCESS = "/order-success";
// export const ORDERSUCCESS = '/ordersuccess';

export const MOBILEACCOUNT = '/account';
export const MOBILEORDERS = '/orders';
export const MOBILESUBSCRIPTION = '/subscription';
export const MOBILEPERSONALDATA = '/personaldata';
export const MOBILEADRESSES = '/addresses';

export const CHECKOUT = '/checkout';
export const LOGIN = "/login";
export const FORGOTPASSWORD = "/forgotpassword";
export const FORGOTSUCCESS = '/forgotsuccess';
export const RESETPASSWORD = "/reset-password";
export const RESETSUCCESS = '/resetsuccess';
export const SIGNUP = "/signup";

export const PRODUCT_LISTING = '/productslisting';