export const MAX_CALLS = 1;
export const END_POINTS = {
  login: process.env.REACT_APP_API_URL + "login",
  signup: process.env.REACT_APP_API_URL + "signUp",
  forgotPassword: process.env.REACT_APP_API_URL + "forgetPassword",
  changePassword: process.env.REACT_APP_API_URL + "changePassword",
  resetPassword: process.env.REACT_APP_API_URL + "resetPassword",
  home: process.env.REACT_APP_API_URL + "homePage",
  cms: process.env.REACT_APP_API_URL + "cmsPage",
  getContactUs: process.env.REACT_APP_API_URL + "getContactUs",
  contactUs: process.env.REACT_APP_API_URL + "sendContactUs",
  youScreen: process.env.REACT_APP_API_URL + "youScreen",
  getCommunication: process.env.REACT_APP_API_URL + "getCommunicationSetting",
  setCommunication:
    process.env.REACT_APP_API_URL + "communicationSettingUpdate",
  getSubscription: process.env.REACT_APP_API_URL + "getSubscription",
  faq: process.env.REACT_APP_API_URL + "getFaqList",
  orders: process.env.REACT_APP_API_URL + "getMyOrders",
  personalData: process.env.REACT_APP_API_URL + "myProfile",
  setPersonalData: process.env.REACT_APP_API_URL + "myProfileUpdate",
  getAddress: process.env.REACT_APP_API_URL + "getAllAddress",
  setAddress: process.env.REACT_APP_API_URL + "updateAddress",
  newsletterSubscription:
    process.env.REACT_APP_API_URL + "newsletterSubscription",
  productDetail: process.env.REACT_APP_API_URL + "productDetails",
  productDetailVarient: process.env.REACT_APP_API_URL + "applyVarient",
  productList: process.env.REACT_APP_API_URL + "productList",
  addToCart: process.env.REACT_APP_API_URL + "addToCart",
  myCart: process.env.REACT_APP_API_URL + "myCart",
  getCheckout: process.env.REACT_APP_API_URL + "getCheckoutList",
  updateCheckout: process.env.REACT_APP_API_URL + "updateCheckoutList",
  placeOrder: process.env.REACT_APP_API_URL + "placeOrder",
  paymentSuccess: process.env.REACT_APP_API_URL + "paymentSuccess",
  puralClub: process.env.REACT_APP_API_URL + "puralClub",
  quoteUpdateAddress: process.env.REACT_APP_API_URL + "quoteUpdateAddress",
  createInvoice: process.env.REACT_APP_API_URL + "createInvoice",
};

export const API_STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  LONG: "long",
  FAILED: "failed",
  NO_DATA: "nodata",
};
