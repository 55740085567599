import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS } from "../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { getCmsPage } from "../../Redux/CmsPages/CmsPagesAction";
import {
  CMS_FAILD,
  CMS_LONG,
  CMS_REQUEST,
  CMS_SUCCESS,
} from "../../Redux/CmsPages/CmsPagesType";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import Loader from "../Loader/Loader";
import SomethingWrong from "../SomethingWrong/SomethingWrong";

const CmsPage = (props: any) => {
  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const cmsPage = useSelector((state: any) => state?.cms?.data?.component[0]);
  useEffect(() => {
    dispatch(getCmsPage({ urlKey: props.slug }));
  }, [props.slug]);

  useSelector((state: any) => {
    if (state?.cms?.type === CMS_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.cms?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.cms?.type === CMS_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.cms?.type === CMS_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.cms?.type === CMS_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });
  // useEffect(() => {
  //   if (cmsPage) {
  //     console.log(cmsPage?.data?.title);
  //     // setPageTitle(cmsPage?.data);
  //   }
  // }, [cmsPage]);
  const accBreadcrumb = [
    {
      path: "/",
      label: "Home",
    },
    {
      label: cmsPage?.data?.title,
    },
  ];
  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <>
          {cmsPage !== undefined && (cmsPage?.data !== undefined || cmsPage?.data.length > 0) && cmsPage?.data?.content !== undefined ? (
            <div className="cms-page">
              { cmsPage?.data?.identifier !== "about-us" && cmsPage?.data?.identifier !== "contact-us" ? (
                <Container>
                  <Breadcrumbs value={accBreadcrumb}></Breadcrumbs>
                </Container>
              ):"" }
              <div
                dangerouslySetInnerHTML={{ __html: cmsPage?.data?.content }}
                className="max-800 pb-45"
              ></div>
            </div>
          ) : (
            <SomethingWrong></SomethingWrong>
          )}
        </>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    // return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
};

export default CmsPage;
