import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Row } from "react-bootstrap";
import { productListAction } from "../../Redux/Product/ProductAction";
import productBanner from "../../Assets/img/product-listing-banner.jpg";
import productMobileBanner from "../../Assets/img/product-listing-mobilebanner.jpg";
import PLShape from "../../Assets/img/pl-shape.svg";
import PLMobileShape from "../../Assets/img/pl-mobile-shape.svg";
import Breadcrumbs from "../Common/Breadcrumbs/Breadcrumbs";
import Listing1 from "../../Assets/img/product_page_01.png";
import Listing2 from "../../Assets/img/product_page_02.png";
import Listing3 from "../../Assets/img/product_page_03.png";
import Listing4 from "../../Assets/img/product_page_04.png";
import Product1 from "../../Assets/img/product_1.png";
import Product2 from "../../Assets/img/product_2.png";
import Product3 from "../../Assets/img/product_3.png";
import Loader from "../Loader/Loader";
import { API_STATUS } from "../../Constant/Api";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import {
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_LONG,
  PRODUCT_LIST_FAILD,
} from "../../Redux/Product/ProductType";
import { useNavigate, useParams } from "react-router-dom";
import { PRODUCT_DETAIL } from "../../Constant/Route";

export default function ProductListing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const productListRawData = useSelector(
    (state: any) => state?.productListReducer?.data
  );

  // console.log(productListRawData);

  useEffect(() => {
    dispatch(
      productListAction({
        categoryUrlkey: "diapers",
        brandId: "",
        pageSize: "10",
        page: "1",
      })
    );
  }, []);

  useSelector((state: any) => {
    if (state?.productListReducer?.type === PRODUCT_LIST_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.productListReducer?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.productListReducer?.type === PRODUCT_LIST_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
      }
    } else if (state?.productListReducer?.type === PRODUCT_LIST_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.productListReducer?.type === PRODUCT_LIST_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  const accBreadcrumb = [
    {
      path: "/",
      label: "Home",
    },
    {
      path: "/productslisting",
      label: "Shop",
    },
  ];

  //const ListingData = [
  //  {
  //    img: Listing1,
  //    name: "LARGE eco nappies",
  //    weight: "10 to 14 Kg",
  //    units: "52 Units",
  //  },
  //  {
  //    img: Listing2,
  //    name: "MEDIUM eco nappies",
  //    weight: "6 to 11 Kg",
  //    units: "60 Units",
  //  },
  //  {
  //    img: Listing3,
  //    name: "SMALL eco nappies",
  //    weight: "3 to 6 Kg",
  //    units: "68 Units",
  //  },
  //  {
  //    img: Listing4,
  //    name: "New born eco nappies",
  //    weight: "Up to 5Kg",
  //    units: "30 Units",
  //  },
  //];

  switch (apiStatus) {
    case API_STATUS.LOADING:
      return <Loader></Loader>;
    case API_STATUS.SUCCESS:
      return (
        <div className="product-listing">
          <div className="banner-wrap">
            <div className="cmsbanner-wrap desktop show-768">
              <div className="cmsbanner-img">
                {/*<img src={productListRawData?.component[1]?.bannerData?.image} alt="Banner Image"></img>*/}
                <img src={productBanner} alt="Banner Image"></img>
              </div>
              <div className="cmsbanner-inwrap">
                <Container>
                  <div className="cmsbanner-content">
                    <img src={PLShape} alt="Vector"></img>
                    <div className="cmsshape">
                      <Breadcrumbs value={accBreadcrumb}></Breadcrumbs>
                      <h1>Shop</h1>
                    </div>
                  </div>
                </Container>
              </div>
            </div>
            <div className="cmsbanner-wrap mobile hide-767">
              <div className="cmsbanner-img">
                {/*<img src={productMobileBanner} alt="Banner Image"></img>*/}
                <Breadcrumbs value={accBreadcrumb}></Breadcrumbs>
              </div>
              {/*<div className="cmsbanner-inwrap">
                    <div className="cmsbanner-content">
                      <img src={PLMobileShape} alt="Vector"></img>
                      <div className="abtshape">
                        <h1>Shop</h1>
                      </div>
                    </div>
                  </div>*/}
            </div>
            <div className="product-listing-mobile-header">
              <Container>
                <h1>The shop</h1>
                <p>{productListRawData?.component[1]?.subtitle}</p>
              </Container>
            </div>
          </div>
          <Container>
            <div className="product-listing-wrapper">
              <Row>
                {productListRawData?.component[2]?.componentProductListData.map(
                  (item: any, index: number) => (
                    <Col
                      lg={4}
                      md={6}
                      sm={6}
                      xs={12}
                      key={"listing" + index}
                      onClick={() =>
                        navigate(PRODUCT_DETAIL + `/${item.urlKey}`)
                      }
                    >
                      <div className="listing-box">
                        <a className="listing-img-wrapper">
                          <img src={item.image} alt="" />
                        </a>
                        <div className="listing-content">
                          <h4>{item.title}</h4>
                          <p>{item.weight}</p>
                          <span>{item.units}</span>
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </div>
          </Container>
          <div className="take-look-section grey-bg">
            <Container>
              <h4>{productListRawData?.component[3]?.title}</h4>
              {
                productListRawData?.component[3]?.productConfigComponent?.map((items: any, index: number) => (
                  <div className="img-content" key={index + 'static-data'}>
                    <img src={items?.image} alt="" />
                    <p>{items?.text}</p>
                  </div>
                ))
              }
              {/* <div className="img-content">
                <img src={Product1} alt="" />
                <p>Comfortable stretchy waistbands</p>
              </div>
              <div className="img-content">
                <img src={Product2} alt="" />
                <p>12 hour leak lock protection</p>
              </div>
              <div className="img-content">
                <img src={Product3} alt="" />
                <p>Absorb more than 15x their weight</p>
              </div> */}
            </Container>
          </div>
        </div>
      );
    case API_STATUS.LONG:
    // return <SomethingWrong></SomethingWrong>;
    case API_STATUS.FAILED:
    // return <SomethingWrong></SomethingWrong>;
    default:
      return <Loader></Loader>;
  }
}
