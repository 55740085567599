import { AnyCnameRecord } from "dns";
import React from "react";

const RadioLabel = (props: any) => {
	return (
		<>
			<div className="rd">
				<input
					type="radio"
					value={props.value}
					name={props.name}
					id={props.id}
					defaultChecked={props.defaultChecked}
					onChange={props.onChange}
				/>
				<label htmlFor={props.id}>
					{props.children}
				</label>
			</div>
		</>
	)
}

export default RadioLabel;