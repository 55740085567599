export const CHECKOUTPAGE = {
  auth: "auth",
  auth_login: "auth_login",
  auth_signup: "auth_signup",
  auth_guest: "auth_guest",
  mob_login: "mob_login",
  mob_signup: "mob_signup",
  mob_guest: "mob_guest",
  address: "address",
  payment: "payment",
  shipping: "shipping",
  address_payment: "address_payment",
  auth_payment: "auth_payment",
};