import React,{ useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import FB from "../../Assets/img/FB.svg";
import GoogleImg from "../../Assets/img/google.svg";
import InputGroups from "../Common/InputGroups/InputGroups";
import PasswordGroups from "../Common/PasswordGroups/PasswordGroups";
import CustomButton from "../Common/CustomButton/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import { FORGOTPASSWORD, HOME, LOGIN, ORDERS } from "../../Constant/Route";
import { API_STATUS } from "../../Constant/Api";
import { useDispatch, useSelector } from "react-redux";
import validate from "../../Utility/Validation";
import { signUpAction } from "../../Redux/Auth/AuthAction";
import { SIGNUP_FAILD, SIGNUP_LONG, SIGNUP_REQUEST, SIGNUP_SUCCESS } from "../../Redux/Auth/AuthType";
import { SUCCESS_RESPONSE_CODE } from "../../Constant/Status";
import { isLoading } from "../../Utility/General";
import GoogleLogin from "../SocialLogin/GoogleLogin/GoogleLogin";


const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const INITIAL_SIGNUP_DATA: any = {
    firstname: {
      value: '',
      validation: ["required"],
      errors: ["Name is required."],
    },
    lastname: {
      value: '',
      validation: ["required"],
      errors: ["Surname is required."],
    },
    email: {
      value: '',
      validation: ["email", "required"],
      errors: ["Please enter valid email.", "Email is required."],
    },
    password: {
      value: '',
      validation: ["required"],
      errors: ["Password is required."],
    },
  };
  const [signupData, setSignupData] = useState(INITIAL_SIGNUP_DATA);
  const [apiStatus, setApiStatus] = useState(API_STATUS.SUCCESS);
  const [isFormValidated, setIsFormValidated] = useState(false);
  let demo: any = {};
  const [signupDataError, setSignupDataError] = useState(demo);


  const userSignUpData = useSelector(
    (state: any) => state?.signup?.data
  );
  
  let singUpStatusCode = userSignUpData?.statusCode;
  
  const setData = (field: string, value: string) => {
    let signupDetails = Object.assign({}, signupData);
    signupDetails[field].value = value;
    setSignupData({ ...signupDetails });
  };  

  useEffect(() => {
    setSignupDataError({ ...validate(signupData)?.errors })
  }, [signupData])

  const signupHandler = () => {
    if (validate(signupData).isValidated === true) {
      dispatch(
        signUpAction({
          firstname: signupData.firstname.value,
          lastname: signupData.lastname.value,
          email: signupData.email.value,
          password: signupData.password.value,
        })
      );
    } else {
      setSignupDataError({ ...validate(signupData)?.errors });
      setIsFormValidated(true);
    }
  };

  useSelector((state: any) => {
    if (state?.signup?.type === SIGNUP_REQUEST) {
      if (apiStatus !== API_STATUS.LOADING) {
        setApiStatus(API_STATUS.LOADING);
      }
    } else if (
      state?.signup?.data?.statusCode === SUCCESS_RESPONSE_CODE &&
      state?.signup?.type === SIGNUP_SUCCESS
    ) {
      if (apiStatus !== API_STATUS.SUCCESS) {
        setApiStatus(API_STATUS.SUCCESS);
        if(window.innerWidth < 767) {
          navigate(LOGIN)
        }
      }
    } else if (state?.signup?.type === SIGNUP_LONG) {
      if (apiStatus !== API_STATUS.LONG) {
        setApiStatus(API_STATUS.LONG);
      }
    } else if (state?.signup?.type === SIGNUP_FAILD) {
      if (apiStatus !== API_STATUS.FAILED) {
        setApiStatus(API_STATUS.FAILED);
      }
    }
  });

  return (
    <div className="signup form-section">
      <Container>
        <div className="max-464">
          <h1>Sign Up</h1>
          <div className="input-field-wrapper">
            <InputGroups
              type="text"
              label="Name"
              autoComplete="off"
              onChange={(e: any) => setData("firstname", e.target.value)}
              error={
                isFormValidated && signupDataError["firstname"]
                  ? signupDataError["firstname"]
                  : null
              }
            />
            <InputGroups
              type="text"
              label="Surname"
              autoComplete="off"
              onChange={(e: any) => setData("lastname", e.target.value)}
              error={
                isFormValidated && signupDataError["lastname"]
                  ? signupDataError["lastname"]
                  : null
              }
            />
            <InputGroups
              type="email"
              label="Email"
              autoComplete="off"
              onChange={(e: any) => setData("email", e.target.value)}
              error={
                isFormValidated && signupDataError["email"]
                  ? signupDataError["email"]
                  : null
              }
            />
            <PasswordGroups
              label={"Password"}
              autoComplete="off"
              onChange={(e: any) => setData("password", e.target.value)}
              error={
                isFormValidated && signupDataError["password"]
                  ? signupDataError["password"]
                  : null
              }
            />
          </div>
          <CustomButton
            isLoading={isLoading(apiStatus)}
            disabled={isLoading(apiStatus)}
            bg="fill"
            onClick={() => signupHandler()}
          >
            SIGN UP
          </CustomButton>
          <p className="or">or</p>
          <div className="social-login">
          <GoogleLogin btnMsg="signup_with"/>
            {/*<a className="social-btns">
              <img src={FB} alt="" />
            </a>*/}
            {/*<a className="social-btns">
              <img src={GoogleImg} alt="" />
            </a>*/}
          </div>
          <p className="have-account">
            Do you already have an account? <Link to={LOGIN}>Log in</Link>{" "}
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Signup;
